<div style="padding: 10px;">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="Type">
            <th mat-header-cell *matHeaderCellDef>Type</th>
            <td mat-cell *matCellDef="let element"> 
                <img [src]="getTypeIcon(element.Type)" />
                {{element.Type}} 
            </td>
        </ng-container>
        <ng-container matColumnDef="Description">
            <th mat-header-cell *matHeaderCellDef>Description</th>
            <td mat-cell *matCellDef="let element"> {{element.Description}} </td>
        </ng-container>
        <ng-container matColumnDef="Time">
            <th mat-header-cell *matHeaderCellDef>Time</th>
            <td mat-cell *matCellDef="let element"> {{element.Time}} </td>
        </ng-container>
        <ng-container matColumnDef="Parameter">
            <th mat-header-cell *matHeaderCellDef>Parameter</th>
            <td mat-cell *matCellDef="let element"> {{element.Parameter}} </td>
        </ng-container>
        <ng-container matColumnDef="Action">
            <th mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let element"> {{element.Action}} </td>
        </ng-container>
        <ng-container matColumnDef="Clear">
            <th mat-header-cell *matHeaderCellDef>Clear</th>
            <td mat-cell *matCellDef="let element"> {{element.Clear}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator  class="mat-elevation-z8"
        [length]="dummyData.length"
        [pageSize]="10"
        [pageSizeOptions]="[10, 15, 20]">
    </mat-paginator>
</div>