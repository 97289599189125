import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';
import { CalendarModule } from 'primeng/calendar';
import { MsalGuard } from '@azure/msal-angular';


import { UsersComponent } from './pages/users/users.component';
import { OrderStatusComponent } from './pages/order-status/order-status.component';
import { SitesComponent } from './pages/sites/sites.component';
import { AssetsComponent } from './pages/assets/assets.component';
import { CompaniesComponent } from './pages/companies/companies.component';
import { PartsListComponent } from './pages/parts-list/parts-list.component';
import { SiteDetailsComponent } from './pages/site-details/site-details.component';
import { AssetDetailsComponent } from './pages/asset-details/asset-details.component';
import { BookmarkComponent } from './pages/bookmarks/bookmarks.component';
import { FeedbackComponent } from './pages/feedback/feedback.component';
import { SandboxComponent } from './pages/sandbox/sandbox.component';
import { JorgesplaybinComponent } from './pages/jorgesplaybin/jorgesplaybin.component';
import { DocumentLibraryComponent } from './pages/document-library/document-library.component';
import { DialogExample } from './pages/sandbox/sandbox.component';
import { TableBasicExample } from './shared/components/mat-table/hrt-mat-table.component';
import { AssetGenericDataComponent } from './pages/asset-details/asset-generic-data/asset-generic-data.component';
import { AssetHistoricalDataComponent } from './pages/asset-details/asset-historical-data/asset-historical-data.component';
import { AssetAnnunciationDataComponent } from './pages/asset-details/asset-annunciation-data/asset-annunciation-data.component';
import { AssetAnalysisComponent } from './pages/asset-details/asset-analysis/asset-analysis.component';
import {
  AssetServiceDataComponent,
  PopUp,
} from './pages/asset-details/asset-service-data/asset-service-data.component';
import { AssetServiceRecordComponent } from './pages/asset-details/asset-service-record/asset-service-record.component';
import { AssetHistoryComponent } from './pages/asset-details/asset-history/asset-history.component';
import { AssetNotesComponent } from './pages/asset-details/asset-notes/asset-notes.component';
import { AssetDocumentationComponent } from './pages/asset-details/asset-documentation/asset-documentation.component';
import { AssetRealTimeDataComponent } from './pages/asset-details/asset-real-time-data/asset-real-time-data.component';
import { AssetComponentListComponent } from './pages/asset-details/asset-component-list/asset-component-list.component';
import { MasterScheduleComponent } from './pages/master-schedule/master-schedule.component';
import { AddProductsComponent } from './pages/add-products/add-products.component';
import { DxDataGridModule, DxFormModule, DxLoadIndicatorModule } from 'devextreme-angular';
import { GeoChartComponent } from './shared/components/geo-chart/geo-chart.component';
import { ProgressSpinnerComponent } from './shared/components/progress-spinner/progress-spinner.component';
import { OverlayService } from './shared/services/overlay.service';
import { UserEditorComponent } from './pages/users/user-editor/user-editor.component';
import { ProductEditorComponent } from './pages/product-editor/product-editor.component';
import { AssetFinderComponent } from './shared/components/asset-finder/asset-finder.component';
import { IntellisocGeneralComponent } from './products/intellisoc-retrofit/intellisoc-general/intellisoc-general.component';
import { ProductInfoComponent } from './pages/product-editor/product-info/product-info.component';
import { ProductPricingComponent } from './pages/product-editor/product-pricing/product-pricing.component';
import { DerivedPricingYorkComponent } from './pages/product-editor/product-pricing/derived-pricing-york/derived-pricing-york.component';
import { EditServiceRecordComponent } from './pages/asset-details/asset-service-data/edit-service-record/edit-service-record.component';
import { SiteTableComponent } from './pages/site-table/site-table.component';
import { AssetTableComponent } from './pages/asset-table/asset-table.component';
import { ServiceTableComponent } from './pages/service-table/service-table.component';
import { FeedbackDetailsComponent } from './pages/feedback/feedback-details/feedback-details.component';
import { CommentsModule } from './pages/feedback/comments/comments.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HrtTableModule } from './shared/components/hrt-table/hrt-table.component';
import { ProgressIndicatorSvgModule } from './shared/components/progress-indicator-svg/progress-indicator-svg.component';
import { UserAppFeatureGuard } from './shared/services/user-app-feature-guard';
import { OilSeparatorCalcComponent } from './products/oil-separator-calc/oil-separator-calc.component';
import { ConfirmDeactivateGuard } from './candeactivate-guard.service';

import {
  InputV2Module,
  SelectV2Module,
  RadioGroupV2Module,
  ButtonV2Module,
  IconModule,
} from '@gea/digital-ui-lib';
import { HrtTitleModule } from './shared/components/hrt-tab/hrt-tab.component';
import { DashboardAssetComponent } from './pages/dashboard/dashboard-asset/dashboard-asset.component';
import { DashboardServiceComponent } from './pages/dashboard/dashboard-service/dashboard-service.component';
import { TableIconComponent } from './shared/components/table-icon/table-icon.component';
import { DashboardSitesComponent } from './pages/dashboard/dashboard-sites/dashboard-sites.component';
import { DashboardIotComponent } from './pages/dashboard/dashboard-iot/dashboard-iot.component';
import { DashboardIotAdComponent } from './pages/dashboard/dashborad-iot-ad/dashboard-iot-ad.component';
import { AssetAllComponent } from './pages/assets/asset-all/asset-all.component';
import { AssetOmniComponent } from './pages/assets/asset-omni/asset-omni.component';
import { AssetPistonComponent } from './pages/assets/asset-piston/asset-piston.component';
import { AssetScrewComponent } from './pages/assets/asset-screw/asset-screw.component';
import { SiteAllComeponent } from './pages/sites/site-all/site-all.component';
import { SiteIotConnectedComeponent } from './pages/sites/site-iot-connected/site-iot-connected.component';
import { DescriptionPopupComponent } from './pages/feedback/description-popup/description-popup.component';
import { HrtCommentsModule } from './shared/components/hrt-comment/hrt-comment.component';
import { DocumentDetailsComponent } from './pages/document-library/document-details/document-details.component';
import { HrtBookmarkModule } from './shared/components/hrt-bookmark/hrt-bookmark.component';
import { FilterSearchComponent } from './shared/components/filter-search/filter-search.component';
import { FeedbackTableComponent } from './pages/feedback/feedback-table/feedback-table.component';
import { DndFileDirective } from './shared/directives/dnd-file.directive';
import { FeedbackTableWrapperComponent } from './pages/feedback/feedback-table-wrapper/feedback-table-wrapper.component';
import { ConfigListComponent } from './shared/components/config-list/config-list.component';
import { AddConfigPopupComponent } from './shared/components/config-list/add-config-popup/add-config-popup.component';
import { ConfigDetailsComponent } from './shared/components/config-list/config-details/config-details.component';
import { DashboardTableComponent } from './pages/dashboard/dashboard-table/dashboard-table.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [MsalGuard],
    data: {
      title: 'Home',
    },
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [MsalGuard, UserAppFeatureGuard],
    data: {
      title: 'Dashboard',
    },
  },
  {
    path: 'order-status',
    component: OrderStatusComponent,
    canActivate: [MsalGuard, UserAppFeatureGuard],
    data: {
      title: 'Order Status',
    },
  },
  {
    path: 'master-schedule',
    component: MasterScheduleComponent,
    canActivate: [MsalGuard, UserAppFeatureGuard],
  },
  {
    path: 'users',
    component: UsersComponent,
    canActivate: [MsalGuard, UserAppFeatureGuard],
    data: {
      title: 'Users',
    },
  },
  {
    path: 'user-editor',
    component: UserEditorComponent,
    canActivate: [MsalGuard, UserAppFeatureGuard],
    data: {
      title: 'User Editor',
    },
  },
  {
    path: 'sites',
    component: SitesComponent,
    canActivate: [MsalGuard, UserAppFeatureGuard],
    data: {
      title: 'Sites',
    },
  },
  {
    path: 'assets',
    component: AssetsComponent,
    canActivate: [MsalGuard, UserAppFeatureGuard],
    data: {
      title: 'Assets',
    },
  },
  {
    path: 'companies',
    component: CompaniesComponent,
    canActivate: [MsalGuard, UserAppFeatureGuard],
    data: {
      title: 'Companies',
    },
  },
  {
    path: 'add-products',
    component: AddProductsComponent,
    canActivate: [MsalGuard, UserAppFeatureGuard],
    data: {
      title: 'Products',
    },
  },
  {
    path: 'parts-list',
    component: PartsListComponent,
    canActivate: [MsalGuard, UserAppFeatureGuard],
    data: {
      title: 'Parts',
    },
  },
  {
    path: 'document-library',
    component: DocumentLibraryComponent,
    canActivate: [MsalGuard, UserAppFeatureGuard],
    data: {
      title: 'Documents',
    },
  },
  {
    path: 'document-details',
    component: DocumentDetailsComponent,
    canActivate: [MsalGuard, UserAppFeatureGuard],
    data: {
      title: 'Document Details',
    },
  },
  {
    path: 'site-details',
    component: SiteDetailsComponent,
    canActivate: [MsalGuard, UserAppFeatureGuard],
    data: {
      title: 'Site Details',
    },
  },
  {
    path: 'asset-details',
    component: AssetDetailsComponent,
    canActivate: [MsalGuard, UserAppFeatureGuard],
    data: {
      title: 'Asset Details',
    },
  },
  {
    path: 'service-record',
    component: EditServiceRecordComponent,
    canActivate: [MsalGuard, UserAppFeatureGuard],
    data: {
      title: 'Service Record',
    },
  },
  {
    path: 'bookmarks',
    component: BookmarkComponent,
    canActivate: [MsalGuard, UserAppFeatureGuard],
    data: {
      title: 'Bookmarks',
    },
  },
  {
    path: 'feedback',
    component: FeedbackComponent,
    canActivate: [MsalGuard, UserAppFeatureGuard],
    data: {
      title: 'Feedback',
    },
  },
  {
    path: 'feedback-details',
    component: FeedbackDetailsComponent,
    canActivate: [MsalGuard, UserAppFeatureGuard],
    canDeactivate: [ConfirmDeactivateGuard],
    data: {
      title: 'Feedback Details',
    },
  },
  {
    path: 'edit-feedback',
    component: FeedbackDetailsComponent,
    canActivate: [MsalGuard, UserAppFeatureGuard],
    data: {
      title: 'Feedback Details',
    },
  },
  {
    path: 'sandbox',
    component: SandboxComponent,
    canActivate: [MsalGuard, UserAppFeatureGuard],
    data: {
      title: 'Sandbox',
    },
  },
  {
    path: 'config',
    component: ConfigListComponent,
    canActivate: [MsalGuard, UserAppFeatureGuard],
    data: {
      title: 'Configurations',
    },
  },
  {
    path: 'config-details',
    component: ConfigDetailsComponent,
    canActivate: [MsalGuard, UserAppFeatureGuard],
    data: {
      title: 'Configuration Details',
    },
  },
  {
    path: 'product-editor',
    component: ProductEditorComponent,
    canActivate: [MsalGuard, UserAppFeatureGuard],
  },
  {
    path: '**',
    redirectTo: 'home',
  },
];

const protectedResourceMap = new Map<string, Array<string>>();
protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

@NgModule({
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes, { useHash: true }),
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    HrtTableModule,
    CalendarModule,
    CommentsModule,
    DxDataGridModule,
    DxFormModule,
    DxLoadIndicatorModule,
    ProgressIndicatorSvgModule,
    HrtTitleModule,
    HrtCommentsModule,
    HrtBookmarkModule,
    InputV2Module,
    SelectV2Module,
    RadioGroupV2Module,
    ButtonV2Module,
    IconModule,
    DndFileDirective,
  ],
  providers: [
    OverlayService,
    {
      provide: MatDialog,
      useValue: {},
    },
  ],
  exports: [RouterModule],
  declarations: [
    HomeComponent,
    DashboardComponent,
    DashboardAssetComponent,
    DashboardSitesComponent,
    DashboardServiceComponent,
    DashboardIotComponent,
    DashboardIotAdComponent,
    TableIconComponent,
    AssetAllComponent,
    AssetOmniComponent,
    AssetPistonComponent,
    AssetScrewComponent,
    DescriptionPopupComponent,
    SiteAllComeponent,
    SiteIotConnectedComeponent,
    OrderStatusComponent,
    UsersComponent,
    CompaniesComponent,
    SitesComponent,
    AssetsComponent,
    PartsListComponent,
    DocumentLibraryComponent,
    SiteDetailsComponent,
    AssetDetailsComponent,
    BookmarkComponent,
    FeedbackComponent,
    TableBasicExample,
    SandboxComponent,
    JorgesplaybinComponent,
    AddProductsComponent,
    DialogExample,
    PopUp,
    AssetGenericDataComponent,
    AssetHistoricalDataComponent,
    AssetAnnunciationDataComponent,
    AssetAnalysisComponent,
    AssetServiceDataComponent,
    AssetServiceRecordComponent,
    AssetHistoryComponent,
    AssetNotesComponent,
    AssetDocumentationComponent,
    AssetRealTimeDataComponent,
    AssetComponentListComponent,
    MasterScheduleComponent,
    GeoChartComponent,
    ProgressSpinnerComponent,
    UserEditorComponent,
    ProductEditorComponent,
    AssetFinderComponent,
    IntellisocGeneralComponent,
    ProductInfoComponent,
    ProductPricingComponent,
    DerivedPricingYorkComponent,
    EditServiceRecordComponent,
    AssetTableComponent,
    SiteTableComponent,
    ServiceTableComponent,
    FeedbackDetailsComponent,
    OilSeparatorCalcComponent,
    DocumentDetailsComponent,
    FilterSearchComponent,
    FeedbackTableWrapperComponent,
    FeedbackTableComponent,
    DashboardTableComponent,
    ConfigListComponent,
    ConfigDetailsComponent,
    AddConfigPopupComponent
  ],
})
export class AppRoutingModule {}
