import { Observable } from 'rxjs';
import { ApiService } from "../api.service";
import { TableResult, TableView } from './table';
import { HourlyRate, ProductInfo, ProductPrice } from '../../data/data.generated';

export class Products {
    constructor(private _api: ApiService) {
    }

    public GetProductInfo(companyId: number, productId: number): Observable<ProductInfo> {
        return this._api.Get<ProductInfo>("GetProductInfo",
            {
                CompanyId: companyId,
                ProductId: productId,
            });
    }

    public GetProductInfoHistory(companyId: number, productId: number): Observable<ProductInfo[]> {
        return this._api.Get<ProductInfo[]>("GetProductInfoHistory",
            {
                CompanyId: companyId,
                ProductId: productId,
            });
    }

    public GetProductInfoHistoryHtml(id: number): Observable<ProductInfo> {
        return this._api.Get<ProductInfo>("GetProductInfoHistoryHtml",
            {
                Id: id,
            });
    }

    public UpdateProductInfo(productId: number, infoHtml: string, comments: string): Observable<string> {
        return this._api.Get<string>("UpdateProductInfo",
            {
                ProductId: productId,
                InfoHtml: infoHtml,
                Comments: comments
            });
    }

    public GetHourlyRates(companyId: number): Observable<HourlyRate[]> {
        return this._api.Get<HourlyRate[]>("GetHourlyRates",
            {
                CompanyId: companyId,
            });
    }

    public GetProductPrices(companyId: number, productId: number): Observable<TableResult<ProductPrice>> {
        return this._api.Get<TableResult<ProductPrice>>("GetProductPrices",
            {
                CompanyId: companyId,
                ProductId: productId,
            });
    }

    public GetDerivedData(productPriceId: number): Observable<any> {
        return this._api.Get<any>("GetDerivedData",
            {
                ProductPriceId: productPriceId,
            });
    }

    public UpdateProductPrice(productPriceId: number, data: any, props: any): Observable<any> {
        return this._api.Get<any>("UpdateProductPrice",
            {
                ProductPriceId: productPriceId,
                Data: JSON.stringify(data),
                ...props
            });
    }

    public GetProductPriceHistory(companyId: number, productId: number, key: string): Observable<ProductPrice[]> {
        return this._api.Get<ProductPrice[]>("GetProductPriceHistory",
            {
                CompanyId: companyId,
                ProductId: productId,
                Key: key,
            });
    }

    public GetProductPriceHistoryItem(id: number): Observable<ProductPrice> {
        return this._api.Get<ProductPrice>("GetProductPriceHistoryItem",
            {
                Id: id,
            });
    }

    public GetBomCost(bomNumber: string): Observable<number> {
        return this._api.Get<number>("GetBomCost",
            {
                BomNumber: bomNumber,
            });
    }
}
