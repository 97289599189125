import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  Bookmark,
} from 'src/app/shared/data/data.generated';
import { ApiService } from 'src/app/shared/services/api.service';
import { Table, TableView } from 'src/app/shared/services/api/table';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { debounceTime } from 'rxjs';
import { HrtBookmarkComponent } from 'src/app/shared/components/hrt-bookmark/hrt-bookmark.component';
import { HrtDialogComponent } from 'src/app/shared/components/hrt-dialog/hrt-dialog.component';

@Component({
  selector: 'app-bookmarks',
  templateUrl: './bookmarks.component.html',
  styleUrls: ['./bookmarks.component.scss'],
})
export class BookmarkComponent implements OnInit {
  constructor(
    private api: ApiService,
    public dialog: MatDialog,
    private loader: LoaderService
  ) {}

  public listOptions: TableView = new TableView();
  public bookmarkItems: Bookmark[] = [];
  bookmarkTable: Table<Bookmark> = new Table<Bookmark>(this.loader);

  public displayedColumns: string[] = [
    'BookmarkType',
    'BookmarkName',
    'URL',
    'Actions'
  ];

  ngOnInit(): void {
    this.bookmarkTable.GetApi = (listOptions: TableView) =>
      this.api.Bookmark.GetBookmarks(listOptions);
    this.bookmarkTable.RefreshData();
    this.bookmarkTable.inputSubject
      .pipe(debounceTime(500))
      .subscribe((value) => {
        this.bookmarkTable.SearchUpdated();
      });
  }

  searchChange(searchText: string) {
    if (searchText.trim() !== this.bookmarkTable.inputSubject.getValue()) {
      this.bookmarkTable.inputSubject.next(searchText);
    }
  }

  editBookmark(ele: any){
    const dialogRef = this.dialog.open(HrtBookmarkComponent, {
      data: {url: ele.URL, bookmarkName: ele.BookmarkName}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        ele.BookmarkName = result;
        this.api.Bookmark.EditBookmark(ele.ForeignKey, ele.BookmarkType, ele.BookmarkName).subscribe({
          next: (x) => {
            this.bookmarkTable.Result.Data= x.Data;
        },
        });
      }
    });

  }

  deleteBookmark(ele: any){
    const dialogRef = this.dialog.open(HrtDialogComponent, {
      data: {title: "Delete Bookmark", msg: "Are you sure want to permanently delete this bookmark?"}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.api.Bookmark.DeleteBookmark(ele.ForeignKey, ele.BookmarkType).subscribe({
          next: (x) => {
            this.bookmarkTable.Result.Data= x.Data;
        }
        });
        let existingBookmarks = JSON.parse(sessionStorage.getItem('UserBookmarks') || '');
        existingBookmarks = existingBookmarks.filter((bookmark: { ForeignKey: number; BookmarkType: string; }) => !(bookmark.ForeignKey === ele.ForeignKey && bookmark.BookmarkType === ele.BookmarkType))
        sessionStorage.setItem('UserBookmarks',JSON.stringify(existingBookmarks));
      }
    }); 
  }

  onClick(url : string){
    window.open(url, '_self');
  }

}
