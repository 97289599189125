<hrt-filter-search
  [tableId]="tableId"
  [searchTextPlaceholder]="searchPlaceholder"
  [searchModelField]="feedbackTable.View"
  [hasFilter]="true"
  (searchVal)="searchChange($event)"
  (filters)="applyFilters($event)"
>
  <table
    mat-table
    [dataSource]="feedbackTable.Result.Data"
    matSort
    matSortDisableClear
    [matSortActive]="feedbackTable.View.SortColumn"
    (matSortChange)="feedbackTable.RefreshData($event)"
  >
    <ng-container matColumnDef="ID">
      <th mat-header-cell *matHeaderCellDef>ID</th>
      <td
        mat-cell
        *matCellDef="let element"
        (click)="feedbackService.editFeedback(element.Id)"
      >
        {{ element.Id }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Type">
      <th mat-header-cell *matHeaderCellDef>Type</th>
      <td
        mat-cell
        *matCellDef="let element"
        (click)="feedbackService.editFeedback(element.Id)"
      >
        <mat-icon
          class="FeedbackIcon"
          [style]="feedbackService.getIconStyle(element.TypeId)"
          >{{ feedbackService.getIcon(element.TypeId) }}</mat-icon
        >
      </td>
    </ng-container>

    <ng-container matColumnDef="Title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
      <td
        mat-cell
        *matCellDef="let element"
        (click)="feedbackService.editFeedback(element.Id)"
      >
        {{ element.Title }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Details">
      <th mat-header-cell *matHeaderCellDef>Details</th>
      <td mat-cell *matCellDef="let element">
        <mat-icon
          class="info-icon custom-svg"
          (click)="
            feedbackService.openModal(
              element.Text,
              element.Title,
              element.TypeId,
              $event
            )
          "
          >info</mat-icon
        >
      </td>
    </ng-container>

    <ng-container matColumnDef="CompanyName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Company</th>
      <td
        mat-cell
        *matCellDef="let element"
        (click)="feedbackService.editFeedback(element.Id)"
      >
        {{ element.CompanyNames }}
      </td>
    </ng-container>

    <ng-container matColumnDef="ApplicationName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>App / Product</th>
      <td
        mat-cell
        *matCellDef="let element"
        (click)="feedbackService.editFeedback(element.Id)"
      >
        {{ getAppIds(element.ApplicationId)}}
      </td>
    </ng-container>

    <ng-container matColumnDef="PriorityId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Priority</th>
      <td
        mat-cell
        *matCellDef="let element"
        (click)="feedbackService.editFeedback(element.Id)"
      >
        {{ element.Priority }}
      </td>
    </ng-container>

    <ng-container matColumnDef="StatusId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td
        mat-cell
        *matCellDef="let element"
        (click)="feedbackService.editFeedback(element.Id)"
      >
        {{ element.Status }}
      </td>
    </ng-container>

    <ng-container matColumnDef="UserName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Created By</th>
      <td
        mat-cell
        *matCellDef="let element"
        (click)="feedbackService.editFeedback(element.Id)"
      >
        {{ element.UserName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Created">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
      <td
        mat-cell
        *matCellDef="let element"
        (click)="feedbackService.editFeedback(element.Id)"
      >
        {{ feedbackService.formatDate(element.Created) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="LastModified">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Modified</th>
      <td
        mat-cell
        *matCellDef="let element"
        (click)="feedbackService.editFeedback(element.Id)"
      >
        {{ feedbackService.formatDate(element.LastModified) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Notifications">
      <th mat-header-cell *matHeaderCellDef>Notifications</th>
      <td
        mat-cell
        *matCellDef="let element"
        (click)="feedbackService.editFeedback(element.Id)"
      >
        <div class="icon-column notifications">
          <div class="comment">
            <mat-icon
              [style.visibility]="
                element.CommentCount > 0 ? 'visible' : 'hidden'
              "
              matBadge="{{ element.CommentCount }}"
              matBadgePosition="after"
              matBadgeColor="accent"
            >
              chat
            </mat-icon>
          </div>
          <div class="attachment">
            <mat-icon
              [style.visibility]="
                element.AttachmentCount > 0 ? 'visible' : 'hidden'
              "
              matBadge="{{ element.AttachmentCount }}"
              matBadgePosition="after"
              matBadgeColor="accent"
              >attach_file</mat-icon
            >
          </div>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td
        class="mat-cell"
        style="text-align: center"
        [attr.colspan]="displayedColumns.length"
      >
        No records found
      </td>
    </tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      style="cursor: pointer"
    ></tr>
  </table>
  <mat-paginator
    [length]="feedbackTable.Result.Count"
    [pageSizeOptions]="feedbackTable.View.PageSizeOptions"
    [pageSize]="feedbackTable.View.PageSize"
    [pageIndex]="feedbackTable.View.Page"
    (page)="feedbackTable.RefreshData($event)"
  >
    >
  </mat-paginator>
</hrt-filter-search>
