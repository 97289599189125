<div class="details-header">
  <img class="go-back-button" src="../assets/layout/arrow_back.svg" (click)="goBack()" />
  <img
    [src]="getCustomTypeIcon(productTypeId)"
    [style.height.px]="getProductTypeIcon(productTypeId).Height"
    [style.width.px]="getProductTypeIcon(productTypeId).Width"
  />
  <span
    style="
      font-size: 20px;
      font-weight: bold;
      margin-left: 20px;
      vertical-align: top;
    "
  >
    {{ title }} <a [routerLink]="" (click)="openSite()" style="cursor: pointer;">{{ projectNumber }}</a>
  </span>
  <span class="gea-icons">
    <mat-icon
    title="Share"
    style="margin-right: 15px;"
    (click)="copyLink()"
    ><geaui-icon-v2
    file="16px_network-connection"
    [color]="'black'"
    [colorProp]="'stroke'"
    ></geaui-icon-v2>
  </mat-icon>

  <mat-icon
    title="Bookmark"
    (click)="toggleBookmark()"
    ><geaui-icon-v2
    file="16px_bookmark"
    [color]="'black'"
    [colorProp]="isBookmarked? 'fill' : 'stroke'"
    ></geaui-icon-v2>
  </mat-icon>
  </span>
</div>
<mat-tab-group class="real-time-tabs MarginTopSmall" [selectedIndex]="selectedParentTabIndex" (selectedIndexChange)="tabChange($event)">
  <mat-tab label="Technical">
    <asset-generic-data [items]="technicalData"></asset-generic-data>
  </mat-tab>
  <mat-tab label="Operating">
    <mat-tab-group class="OperatingDataClass" [selectedIndex]="selectedIndex">
      <mat-tab class="custom-tabs" label="As Built">
        <asset-generic-data *ngIf="selectedProduct!==productTypeId" [items]="asBuiltData"></asset-generic-data>
        <asset-documentation
          *ngIf="selectedProduct === productTypeId"
          [assetId]="id"
          [items]="panelData"
          [isDrawing]="false"
          [isOmni]="isOmni"
        ></asset-documentation>
      </mat-tab>
      <mat-tab  class="custom-tabs" label="Real-Time">
        <asset-real-time-data [assetData]="asset" [setData]="realTimeData" 
        [isFetchingData]="fetchingOmniData" [isConnectedAsset]="isConnectedAsset"></asset-real-time-data>
      </mat-tab>
      <!-- <mat-tab label="Annunciations">
        <asset-historical-data [assetId]="id"></asset-historical-data>
      </mat-tab> -->
      <mat-tab class="custom-tabs" label="Analysis">
        <asset-analysis></asset-analysis>
      </mat-tab>
    </mat-tab-group>
  </mat-tab>
  <mat-tab label="Service">
    <asset-service-data [assetId]="id"></asset-service-data>
  </mat-tab>
  <mat-tab label="Documents">
    <div class="MarginTop">
      <h2>Documentation</h2>
    </div>
    <asset-documentation
      [assetId]="id"
      [items]="documentList"
      [isDrawing]="false"
      [isOmni]="isOmni"
    ></asset-documentation>
    <div class="MarginTop">
      <h2>Drawings</h2>
    </div>
    <asset-documentation
      [assetId]="id"
      [items]="drawingData"
      [isDrawing]="true"
      [isOmni]="isOmni"
    ></asset-documentation>
  </mat-tab>
  <mat-tab label="Components">
    <asset-component-list 
      *ngIf="!isOmni" 
      [assetId]="id" 
      [showLineNumber]="false"
      ></asset-component-list>
    <asset-component-list 
      *ngIf="isOmni" 
      [assetId]="id" 
      [showLineNumber]="true"
      ></asset-component-list>
  </mat-tab>
  <mat-tab label="Notes">
    <div class="comment-content">
      <hrt-comment [comments]="comments" [commentType]="commentType" (commentAdded)="addComment($event)"></hrt-comment>
    </div>
  </mat-tab>
</mat-tab-group>

