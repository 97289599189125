<div class="hrt-table-container">
  <header class="header">
    <geaui-button-v2
      *ngIf="hasFilter"
      [type]="'primary'"
      class="btn-brdr-radius"
      preIcon="16px_system-preferences"
      (click)="showFilterSelection = !showFilterSelection"
    >
      Filter
    </geaui-button-v2>
    <mat-form-field
      class="table-header-search-inp"
      [ngClass]="hasFilter ? 'has-filter-btn' : ''"
    >
      <mat-label>{{ searchTextPlaceholder }}</mat-label>
      <input
        type="text"
        matInput
        [(ngModel)]="searchValue"
        (input)="searchChange(searchValue)"
      />
      <mat-icon matSuffix style="cursor: pointer">search</mat-icon>
    </mat-form-field>
  </header>
  <section
    class="filter-selection"
    [class.show]="showFilterSelection"
    *ngIf="hasFilter"
  >
    <div class="filter-field-list">
      <div
        *ngFor="let filter of filterConfigurations; let i = index"
        class="filter-field"
      >
        <mat-label>{{ filter.name }}</mat-label>
        <mat-form-field class="form-select">
          <mat-select
            #matRef
            [multiple]="filter.type == 'multiSelect'"
            [ngModel]="
              filter.type == 'select' && selectedFilters[filter.key]
                ? selectedFilters[filter.key][0]
                : selectedFilters[filter.key]
            "
            (ngModelChange)="
              filter.type == 'select' && selectedFilters[filter.key]
                ? (selectedFilters[filter.key][0] = $event)
                : (selectedFilters[filter.key] = $event)
            "
            [compareWith]="comparer"
            (selectionChange)="addToFilterList(filter.key, $event, filter.type)"
          >
            <mat-option
              *ngFor="let option of filter.options; let i = index"
              [value]="option"
              >{{ option.label }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="apply">
      <geaui-button-v2
        [disabled]="applyDisabled"
        [type]="'primary'"
        (click)="applyFilter()"
      >
        Apply
      </geaui-button-v2>
    </div>
  </section>
  <div
    class="sub-content"
    [class.filter-shown]="showFilterSelection"
    [class.has-filter]="hasFilter"
  >
    <section class="applied-filters-list" *ngIf="filtersApplied.length > 0">
      <div class="filter-text">Filters Applied:</div>
      <mat-chip-grid
        class="chip-list"
        #chipList
        aria-label="Product selection"
        *ngFor="let filter of filtersApplied"
      >
        <mat-chip class="geaui-chip">
          <button matChipRemove (click)="removeFilter(filter)">
            <mat-icon class="custom-svg">close</mat-icon>
          </button>
          <span>{{ filter.name }}: {{ filter.value }}</span>
        </mat-chip>
      </mat-chip-grid>
      <a (click)="resetAllFilters()" class="reset-filter">
        <div>Reset All</div>
        <mat-icon class="custom-svg">replay</mat-icon>
      </a>
    </section>
    <section class="custom-user-content">
      <ng-content></ng-content>
    </section>
    <section class="geaui-table">
      <button
        class="export-btn"
        *ngIf="hasExport && tableData"
        (click)="exportTable()"
      >
        <span class="csv-text">CSV</span>
        <mat-icon>download</mat-icon>
      </button>
      <geaui-table-v2
        [tableId]="tableId"
        [columnDefinitions]="columnDefinitions"
        [defaultSettings]="defaultTableSettings"
        [data]="tableData"
        [totalRecords]="totalCount"
        [sortable]="true"
        [rowsClickable]="true"
        [loading]="tableData ? false : true"
        (rowClicked)="getTableRowData($event)"
        (rowDoubleClicked)="getTableRowData($event)"
      ></geaui-table-v2>
    </section>
  </div>
</div>
