import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ImagesService {
    public Dashboard: Dashboard = new Dashboard();
    public Icons: Icons = new Icons();
    public ProductIcons: ProductIcons = new ProductIcons();
}

export class Dashboard {
    public BluAstrum: string = "/assets/dashboard/BluAstrum.png";
    public BluRedFusion: string = "/assets/dashboard/BluRedFusion.png";
    public Compax: string = "/assets/dashboard/Compax.png";
    public CustomerService: string = "/assets/dashboard/CustomerService.png";
    public Dashboard: string = "/assets/dashboard/Dashboard.png";
    public Gea: string = "/assets/dashboard/Gea.png";
    public Gorp: string = "/assets/dashboard/Gorp.png";
    public RTDocuments: string = "/assets/dashboard/RTDocuments.png";
    public RTParts: string = "/assets/dashboard/RTParts.png";
    public RTSelect: string = "/assets/dashboard/RTSelect.png";
    public RTService: string = "/assets/dashboard/RTService.png";
    public SenS: string = "/assets/dashboard/SenS.png";
}

export class Icons {
    public Admin: string = "/assets/icons/Admin.png";
    public Asset: string = "/assets/icons/Asset.png";
    public Chat: string = "/assets/icons/Chat.png";
    public Check: string = "/assets/icons/Check.svg";
    public Company: string = "/assets/icons/Company.png";
    public Delete: string = "/assets/icons/Delete.svg";
    public Edit: string = "/assets/icons/Edit.svg";
    public Error: string = "/assets/icons/Error.svg";
    public FormatExcel: string = "/assets/icons/FormatExcel.png";
    public FormatPdf: string = "/assets/icons/FormatPdf.png";
    public FormatWord: string = "/assets/icons/FormatWord.png";
    public Information: string = "/assets/icons/Information.svg";
    public IoT: string = "/assets/icons/IoT.png";
    public List: string = "/assets/icons/List.png";
    public New: string = "/assets/icons/New.png";
    public NotAvailable: string = "/assets/icons/NotAvailable.png";
    public Permission: string = "/assets/icons/Permission.png";
    public Question: string = "/assets/icons/Question.png";
    public Service: string = "/assets/icons/Service.png";
    public Settings: string = "/assets/icons/Settings.png";
    public Site: string = "/assets/icons/Site.png";
    public TechnicalData: string = "/assets/icons/TechnicalData.png";
    public User: string = "/assets/icons/User.png";
    public Users: string = "/assets/icons/Users.png";
    public Warning: string = "/assets/icons/Warning.svg";
}

export class ProductIcons {
    public BareCompressor: string = "/assets/product-icons/BareCompressor.png";
    public BarePiston: string = "/assets/product-icons/BarePiston.png";
    public BareScrew: string = "/assets/product-icons/BareScrew.png";
    public Freezer: string = "/assets/product-icons/Freezer.png";
    public GForce: string = "/assets/product-icons/GForce.png";
    public Miscellaneous: string = "/assets/product-icons/Miscellaneous.png";
    public Omni: string = "/assets/product-icons/Omni.png";
    public PistonChiller: string = "/assets/product-icons/PistonChiller.png";
    public PistonHeatPump: string = "/assets/product-icons/PistonHeatPump.png";
    public PistonPackage: string = "/assets/product-icons/PistonPackage.png";
    public PlateHeatExchanger: string = "/assets/product-icons/PlateHeatExchanger.png";
    public Purger: string = "/assets/product-icons/Purger.png";
    public ScrewChiller: string = "/assets/product-icons/ScrewChiller.png";
    public ScrewHeatPump: string = "/assets/product-icons/ScrewHeatPump.png";
    public ScrewPackage: string = "/assets/product-icons/ScrewPackage.png";
    public Separator: string = "/assets/product-icons/Separator.png";
    public StandAloneMotor: string = "/assets/product-icons/StandAloneMotor.png";
    public StandAloneStarter: string = "/assets/product-icons/StandAloneStarter.png";
    public ValveStation: string = "/assets/product-icons/ValveStation.png";
}