<div style="margin: 20px;" *ngIf="isEnvironment !='local'">

  <table class="TableHeader">
    <tr>
      <!-- <td>
        <mat-icon matSuffix class="TableHeaderIcon">factory</mat-icon>
      </td>
      <td>
        <span class="TableHeaderText">Sites</span>
      </td> -->
      <td >
        <mat-form-field  class="TableHeaderSearch">
          <mat-label style="color: var(--geaui-vibrant-blue);">Search Components</mat-label>
          <input type="text" matInput [(ngModel)]="table.View.Search" (ngModelChange)="table.SearchUpdated()">
          <mat-icon style="color: var(--geaui-vibrant-blue);" matSuffix>search</mat-icon>
        </mat-form-field>
      </td>
      <!-- <td></td>
      <td></td> -->
    </tr>
  </table>
    <table mat-table [dataSource]="table.Result.Data" 
           matSort matSortDisableClear
           [matSortActive]="table.View.SortColumn" 
           (matSortChange)="table.RefreshData($event)">
      <ng-container matColumnDef="LineNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Item</th>
        <td mat-cell *matCellDef="let row">{{row.LineNumber}}</td>
      </ng-container>
      <ng-container matColumnDef="Number">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Part Number</th>
        <td mat-cell *matCellDef="let row">{{row.Number}}</td>
      </ng-container>
      <ng-container matColumnDef="Quantity">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Quantity</th>
        <td mat-cell *matCellDef="let row">{{formatQuantity(row.Quantity)}}</td>
      </ng-container>
      <ng-container matColumnDef="Category">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Category</th>
        <td mat-cell *matCellDef="let row">{{row.Category}}</td>
      </ng-container>
      <ng-container matColumnDef="Description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
        <td mat-cell *matCellDef="let row">{{row.Description}}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator 
      [length]="table.Result.Count" 
      [pageSizeOptions]="table.View.PageSizeOptions" 
      [pageSize]="table.View.PageSize" 
      [pageIndex]="table.View.Page" 
      (page)="table.RefreshData($event)">
    </mat-paginator>
  </div>
  

<hrt-table *ngIf="isEnvironment =='local'"
[tableId]="tableId"
[columnDefinitions]="columnDefinitions"
[tableData]="tableData"
[totalCount]="totalCount"
(searchText)="updateSearchTerm($event)"
>
</hrt-table>

<b>* AR = As Required, REF = Reference</b>