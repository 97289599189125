import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class DigiApiService {

  constructor(
    private readonly _http: HttpClient
  ) { }

  public AuthToken = "";

  public Get<T>(url: string): Observable<T> {

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.AuthToken}`
    });

    return this._http
      .get<T>(environment.baseURL + url, {
        headers
      });
  }
}
