<div class="comments-container">
    <div class="add-new-comments">
        <textarea matInput class="comment-editor" placeholder="Add {{commentType ==='Feedback'? 'comments':'notes'}}..." [(ngModel)]="newComment"></textarea>
        @if (newComment) {
            <div class="icon-bubble">
                <mat-icon class="add-comment-btn" (click)="addComment()">send</mat-icon>
            </div>
        } @else {
            <div class="icon-bubble">
                <mat-icon class="add-comment-btn-disable">send</mat-icon>
            </div>
        }
    </div>
    <div [ngClass]="{'user-comments-section': commentType ==='Feedback' || 'Orders', 'user-asset-comments-section':commentType ==='Asset'}">
        <div *ngFor="let comment of comments;let isEven = even" class="comment" >
            <div class="user-info">
                <div class="user-bubble">{{getUserInitials(comment.UserName)}}</div>
                <div class="username">{{comment.UserName}}</div>
                <div class="timestamp">{{formatDate(comment.Created)}}</div>
            </div>

            <div class="user-comment" [innerHTML]="sanitizeComment(comment.Text)">
            </div>
        </div>
    </div>
</div>