<mat-tab-group selectedIndex="0" mat-align-tabs="center">


  <mat-tab label="HRT Table">
    <div class="hrt-table-container mat-elevation-z8">

      <!-- Global Search -->
      <table class="filter-table">
        <tr>
          <td>
            <button mat-button class="geaui-button-primary" (click)="toggleColumnFilters()">Filters</button>
            <mat-form-field class="filter-search">
              <input matInput (keyup)="applyFilter($event)" placeholder="Search">
            </mat-form-field>
          </td>
        </tr>
      </table>

      <!-- Column Filters -->
      <div [@slideDown]="showColumnFilters.toString()">
      <table class="filter-table">
        <tr>
        <!-- Column Filters -->
        <td>
        <mat-form-field>
        <input matInput (keyup)="applyFilter($event, 'ID')" placeholder="Search by ID">
        </mat-form-field>
        </td>
        <td>
        <mat-form-field>
        <input matInput (keyup)="applyFilter($event, 'name')" placeholder="Search by Name">
        </mat-form-field>
        </td>
        <td>
        <mat-form-field>
        <input matInput (keyup)="applyFilter($event, 'rank')" placeholder="Search by Rank">
        </mat-form-field>
        </td>
        <td>
        <mat-form-field>
        <input matInput (keyup)="applyFilter($event, 'acronym')" placeholder="Search by Acronym">
        </mat-form-field>
        </td>
        </tr>
      </table>
      </div>

      <mat-table #table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="ID">
          <mat-header-cell *matHeaderCellDef mat-sort-header> ID </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.ID}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="rank">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Rank </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.rank}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="acronym">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Acronym </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.acronym}} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </div>

    <mat-paginator #paginator
                    [pageSize]="10"
                    [pageSizeOptions]="[5, 10, 20]"
                    [showFirstLastButtons]="true">
    </mat-paginator>
  </mat-tab>



  <mat-tab label="HRT Table">
    <div class="hrt-table-container mat-elevation-z8">

      <!-- Global Search -->
      <table class="filter-table">
        <tr>
          <td>
            <button mat-button class="geaui-button-primary" (click)="toggleColumnFilters()">Filters</button>
            <mat-form-field class="filter-search">
              <input matInput (keyup)="applyFilter($event)" placeholder="Search">
            </mat-form-field>
          </td>
        </tr>
      </table>

      <!-- Column Filters -->
      <div [@slideDown]="showColumnFilters.toString()">
      <table class="filter-table">
        <tr>
        <!-- Column Filters -->
        <td>
        <mat-form-field>
        <input matInput (keyup)="applyFilter($event, 'ID')" placeholder="Search by ID">
        </mat-form-field>
        </td>
        <td>
        <mat-form-field>
        <input matInput (keyup)="applyFilter($event, 'name')" placeholder="Search by Name">
        </mat-form-field>
        </td>
        <td>
        <mat-form-field>
        <input matInput (keyup)="applyFilter($event, 'rank')" placeholder="Search by Rank">
        </mat-form-field>
        </td>
        <td>
        <mat-form-field>
        <input matInput (keyup)="applyFilter($event, 'acronym')" placeholder="Search by Acronym">
        </mat-form-field>
        </td>
        </tr>
      </table>
      </div>

      <mat-table #table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="ID">
          <mat-header-cell *matHeaderCellDef mat-sort-header> ID </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.ID}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="rank">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Rank </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.rank}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="acronym">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Acronym </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.acronym}} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </div>

    <mat-paginator #paginator
                    [pageSize]="10"
                    [pageSizeOptions]="[5, 10, 20]"
                    [showFirstLastButtons]="true">
    </mat-paginator>
  </mat-tab>


</mat-tab-group>