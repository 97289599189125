import {Component, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { mockAssets } from '../../data/mock-assets';
@Component({
  selector: 'hrt-mat-table',
  styleUrls: ['./hrt-mat-table.component.scss'],
  templateUrl: './hrt-mat-table.component.html',
  animations: [
    trigger('slideDown', [
      state('false', style({ height: '0px', opacity: 0, overflow: 'hidden' })),
      state('true', style({ height: '*', opacity: 1, overflow: 'hidden' })),
      transition('false <=> true', animate('500ms ease-in-out'))
    ])
  ]
})
export class TableBasicExample {
  displayedColumns = ['ID', 'name', 'rank', 'acronym'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  filterValues: { [key: string]: string } = {};
  globalFilter = '';
  showColumnFilters: boolean = false;

  toggleColumnFilters() {
    this.showColumnFilters = !this.showColumnFilters;
  }

  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = this.createFilter();
  }

  applyFilter(event: Event, column?: string) {
    const filterValue = (event.target as HTMLInputElement).value;
    if (column) {
      this.filterValues[column] = filterValue.trim().toLowerCase();
    } else {
      this.globalFilter = filterValue.trim().toLowerCase();
    }
    this.dataSource.filter = JSON.stringify({
      global: this.globalFilter,
      columns: this.filterValues
    });
  }

  createFilter(): (data: any, filter: string) => boolean {
  return (data, filter) => {
    let searchTerms = JSON.parse(filter);
    let globalMatch = !searchTerms.global || Object.keys(data).some(key =>
      data[key].toString().toLowerCase().includes(searchTerms.global));
    let columnMatch = Object.keys(searchTerms.columns).every(column => {
      let columnFilter = searchTerms.columns[column];
      let val = data[column] === null ? '' : data[column];
      return !columnFilter || val.toString().toLowerCase().includes(columnFilter);
    });
    return globalMatch && columnMatch;
  };
  }
}


export interface Element {
  name: string;
  ID: number;
  rank: number;
  acronym: string;
}

const ELEMENT_DATA: Element[] = [
  {ID: 1, name: 'aaa', rank: 1.0079, acronym: 'a'},
  {ID: 2, name: 'bbb', rank: 4.0026, acronym: 'b'},
  {ID: 3, name: 'ccc', rank: 6.941, acronym: 'c'},
  {ID: 4, name: 'ddd', rank: 9.0122, acronym: 'd'},
  {ID: 5, name: 'eee', rank: 10.811, acronym: 'e'},
  {ID: 6, name: 'fff', rank: 12.0107, acronym: 'f'},
  {ID: 7, name: 'ggg', rank: 14.0067, acronym: 'g'},
  {ID: 8, name: 'hhh', rank: 15.9994, acronym: 'h'},
  {ID: 9, name: 'iii', rank: 18.9984, acronym: 'i'},
  {ID: 10, name: 'jjj', rank: 20.1797, acronym: 'j'},
  {ID: 11, name: 'kkk', rank: 22.9897, acronym: 'k'},
  {ID: 12, name: 'lll', rank: 24.305, acronym: 'l'},
  {ID: 13, name: 'mmm', rank: 26.9815, acronym: 'm'},
  {ID: 14, name: 'nnn', rank: 28.0855, acronym: 'n'},
  {ID: 15, name: 'ooo', rank: 30.9738, acronym: 'o'},
  {ID: 16, name: 'ppp', rank: 32.065, acronym: 'p'},
  {ID: 17, name: 'qqq', rank: 35.453, acronym: 'q'},
  {ID: 18, name: 'rrr', rank: 39.948, acronym: 'r'},
  {ID: 19, name: 'sss', rank: 39.0983, acronym: 's'},
  {ID: 20, name: 'ttt', rank: 40.078, acronym: 't'},
];