export enum ProductType {
    All = -1,
    Generic = 0,

    ScrewPackage = 100,
    BareScrew = 101,
    PistonPackage = 102,
    BarePiston = 103,
    Motor = 104,
    Starter = 105,
    Omni = 106,
    Gforce = 107,
    OilPump = 108,
    OilSeparator = 109,
    IntelliSocRetro = 110,

    Evaporator = 200,
    Condenser = 201,
    AirCompressor = 202,
    CoolingTower = 203,
};

export class ProductTypeName {
    Id: ProductType = ProductType.Generic;
    Name: string = "";
}

export const ProductTypes: ProductTypeName[] = [
    { Id: ProductType.All, Name: "All" },
    { Id: ProductType.Generic, Name: "Generic" },

    { Id: ProductType.ScrewPackage, Name: "Screw Package" },
    { Id: ProductType.BareScrew, Name: "Bare Screw" },
    { Id: ProductType.PistonPackage, Name: "Piston Package" },
    { Id: ProductType.BarePiston, Name: "Bare Piston" },
    { Id: ProductType.Motor, Name: "Motor" },
    { Id: ProductType.Starter, Name: "Starter" },
    { Id: ProductType.Omni, Name: "Omni" },
    { Id: ProductType.Gforce, Name: "GForce" },
    { Id: ProductType.OilPump, Name: "Oil Pump" },
    { Id: ProductType.OilSeparator, Name: "Oil Separator" },
    { Id: ProductType.IntelliSocRetro, Name: "IntelliSOC Retrofit" },

    { Id: ProductType.Evaporator, Name: "Evaporator" },
    { Id: ProductType.Condenser, Name: "Condenser" },
    { Id: ProductType.AirCompressor, Name: "Air Compressor" },
    { Id: ProductType.CoolingTower, Name: "Cooling Tower" }
];

export class ProductTypeIcon {
    Id: ProductType = ProductType.Generic;
    Name: string = "";
    Width: number = 0;
    Height: number = 0;
}

export const ProductIcons: ProductTypeIcon[] = [
    { Id: ProductType.Generic, Name: "Miscellaneous.png", Width: 36, Height: 36 },

    { Id: ProductType.ScrewPackage, Name: "screwpackage_vibrantBlue.png", Width: 80, Height: 36  },
    { Id: ProductType.BareScrew, Name: "screw_vibrantBlue.svg", Width: 36, Height: 36  },
    { Id: ProductType.PistonPackage, Name: "pistonpackage_vibrantBlue.png", Width: 80, Height: 36  },
    { Id: ProductType.BarePiston, Name: "piston_vibrantBlue.svg", Width: 36, Height: 36  },
    { Id: ProductType.Motor, Name: "Motor_vibrantBlue.svg", Width: 44, Height: 36  },
    { Id: ProductType.Starter, Name: "starter_vibrantBlue.svg", Width: 29, Height: 36  },
    { Id: ProductType.Omni, Name: "Omni_vibrantBlue.svg", Width: 32, Height: 32 },
    { Id: ProductType.Gforce, Name: "Omni_vibrantBlue.svg", Width: 36, Height: 36  },
    { Id: ProductType.OilPump, Name: "Miscellaneous.png", Width: 36, Height: 36 },
    { Id: ProductType.OilSeparator, Name: "Vessel_vibrantBlue.svg", Width: 36, Height: 36 },
    { Id: ProductType.IntelliSocRetro, Name: "Miscellaneous.png", Width: 36, Height: 36 },

    { Id: ProductType.Evaporator, Name: "PlateHeatExcahnger.png", Width: 36, Height: 36  },
    { Id: ProductType.Condenser, Name: "PlateHeatExcahnger.png", Width: 36, Height: 36 },
    { Id: ProductType.AirCompressor, Name: "Miscellaneous.png", Width: 36, Height: 36 },
    { Id: ProductType.CoolingTower, Name: "Miscellaneous.png", Width: 36, Height: 36 }
];

export function GetCustomTypeIcon(id: number) {
    let iconData = ProductIcons.find(x => <number>x.Id === id);
    if (iconData) {
        return "../assets/product-icons/" + iconData.Name;
    }
    else {
        return "../assets/product-icons/Miscellaneous.png";
    }
  }

export function GetCustomTypeIconWidth(id: number) {
    let iconData = ProductIcons.find(x => <number>x.Id === id);
    if (iconData) {
        let productTypeIconWidth = iconData.Width;
        let productTypeIconHeight = iconData.Height;
        return (24 / productTypeIconHeight) * productTypeIconWidth;
    }
    else {
        return 24;
    }
  }

export function GetProductTypeIcon(id: number): ProductTypeIcon {
    let iconData = ProductIcons.find(x => <number>x.Id === id);
    if (iconData) {
        return iconData;
    }
    else {
        return new ProductTypeIcon();
    }
  }

export function GetCustomTypeText(id: number) {
    let textData = ProductTypes.find(x => <number>x.Id === id);
    if (textData) {
      return textData.Name;
    }
    else {
      return "Generic";
    }
  }