import { Observable } from 'rxjs';
import { ConfigList, ConfigData } from '../../data/data.generated';
import { ApiService } from '../api.service';
import { TableResult, TableView } from './table';

export class ConfigApi {
  constructor(private _api: ApiService) {}

  public GetConfigList(
    listOptions: TableView
  ): Observable<TableResult<ConfigList>> {
    return this._api.Post('GetConfigList', listOptions);
  }

  public GetConfigDetails(page: string) {
    return this._api.Get('GetConfigDetails', { ConfigPage: page });
  }

  public GetConfigData(
    id: number,
    listOptions: TableView
  ): Observable<TableResult<ConfigData>> {
    const params: any = {
      ConfigListId: id,
      ...listOptions,
    };
    return this._api.Post('GetConfigData', params);
  }

  public AddOrEditConfigList(config: ConfigList) {
    return this._api.Post('AddOrEditConfigList', {
      ConfigListData: JSON.stringify(config),
    });
  }

  public AddOrEditConfigData(configData: ConfigData) {
    return this._api.Post('AddOrEditConfigData', {
      ConfigData: JSON.stringify(configData),
    });
  }

  public RemoveFromConfigList(id: number) {
    return this._api.Post('RemoveFromConfigList', {
      Id: id,
    });
  }

  public DeleteConfigData(id: number, configListId: number) {
    return this._api.Post('DeleteConfigData', {
      Id: id,
      ConfigListId: configListId,
    });
  }
}
