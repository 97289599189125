export enum Company {
    None = -1,
    York = 0,
    Vancouver = 1,
    Berlin = 4,
    DenBosch = 5,
    Bock = 6,
    Suzhou = 7,
    EmeaSkids = 8,
    CapeTown = 9,
    EmeaSkidsServiceSales = 10,
    DenBoschServiceSales = 11,
}

export const Companies = [
    { Id: Company.None, Name: "None" },
    { Id: Company.York, Name: "GEA York" },
    { Id: Company.Vancouver, Name: "GEA Vancouver" },
    { Id: Company.Berlin, Name: "GEA Berlin" },
    { Id: Company.DenBosch, Name: "GEA 's-Hertogenbosch" },
    { Id: Company.Bock, Name: "Bock" },
    { Id: Company.Suzhou, Name: "GEA Suzhou" },
    { Id: Company.EmeaSkids, Name: "GEA EMEA Skids" },
    { Id: Company.CapeTown, Name: "GEA Cape Town" },
    { Id: Company.EmeaSkidsServiceSales, Name: "GEA EMEA Skids (Service Sales)" },
    { Id: Company.DenBoschServiceSales, Name: "GEA 's-Hertogenbosch (Service Sales)" },
];

export function GetCompanyText(id: number) {
    let textData = Companies.find(x => <number>x.Id === id);
    if (textData) {
        return textData.Name;
    }
    else {
        return "Unknown";
    }
}