<div style="padding: 10px;">
    <table mat-table [dataSource]="dummyData" multiTemplateDataRows>
        <ng-container matColumnDef="Time">
            <th mat-header-cell *matHeaderCellDef>Time</th>
            <td mat-cell *matCellDef="let element"> {{element.Time}} </td>
        </ng-container>
        <ng-container matColumnDef="SuctionPressure">
            <th mat-header-cell *matHeaderCellDef>Suction Pressure</th>
            <td mat-cell *matCellDef="let element"> {{element.SuctionPressure}} </td>
        </ng-container>
        <ng-container matColumnDef="DischargePressure">
            <th mat-header-cell *matHeaderCellDef>Discharge Pressure</th>
            <td mat-cell *matCellDef="let element"> {{element.DischargePressure}} </td>
        </ng-container>
        <ng-container matColumnDef="CapacityPercentage">
            <th mat-header-cell *matHeaderCellDef>Slide Valve %</th>
            <td mat-cell *matCellDef="let element"> {{element.CapacityPercentage}} </td>
        </ng-container>
        <ng-container matColumnDef="ShutdownStatus">
            <th mat-header-cell *matHeaderCellDef>Shutdown Status</th>
            <td mat-cell *matCellDef="let element"> 
                <img [src]="getStatusImage(element.ShutdownStatus)" />
                {{element.ShutdownStatus}} 
            </td>
        </ng-container>
        <ng-container matColumnDef="WarningStatus">
            <th mat-header-cell *matHeaderCellDef>Warning Status</th>
            <td mat-cell *matCellDef="let element"> 
                <img [src]="getStatusImage(element.WarningStatus)" />
                {{element.WarningStatus}} 
            </td>
        </ng-container>
        <ng-container matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef aria-label="row actions" class="TableIconColumn">&nbsp;</th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button aria-label="expand row" (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
                    <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                    <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
                </button>
            </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumnsWithExpand.length">
                <div class="example-element-detail"
                    [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <table style="margin: 10px;">
                        <tr>
                            <td><p>Motor Current</p></td>
                            <td>{{element.MotorCurrent}}</td>
                            <td></td>
                            <td><p>Motor Speed</p></td>
                            <td>{{element.MotorSpeed}}</td>
                            <td></td>
                            <td><p>Total kW Usage</p></td>
                            <td>{{element.KWTotalUsage}}</td>
                        </tr>
                        <tr>
                            <td><p>Suction Temperature</p></td>
                            <td>{{element.SuctionTemperature}}</td>
                            <td></td>
                            <td><p>Saturated Suction Temp</p></td>
                            <td>{{element.SaturatedSuctionTemperature}}</td>
                        </tr>
                        <tr>
                            <td><p>Discharge Temperature</p></td>
                            <td>{{element.DischargeTemperature}}</td>
                            <td></td>
                            <td><p>Saturated Discharge Temp</p></td>
                            <td>{{element.SaturatedDischargeTemperature}}</td>
                        </tr>
                        <tr>
                            <td><p>Oil Pressure</p></td>
                            <td>{{element.OilPressure}}</td>
                            <td></td>
                            <td><p>Oil Temperature</p></td>
                            <td>{{element.OilTemperature}}</td>
                        </tr>
                    </table>
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumnsWithExpand"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsWithExpand;"
            class="example-element-row"
            [class.example-expanded-row]="expandedElement === row"
            (click)="expandedElement = expandedElement === row ? null : row"></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>
</div>