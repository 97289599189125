import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'asset-generic-data',
    templateUrl: './asset-generic-data.component.html',
    styleUrls: ['./asset-generic-data.component.scss']
})
export class AssetGenericDataComponent implements OnInit {

    constructor() { }

    @Input() public items: any[] = [];
    @Input() public downloadsAvailable: boolean = false;
    @Input() public downloadableDocuments: string[] = [];
    @Input() public downloadExtension: string = "";

    public labelValueColumns: string[] = ['label', 'value', 'download'];

    ngOnInit(): void {
    }

    getDownloadVisibility(item: string) {
        if (!this.downloadsAvailable) return 'hidden';
        return this.downloadableDocuments.includes(item) ? 'visible' : 'hidden';
    }

    getDownloadLink(item: string) {
        return 'https://rtselect.azurewebsites.net/Download/?filename=' + item + this.downloadExtension;
    }
}
