<!-- <table mat-table [dataSource]="items" class="MarginTop">
    <ng-container matColumnDef="label">
        <td mat-cell *matCellDef="let element" class="TableLabelColumn"> {{element.label}} </td>
    </ng-container>
    <ng-container matColumnDef="value">
        <td mat-cell *matCellDef="let element" style="width: 200px;"> 
            {{element.value}} 
        </td>
    </ng-container>
    <ng-container matColumnDef="download">
        <td mat-cell *matCellDef="let element">
            <a [style.visibility]="getDownloadVisibility(element.value)" [href]="getDownloadLink(element.value)" target="_blank" title="Download File">
                <mat-icon>download</mat-icon>
            </a>
        </td>
    </ng-container>
    <tr mat-row *matRowDef="let row; columns: labelValueColumns;"></tr>
</table> -->

<div class="grid-container">
    <div class="grid-content" *ngFor="let item of items">
        <div class="grid-item-name">{{item.label}}</div>
        <div class="grid-item-value">{{item.value}}</div>
    </div>
</div>