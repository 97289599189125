import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../shared/services/api.service';

@Component({
  selector: 'master-schedule',
  templateUrl: './master-schedule.component.html',
  styleUrls: ['./master-schedule.component.scss']
})
export class MasterScheduleComponent implements OnInit {

    constructor(private readonly _api: ApiService) { }

    public filterDelayed = <any>undefined;
    public filter = "";
    public items = <any>[];
    public formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    public noResults = false;
    public showNotes = false;
    public notesReferenceId = -1;

    ngOnInit(): void {
        this.refreshData();
    }

    public dateChanged() {
        //eturn (val: string) => {
        //   this._api.Projects.UpdateMasterScheduleDate(lineItem.Id, property, val)
        //       .subscribe(x => { });
        //;
    }

    public searchUpdated() {
        clearTimeout(this.filterDelayed);
        this.filterDelayed = setTimeout(() => {
            this.refreshData();
        }, 500);
    }

    refreshData() {
        this.items = undefined;
        this.noResults = false;
        this._api.Projects.GetMasterScheduleItems(this.filter)
            .subscribe((returnItems: any) => {
                if (returnItems) {

                    for (const item of returnItems.Data) {
                        item.TurnInDate = this.formatDate(item.TurnInDate);
                        item.RequestedShipDate = this.formatDate(item.RequestedShipDate);
                        item.CurrentShipDate = this.formatDate(item.CurrentShipDate);
                        item.ApprovalDrawingDate = this.formatDate(item.ApprovalDrawingDate);
                    }

                    let finalList: any[] = [];
                    let salesReps: string[] = [];

                    for (let i = 0; i < returnItems.Data.length; i++) {
                        let itemRow: any = returnItems.Data[i];
                        itemRow.SalesRep = "Compressors";
                        if (salesReps.indexOf(itemRow.SalesRep) === -1) {
                            salesReps.push(itemRow.SalesRep);
                            finalList.push({
                                SalesRep: itemRow.SalesRep,
                                ProjectNames: [],
                                Projects: []
                            });
                        }
                        let repIndex = salesReps.indexOf(itemRow.SalesRep);
                        let repRow = finalList[repIndex];
                        if (repRow.ProjectNames.indexOf(itemRow.Project) === -1) {
                            repRow.ProjectNames.push(itemRow.Project);
                            repRow.Projects.push({
                                ProjectName: itemRow.Project,
                                FirstItem: itemRow,
                                LineItems: []
                            });
                        }
                        let projectIndex = repRow.ProjectNames.indexOf(itemRow.Project);
                        let projectRow = repRow.Projects[projectIndex];
                        projectRow.LineItems.push(itemRow);
                    }
                    this.items = finalList;
                    this.noResults = this.items.length === 0;
                }
                else {

                }
            });
    }

    formatDate(dateStr: string) {
        let date = new Date(dateStr);
        if (date.getFullYear() < 1980 || !date.valueOf()) return "N/A";
        return date.toLocaleDateString('en-US', { timeZone: 'UTC' });
    }

    formatRep(str: string) {
        if (!str || str.trim() == "") return "None";
        let match = str.match(/\d+.*/)
        return match === null ? "None" : match[0];
    }

    getShipmentLink(str: string) {
        if (!str || str.trim() == "") return "None";
        if (str.match(/[xX][pP][oO].*\d+/))
            return "<a href='https://track.xpoweb.com/ltl-shipment/" + str.trim().split(" ").reverse()[0] + "/' target='_blank'>" + str + "</a>";
        if (str.match(/[fF][eE][dD][ ]*[eE][xX].*\d+/))
            return "<a href='https://www.fedex.com/apps/fedextrack/index.html?tracknumbers=" + str.trim().split(" ").reverse()[0] + "' target='_blank'>" + str + "</a>";
        return str;
    }

    formatCurrency(currency: number) {
        return this.formatter.format(currency);
    }

}
