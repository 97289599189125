import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HRTColumnDefinition } from '../data/filterConfiguration';
import { TableView } from './api/table';

@Injectable({
  providedIn: 'root'
})
export class HrtTableService {

  iconEvent = new BehaviorSubject<TableEvent | undefined>(undefined);

  constructor() { }

  addTypeIconToColumn(
    columnDef: HRTColumnDefinition[],
    keys: string[],
    component: any,
    data?: any
  ) {
    keys.forEach((key) => {
      const colIndex = columnDef.findIndex((def) => def.key === key);
      const rendererObj = {
        component: component,
        config: {
          field: key,
          data: data,
        },
      };
      columnDef[colIndex]['renderer'] = rendererObj;
    });
    return columnDef;
  }

  saveListOptionsToSS(tableId: string, listOptions: TableView) {
    sessionStorage.setItem(
      tableId + '-listOptions',
      JSON.stringify(listOptions)
    );
  }
}

export interface TableEvent {
  eventKey: string,
  eventVal?: any
};
