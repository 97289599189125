import { ProductSetup, ProductTab } from "../product-setup";

let setup: ProductSetup = new ProductSetup();

setup.Title = "IntelliSOC Retrofit";
setup.Icon = "IntelliSOC_vibrantBlue.svg";
setup.Image = "IntelliSOCRetro.png";

setup.CanCalculate = false;

setup.Tabs = [
    new ProductTab("General", "general")
];

export { setup };
