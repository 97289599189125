import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { Item, OrderStatusItem } from '../../data/data.generated';
import { TableResult, TableView } from './table';

export class Projects {
  constructor(private _api: ApiService) {}

  public GetItems(listOptions: TableView): Observable<TableResult<Item>> {
    return this._api.Get<TableResult<Item>>('GetItems', {
      ...listOptions,
    });
  }

  public GetOrderStatusItems(
    listOptions: TableView
  ): Observable<TableResult<OrderStatusItem>> {
    return this._api.Post('GetOrderStatusItems', listOptions);
  }

  public GetMasterScheduleItems(filter: string): Observable<{}> {
    return this._api.Get<{}>('GetMasterScheduleItems', {
      Filter: filter,
    });
  }

  public UpdateMasterScheduleDate(id: number, property: string, value: string) {
    return this._api.Get<{}>('UpdateMasterScheduleDate', {
      Id: id,
      DateName: property,
      DateValue: value,
    });
  }

  public UpdateOrderStatus(id: number, shippingShortage: boolean) {
    return this._api.Post('UpdateOrderStatus', {
      Id: id,
      ShippingShortage: shippingShortage,
    });
  }

  public ExportOrderStatusItems(
    listOptions: TableView,
    tabName?: string
  ) {
    var params: any = {
      ...listOptions,
      Filters: JSON.stringify(listOptions.Filters)
    };

    let date = new Date().toLocaleString('en-US');

    this._api.Download(
      'ExportOrderStatusItems',
      params,
      `${tabName ?? ''} Export ${date}`,
      'text/csv',
      (err: string) => {}
    );
  }
}
