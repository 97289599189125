import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import {
  AssetServiceRecord,
  AssetServiceRecordDashboardView,
} from 'src/app/shared/data/data.generated';
import {
  GetCustomTypeIcon,
  GetCustomTypeIconWidth,
} from 'src/app/shared/data/product-types';
import { GetConditionImage } from 'src/app/shared/data/service-conditions';
import { ServiceTypes } from 'src/app/shared/data/service-types';
import { ApiService } from 'src/app/shared/services/api.service';
import { Table } from 'src/app/shared/services/api/table';
import { ImagesService } from 'src/app/shared/services/images.service';
import { UtilityService } from 'src/app/shared/services/utility.service';

@Component({
  selector: 'gea-service-table',
  templateUrl: './service-table.component.html',
  styleUrls: ['./service-table.component.scss'],
})
export class ServiceTableComponent {
  @Input() serviceTable: Table<AssetServiceRecordDashboardView> =
    new Table<AssetServiceRecordDashboardView>();

  public getCustomTypeIcon = GetCustomTypeIcon;
  public getCustomTypeIconWidth = GetCustomTypeIconWidth;

  public pdfImage: string = this.images.Icons.FormatPdf;
  public infoImage: string = this.images.Icons.Information;

  public notesDialog: boolean = false;
  public notesItem: AssetServiceRecord = new AssetServiceRecord();

  public serviceColumns: string[] = [
    'Icon',
    'Location',
    'Manufacturer',
    'Model',
    'AlternativeModel',
    'Date',
    'Type',
    'Condition',
    'Report',
    'Notes',
  ];

  constructor(
    private router: Router,
    private images: ImagesService,
    private api: ApiService,
    private utilityService: UtilityService
  ) {}

  openAsset(id: number) {
    this.utilityService.openAsset(id);
  }

  formatDate(dateStr: string) {
    return new Date(dateStr).toLocaleDateString('en-US', { timeZone: 'UTC' });
  }

  getTypeDescription(id: number) {
    return ServiceTypes.find((x) => x.Id == id)?.Name ?? '';
  }

  getConditionImage(id: number) {
    return GetConditionImage(id, this.images);
  }

  fixUrl(filename: string) {
    return encodeURIComponent(filename);
  }

  downloadStorageReport(serviceRecord: AssetServiceRecord) {
    this.api.Assets.DownloadAssetServiceRecordReport(serviceRecord);
  }

  showNotes(item: AssetServiceRecord) {
    this.notesItem = item;
    this.notesDialog = true;
  }
}
