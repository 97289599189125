import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';

export interface IDeactivateComponent {
    canExit: () => Observable<boolean> | Promise<boolean> | boolean;
}

export class ConfirmDeactivateGuard implements CanDeactivate<IDeactivateComponent>{
 canDeactivate(component: IDeactivateComponent ) {
   return component.canExit();
 }
}