<div class="real-time-container" *ngIf="isConnectedAsset; else notPurchased">
  <div class="fetch-message" *ngIf="fetchingData">
    <mat-spinner class="data-loading" [diameter]="24"></mat-spinner>
    <span>Fetching data...</span>
  </div>
  <div class="unit-toggle" *ngIf="!fetchingData">
    <span class="toggle-label si">SI</span>
    <mat-slide-toggle [(ngModel)]="isUSUnits">
      <span class="toggle-label us">I-P</span>
    </mat-slide-toggle>
  </div>
  <div class="sections-container">
    <!-- Operating Point Section  -->
    <section class="section">
      <div class="section-header">
        <mat-icon>check_circle</mat-icon>
        <span class="section-title">Operating Status</span>
        <span class="timestamp">{{ data ? time : "" }}</span>
        <span
          class="status-dot"
          [ngClass]="websocketService.isSocketConnected ? 'green' : 'red'"
          [title]="
            websocketService.isSocketConnected
              ? 'Connected'
              : 'IoT Device Disconnected'
          "
        ></span>
      </div>
      <div class="card-container">
        <!-- Operating Point Card (added manually) -->
        <div class="card">
          <div class="card-title-container">
            <div class="title-info">
              <span class="display-name">Annunciation Status</span>
            </div>
            <!-- <mat-icon class="custom-svg insights">insights</mat-icon> -->
          </div>
          <div class="card-value-container card-type-2">
            <div class="value">
              <img
                [style.display]="data ? 'block' : 'none'"
                [src]="getShutdownImage()"
                class="shutdown-status-img"
              />
              <span class="img-val">{{ shutdownStatus }}</span>
            </div>
          </div>
        </div>
        <ng-container *ngFor="let card of operatingStatusCards">
          <div class="card">
            <div class="card-title-container">
              <div class="title-info">
                <span class="display-name">{{ card.displayName }}</span>
                <mat-icon
                  class="info-icon"
                  *ngIf="card.infoText"
                  [matTooltip]="card.infoText"
                  [matTooltipPosition]="'right'"
                  >info</mat-icon
                >
              </div>
              <mat-icon
                *ngIf="card.showChart"
                class="custom-svg insights"
                (click)="openChartModal(card)"
                >insights</mat-icon
              >
            </div>
            <div
              class="card-value-container"
              [ngClass]="assignCardType(card.unit)"
            >
              <ng-container
                *ngIf="card.key == 'motor_Running'; else regularCard"
              >
                <img
                  [style.display]="data ? 'block' : 'none'"
                  [src]="getMotorStatus(card.key)"
                  class="shutdown-status-img"
                />
                <span class="img-val">{{ motorStatusText }}</span>
              </ng-container>
              <span></span>
              <ng-template #regularCard>
                <span class="value">{{ getValue(card.key, card) }}</span>
                <span class="unit">{{ getUnit(card) }}</span>
              </ng-template>
            </div>
          </div>
        </ng-container>
      </div>
    </section>
    <!-- Energy Section -->
    <section class="section">
      <div class="section-header">
        <mat-icon>offline_bolt</mat-icon>
        <span class="section-title">Energy</span>
        <span class="timestamp">{{ data ? time : "" }}</span>
        <span
          class="status-dot"
          [ngClass]="websocketService.isSocketConnected ? 'green' : 'red'"
          [title]="
            websocketService.isSocketConnected
              ? 'Connected'
              : 'IoT Device Disconnected'
          "
        ></span>
      </div>
      <div class="card-container">
        <ng-container *ngFor="let card of energyCards">
          <div class="card">
            <div class="card-title-container">
              <div class="title-info">
                <span class="display-name">{{ card.displayName }}</span>
                <mat-icon
                  class="info-icon"
                  *ngIf="card.infoText"
                  [matTooltip]="card.infoText"
                  [matTooltipPosition]="'right'"
                  >info</mat-icon
                >
              </div>
              <mat-icon
                *ngIf="card.showChart"
                class="custom-svg insights"
                (click)="openChartModal(card)"
                >insights</mat-icon
              >
            </div>
            <div
              class="card-value-container"
              [ngClass]="assignCardType(card.unit)"
            >
              <span class="value">{{ getValue(card.key, card) }}</span>
              <span class="unit">{{ getUnit(card) }}</span>
            </div>
          </div>
        </ng-container>
      </div>
    </section>
    <!-- Temperature Section -->
    <section class="section">
      <div class="section-header">
        <mat-icon>device_thermostat</mat-icon>
        <span class="section-title">Temperature</span>
        <span class="timestamp">{{ data ? time : "" }}</span>
        <span
          class="status-dot"
          [ngClass]="websocketService.isSocketConnected ? 'green' : 'red'"
          [title]="
            websocketService.isSocketConnected
              ? 'Connected'
              : 'IoT Device Disconnected'
          "
        ></span>
      </div>
      <div class="card-container">
        <ng-container *ngFor="let card of temperatureCards">
          <div class="card">
            <div class="card-title-container">
              <div class="title-info">
                <span class="display-name">{{ card.displayName }}</span>
                <mat-icon
                  *ngIf="card.infoText"
                  [matTooltip]="card.infoText"
                  [matTooltipPosition]="'right'"
                  >info</mat-icon
                >
              </div>
              <mat-icon
                *ngIf="card.showChart"
                class="custom-svg insights"
                (click)="openChartModal(card)"
                >insights</mat-icon
              >
            </div>
            <div
              class="card-value-container"
              [ngClass]="assignCardType(card.unit)"
            >
              <span class="value">{{ getValue(card.key, card) }}</span>
              <span class="unit">{{ getUnit(card) }}</span>
            </div>
          </div>
        </ng-container>
      </div>
    </section>
    <!-- Pressure Section -->
    <div class="section">
      <div class="section-header">
        <mat-icon>speed</mat-icon>
        <span class="section-title">Pressure</span>
        <span class="timestamp">{{ data ? time : "" }}</span>
        <span
          class="status-dot"
          [ngClass]="websocketService.isSocketConnected ? 'green' : 'red'"
          [title]="
            websocketService.isSocketConnected
              ? 'Connected'
              : 'IoT Device Disconnected'
          "
        ></span>
      </div>
      <div class="card-container">
        <ng-container *ngFor="let card of pressureCards">
          <div class="card">
            <div class="card-title-container">
              <div class="title-info">
                <span class="display-name">{{ card.displayName }}</span>
                <mat-icon
                  *ngIf="card.infoText"
                  [matTooltip]="card.infoText"
                  [matTooltipPosition]="'right'"
                  >info</mat-icon
                >
              </div>
              <mat-icon
                *ngIf="card.showChart"
                class="custom-svg insights"
                (click)="openChartModal(card)"
                >insights</mat-icon
              >
            </div>
            <div
              class="card-value-container"
              [ngClass]="assignCardType(card.unit)"
            >
              <span class="value">{{ getValue(card.key, card) }}</span>
              <span class="unit">{{ getUnit(card) }}</span>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <!-- Miscellaneous Section -->
    <section class="section">
      <div class="section-header">
        <mat-icon>group_work</mat-icon>
        <span class="section-title">Miscellaneous</span>
        <span class="timestamp">{{ data ? time : "" }}</span>
        <span
          class="status-dot"
          [ngClass]="websocketService.isSocketConnected ? 'green' : 'red'"
          [title]="
            websocketService.isSocketConnected
              ? 'Connected'
              : 'IoT Device Disconnected'
          "
        ></span>
      </div>
      <div class="card-container">
        <ng-container *ngFor="let card of miscellaneousCards">
          <div class="card">
            <div class="card-title-container">
              <div class="title-info">
                <span class="display-name">{{ card.displayName }}</span>
                <mat-icon
                  *ngIf="card.infoText"
                  [matTooltip]="card.infoText"
                  [matTooltipPosition]="'right'"
                  >info</mat-icon
                >
              </div>
              <mat-icon
                *ngIf="card.showChart"
                class="custom-svg insights"
                (click)="openChartModal(card)"
                >insights</mat-icon
              >
            </div>
            <div
              class="card-value-container"
              [ngClass]="assignCardType(card.unit)"
            >
              <span class="value">{{ getValue(card.key, card) }}</span>
              <span class="unit">{{ getUnit(card) }}</span>
            </div>
          </div>
        </ng-container>
      </div>
    </section>
  </div>
</div>

<ng-template #notPurchased>
  <div class="message-div">
    <h2>
      Real-time data monitoring has not been purchased or is not available for
      this asset.
    </h2>
  </div>
</ng-template>
