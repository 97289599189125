<!-- @if (isEnvironment == 'local') {
<hrt-tab
  [hasTabs]="true"
  [title]="'Sites'"
  [tabs]="siteTabs"
  [renderAllTabs]="false"
></hrt-tab>
} @else {
} -->
<hrt-tab [hasTabs]="false" [title]="'Sites'"></hrt-tab>
<gea-site-table [siteTable]="table"></gea-site-table>
