import {
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Output,
} from '@angular/core';

@Directive({
  selector: '[hrtDndFile]',
  standalone: true,
})
export class DndFileDirective {
  constructor() {}

  counter = 0;

  @HostBinding('class.fileover') fileOver: boolean;
  @Output() fileDropped = new EventEmitter<any>();

  @HostListener('dragover', ['$event'])
  onDragOver(event: Event) {
    event.preventDefault();
  }

  @HostListener('dragenter', ['$event']) onDragEnter(event: DragEvent) {
    event.preventDefault();
    this.counter++;
    this.fileOver = true;
  }

  @HostListener('dragleave', ['$event']) onDragLeave(event: DragEvent) {
    event.preventDefault();
    this.counter--;
    if (this.counter == 0) {
      this.fileOver = false;
    }
  }

  @HostListener('drop', ['$event']) onDrop(event: DragEvent) {
    event.preventDefault();
    this.counter = 0;
    this.fileOver = false;
    const files = event.dataTransfer?.files;
    if (files && files?.length > 0) {
      this.fileDropped.emit(files);
    }
  }
}
