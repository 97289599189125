import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfigData, ConfigList } from 'src/app/shared/data/data.generated';

@Component({
  selector: 'app-add-config-popup',
  templateUrl: './add-config-popup.component.html',
  styleUrl: './add-config-popup.component.scss',
})
export class AddConfigPopupComponent {
  isUIConfigDropdown = [
    { key: false, label: 'No' },
    { key: true, label: 'Yes' },
  ];

  action: string;
  newConfig = new ConfigList(); // for adding new entry.
  newConfigData = new ConfigData(); // for editing a config.

  public homepageTargets = [
    { value: 'productSlides', viewValue: 'Product Slides' },
    { value: 'appInfo', viewValue: 'App info' },
  ];

  constructor(
    public dialogRef: MatDialogRef<AddConfigPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.action = this.data.action ? this.data.action: 'Add';
    if (this.data.flag == 'ConfigList') {
      if (this.data.row) {
        this.newConfig = this.data.row;
        this.newConfig.Id = this.data.row.Id;
      }
    } else {
      if (this.data.row) {
        this.newConfigData = this.data.row;
      }
      this.newConfigData.ConfigListId = this.data.ConfigListId;
      this.newConfigData.ConfigListValue = this.data.ConfigListValue;
    }
  }

  sendDataToParent() {
    this.dialogRef.close(this.newConfigData);
  }

  sendListToParent() {
    this.dialogRef.close(this.newConfig);
  }

  closePopup() {
    this.dialogRef.close(false);
  }
}
