import { Observable } from 'rxjs';
import { ApiService } from "../api.service";
import { Bookmark } from '../../data/data.generated';
import { TableResult, TableView } from './table';

export class BookmarkApi {
    constructor(private _api: ApiService) {
    }

    public AddBookmark( id: number, bookmarkType: string, url: string, bookmarkName: string) {
        return this._api.Post("AddBookmark",
        {
            Id : id,
            BookmarkType: bookmarkType,
            URL: url,
            BookmarkName : bookmarkName,
        });
    }

    public GetBookmarks(listOptions: TableView): Observable<TableResult<Bookmark>> {
        return this._api.Get<TableResult<Bookmark>>("GetBookmarks",
            {
                ...listOptions
            });
    }

    public EditBookmark( id: number, bookmarkType: string, bookmarkName: string){
        return this._api.Post("EditBookmark",
        {
            Id : id,
            BookmarkType: bookmarkType,
            BookmarkName : bookmarkName,
        });
    }

    public DeleteBookmark( id: number, bookmarkType: string){
        return this._api.Post("DeleteBookmark",
        {
            Id : id,
            BookmarkType: bookmarkType
        });
    }
}