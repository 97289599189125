import { Role } from "./roles";

export enum ApplicationFeature {
    Home = 0,
    Dashboard = 1,
    Users = 2,
    Companies = 3,
    Sites = 4,
    Assets = 5,
    OrderStatus = 6,
    Proposals = 7,
    Products = 8,
    Tools = 9,
    Parts = 10,
    Documents = 11,
    Feedback = 12,

    AdminPage = 1000,
};

export const Features = [
    { Id: ApplicationFeature.Home, Name: "Home" },
    { Id: ApplicationFeature.Dashboard, Name: "Dashboard" },
    { Id: ApplicationFeature.Users, Name: "Users" },
    { Id: ApplicationFeature.Companies, Name: "Companies" },
    { Id: ApplicationFeature.Sites, Name: "Sites" },
    { Id: ApplicationFeature.Assets, Name: "Assets" },
    { Id: ApplicationFeature.OrderStatus, Name: "Order Status" },
    { Id: ApplicationFeature.Proposals, Name: "Proposals" },
    { Id: ApplicationFeature.Products, Name: "Products" },
    { Id: ApplicationFeature.Tools, Name: "Tools" },
    { Id: ApplicationFeature.Parts, Name: "Parts" },
    { Id: ApplicationFeature.Documents, Name: "Documents" },
    { Id: ApplicationFeature.Feedback, Name: "Feedback" },

    { Id: ApplicationFeature.AdminPage, Name: "Admin Page" },
];

export function FeatureHasEdit(feature: ApplicationFeature) {
    switch (feature)
    {
        case ApplicationFeature.Users:
        case ApplicationFeature.Feedback:
        case ApplicationFeature.Assets:
            return true;
        default:
            return false;
    }
}

export function CanViewByDefault(feature: ApplicationFeature, role: Role) {
    switch (feature)
    {
        case ApplicationFeature.Home:
            return true;
        case ApplicationFeature.Dashboard:
            return role >= Role.ExternalSales;
        case ApplicationFeature.Users:
            return role >= Role.InternalAdmin;
        case ApplicationFeature.Companies:
            return role >= Role.InternalAdmin;
        case ApplicationFeature.Sites:
            return role >= Role.Customer;
        case ApplicationFeature.Assets:
            return role >= Role.Customer;
        case ApplicationFeature.OrderStatus:
            return role >= Role.ExternalSales;
        case ApplicationFeature.Proposals:
            return role >= Role.InternalSales;
        case ApplicationFeature.Products:
            return role >= Role.InternalSales;
        case ApplicationFeature.Tools:
            return role >= Role.InternalSales;
        case ApplicationFeature.Parts:
            return role >= Role.InternalSales;
        case ApplicationFeature.Documents:
            return role >= Role.Customer;
        case ApplicationFeature.Feedback:
            return true;
        case ApplicationFeature.AdminPage:
            return role === Role.DevAdmin;
    }

    return false;
}

export function CanEditByDefault(feature: ApplicationFeature, role: Role) {
    switch (feature) {
        case ApplicationFeature.Home:
            return true;
        case ApplicationFeature.Dashboard:
            return role >= Role.InternalAdmin;
        case ApplicationFeature.Users:
            return role >= Role.InternalAdmin;
        case ApplicationFeature.Companies:
            return role >= Role.InternalAdmin;
        case ApplicationFeature.Sites:
            return role >= Role.InternalAdmin;
        case ApplicationFeature.Assets:
            return role >= Role.InternalAdmin;
        case ApplicationFeature.OrderStatus:
            return role >= Role.ExternalSales;
        case ApplicationFeature.Proposals:
            return role >= Role.InternalSales;
        case ApplicationFeature.Products:
            return role >= Role.InternalSales;
        case ApplicationFeature.Tools:
            return role >= Role.InternalSales;
        case ApplicationFeature.Parts:
            return role >= Role.InternalAdmin;
        case ApplicationFeature.Documents:
            return role >= Role.InternalAdmin;
        case ApplicationFeature.Feedback:
            return role >= Role.InternalAdmin;
        case ApplicationFeature.AdminPage:
            return role === Role.DevAdmin;
    }
}