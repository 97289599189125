import { Observable } from 'rxjs';
import { ApiService } from "../api.service";
import { Document } from '../../data/data.generated';
import { TableResult, TableView } from './table';

export class Documents {
    constructor(private _api: ApiService) {
    }

    public GetDocuments(listOptions: TableView): Observable<TableResult<Document>> {
        return this._api.Get<TableResult<Document>>("GetDocuments",
            {
                ...listOptions
            });
    }

    public GetDocumentDetails(documentId: number): Observable<Document> {
        return this._api.Get<Document>("GetDocumentDetails", { Id: documentId })
    }

    public DownloadDocument(document: Document, finished: (err: string) => void) {
        this._api.Download("DownloadDocument",
            {
                Id: document.Id
            },
            document.OriginalFileName,
            document.FileType.toLowerCase() == "pdf"
                ? "application/pdf" : "application/octet-stream",
        finished);
    }

}
