import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AssetServiceRecord } from 'src/app/shared/data/data.generated';
import { GetConditionImage, ServiceCondition, ServiceConditions } from 'src/app/shared/data/service-conditions';
import { ServiceType, ServiceTypes } from 'src/app/shared/data/service-types';
import { ApiService } from 'src/app/shared/services/api.service';
import { ImagesService } from 'src/app/shared/services/images.service';

@Component({
  selector: 'edit-service-record',
  templateUrl: './edit-service-record.component.html',
  styleUrls: ['./edit-service-record.component.scss']
})
export class EditServiceRecordComponent implements OnInit {

  constructor(
    private readonly api: ApiService,
    private images: ImagesService,
    private router: Router,
    private route: ActivatedRoute) { }

  public mode: string = "add";
  public assetId: number = 0;
  public serviceRecordId: number = 0;

  public availableTypes = ServiceTypes;
  public conditions = ServiceConditions;
  public vibrationId = ServiceType.Vibration;

  public projectNumber: string = "";
  public serialNumber: string = "";

  public data: AssetServiceRecord = new AssetServiceRecord();
  public file: File | undefined = undefined;

  public validationMessage: string = "";

  ngOnInit(): void {
    this.route.params.subscribe((p: any) => {
      this.mode = p['mode'];
      this.assetId = Number.parseFloat(p['assetId']);
      this.api.Assets.GetAssetDetails(this.assetId)
        .subscribe(x => {
          if (x.Asset) {
            this.projectNumber = x.Asset.SalesOrderNumber;
            this.serialNumber = x.Asset.SerialNumber;
          }
        });
      if (this.mode == "edit") {
        this.serviceRecordId = Number.parseFloat(p['serviceRecordId']);
        this.api.Assets.GetAssetServiceRecord(this.serviceRecordId)
          .subscribe(x => {
            this.data = x;
          });
      }
    });
  }

  getConditionImage(id: number) {
    return GetConditionImage(id, this.images);
  }

  getConditionText(id: number) {
    return ServiceConditions.find(x => x.Id === id)?.Name;
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      this.file = file;
    }
  }

  cancel() {
    this.router.navigate(['/asset-details', { service: 'true', id: this.assetId }]);
  }

  submit() {
    if (!this.validate()) return;
    this.data.AssetId = this.assetId;
    if (this.data.Id == 0) {
      this.api.Assets.CreateAssetServiceRecord(this.data, this.file)
        .subscribe(x => {
          this.router.navigate(['/asset-details', { service: 'true', id: this.assetId }]);
        });
    }
    else {
      this.api.Assets.UpdateAssetServiceRecord(this.data, this.file)
        .subscribe(x => {
          this.router.navigate(['/asset-details', { service: 'true', id: this.assetId }]);
        });
    }
  }

  validate(): boolean {
    this.validationMessage = "";
    let isValid = true;
    if (this.data.Date == "") {
      this.validationMessage += "Date required. ";
      isValid = false;
    }
    if (this.data.ConditionId == 0) {
      this.validationMessage += "Condition required. ";
      isValid = false;
    }
    return isValid;
  }

}
