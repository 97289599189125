import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ConfigService } from '../../services/config.service';
declare var $: any;

@Component({
    selector: 'geo-chart',
    templateUrl: './geo-chart.component.html',
    styleUrls: ['./geo-chart.component.scss']
})
export class GeoChartComponent implements AfterViewInit, OnChanges {

    @Input() public Data: (string | number)[][] = [];
    @Input() public Options: any = GeoChartOptions.WorldViewOptions;

    @Output() RegionClicked = new EventEmitter<string>();
    
    // @ts-ignore
    @ViewChild('chartContainer') chartContainer: ElementRef;

    constructor(
        private config: ConfigService
    ) { }

    ngAfterViewInit(): void {
        // @ts-ignore
        google.charts.load('current', {
            'packages': ['geochart'],
            'mapsApiKey': this.config.fetchConfigValue('GeneralSettings', 'mapsApiKey')
        });

        // @ts-ignore
        google.charts.setOnLoadCallback(() => this.drawRegionsMap(this.chartContainer));

    }

    ngOnChanges(changes: SimpleChanges): void {
        this.drawRegionsMap(this.chartContainer);
    }

    private drawRegionsMap(chartContainer: ElementRef) {

        var dataArray: (string | number)[][] = [];
        if (this.Options.resolution == GeoChartResolutions.countries)
            dataArray.push(["Country", "Count"]);
        else if (this.Options.resolution == GeoChartResolutions.provinces &&
            this.Options.region === "US")
            dataArray.push(["State", "Count"]);
        else if (this.Options.resolution == GeoChartResolutions.provinces &&
            this.Options.region !== "US")
            dataArray.push(["Province", "Count"]);
        else
            dataArray.push(["Region", "Count"]);
        dataArray = dataArray.concat(this.Data);
        
        // @ts-ignore
        if (dataArray && dataArray.length > 0) {
            // @ts-ignore
            var data = google.visualization.arrayToDataTable(dataArray);
        }

        // @ts-ignore
        if (chartContainer) {
            // @ts-ignore
            var chart = new google.visualization.GeoChart(chartContainer.nativeElement);
        }

        // @ts-ignore
        google.visualization.events.addListener(chart, 'select', () => {
            let selection = chart.getSelection();
            if (selection[0] !== undefined && selection[0].row !== undefined) {
                let value = <string>this.Data[selection[0].row][0];
                if (value) this.RegionClicked.emit(value);
            }
        });

        // @ts-ignore
        //google.visualization.events.addListener(chart, 'ready', function () {
        //    $('#chartContainer').find('iframe').contents().find('shape').each(function () {
        //        // @ts-ignore
        //        if ($(this).prop('fillcolor') != 'white' && $(this).prop('fillcolor') != 'none' && typeof ($(this).prop('fillcolor')) != 'undefined') {
        //            // @ts-ignore
        //            $(this).css('cursor', 'pointer');
        //        }
        //    });
        //});

        

        chart.draw(data, this.Options);
    }
}

// Documentation:
// https://developers.google.com/chart/interactive/docs/gallery/geochart

export enum GeoChartResolutions {
    countries = "countries",
    provinces = "provinces",
    metros = "metros",
}

export class GeoChartOptions {
    public static WorldViewOptions: GeoChartOptions = {
        region: "world",
        resolution: GeoChartResolutions.countries,
        colorAxis: {
            colors: ['lightGray', '#0303B8']
        },
        backgroundColor: '#F7F7F7',
        datalessRegionColor: 'white',
        defaultColor: 'black',
        displayMode: 'auto',
        height: 675,
        legend: 'none',
        chartArea: { 'width': '100%', 'height': '80%' },
        forceIFrame: false,
    };
    
    public static StateViewOptions: GeoChartOptions = {
        region: "US",
        resolution: GeoChartResolutions.provinces,
        colorAxis: {
            colors: ['lightGray', '#0303B8']
        },
        backgroundColor: '#F7F7F7',
        datalessRegionColor: 'white',
        defaultColor: 'black',
        displayMode: 'auto',
        height: 675,
        legend: 'none',
        chartArea: { 'width': '100%', 'height': '80%' },
        forceIFrame: false,
    };

    public region: string = "";
    public resolution: GeoChartResolutions = GeoChartResolutions.countries;
    public colorAxis: any = {};
    public backgroundColor: string = "";
    public datalessRegionColor: string = "";
    public defaultColor: string = "";
    public displayMode: string = "";
    public height: number | string = "";
    public legend: any | undefined = "";
    public chartArea: any | undefined = "";
    public forceIFrame: boolean = false;
}
