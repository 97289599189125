<h1 mat-dialog-title class="description">Bookmark Details</h1>
<div mat-dialog-content class="desc-content">
    <form #bookmarkForm="ngForm">
        <table>
            <tr class="grid-row">
                <td>
                    Name:
                    <mat-form-field class="bookmark-name">
                        <input matInput name="bookmarkName" [(ngModel)]="data.bookmarkName" #bookmarkName="ngModel" required>
                        <mat-error *ngIf="bookmarkName.errors?.['required'] && (bookmarkName.dirty || bookmarkName.touched)">
                            Name is required.
                        </mat-error>
                    </mat-form-field>
                </td>
                <td>
                    URL: <i class="url">{{data.url}}</i>
                </td>
            </tr>
        </table>
    </form>
</div>
<div mat-dialog-actions class="actions">
    <button mat-button class="geaui-button-secondary actionbutton" (click)="closePopup()">Cancel</button>
    <button mat-button class="geaui-button-primary actionbutton" [mat-dialog-close]="data.bookmarkName" cdkFocusInitial [disabled]="bookmarkForm.invalid">Save</button>
</div>