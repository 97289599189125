import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { debounceTime } from 'rxjs';
import { Asset, AssetServiceRecord, AssetServiceRecordDashboardView } from 'src/app/shared/data/data.generated';
import { GetFilterConfigKey, GetFilterConfigType, IsClientSideFilter, SelectedFilters } from 'src/app/shared/data/filterConfiguration';
import { GetCustomTypeIcon, GetCustomTypeIconWidth, GetCustomTypeText } from 'src/app/shared/data/product-types';
import { GetConditionImage } from 'src/app/shared/data/service-conditions';
import { ApiService } from 'src/app/shared/services/api.service';
import { Table, TableView } from 'src/app/shared/services/api/table';
import { DashboardQueryType, DashboardService, DashboardServiceCondition } from 'src/app/shared/services/dashboard.service';
import { ImagesService } from 'src/app/shared/services/images.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { WebsocketService } from 'src/app/shared/services/websocket.service';

@Component({
  selector: 'hrt-dashboard-table',
  templateUrl: './dashboard-table.component.html',
  styleUrl: './dashboard-table.component.scss'
})
export class DashboardTableComponent {


  @Input() set id(id: string) {
    this.tableId = id;
  }
  @Input() dashboardTable: Table<any>;
  @Input() displayedColumns: string[];
  @Input() hasFilter: boolean = true;
  @Input() hasPagination: boolean = true;
  @Input() presetFilters: SelectedFilters;
  
  @Output() exportClicked = new EventEmitter();
  @Output() filterApplied = new EventEmitter();
  @Output() rowClicked = new EventEmitter();


  listOptions: TableView = new TableView();
  feedbackCount: number = 0;
  //dashboardTable: Table<Asset> = new Table<Asset>(this.loader);
  searchPlaceholder = 'Search Dashboard';
  showMotorStatus = true;
  public pdfImage: string = this.images.Icons.FormatPdf;
  public infoImage: string = this.images.Icons.Information;


  assetsType: DashboardQueryType = DashboardQueryType.Assets;
  selectedCountry: string = '';
  selectedState: string = '';
  selectedType: DashboardQueryType = DashboardQueryType.Assets;
  selectedCondition: DashboardServiceCondition = DashboardServiceCondition.All;
  selectedProductType: number = -1;
  fetchConnectedAssets: boolean = true;
  appliedType: DashboardQueryType = DashboardQueryType.Assets;

  public getCustomTypeText = GetCustomTypeText;
  public getCustomTypeIcon = GetCustomTypeIcon;
  public getCustomTypeIconWidth = GetCustomTypeIconWidth;

  public shutdownStatusMapping: any = {
    0: 'Normal',
    1: 'Warning',
    2: 'Shutdown',
  };

  tableId: string;

  
  constructor(
    public dashboardService: DashboardService,
    private loader: LoaderService,
    private api: ApiService,
    private router: Router,
    private images: ImagesService,
    public websocket: WebsocketService,
    private utilityService: UtilityService
) {}

  ngOnInit(): void {
    // this.showMotorStatus ? this.displayedColumns.push('MotorStatus') : null;
    // this.dashboardTable.View.SortColumn = 'LastModified';
    // this.dashboardTable.View.SortDesc = 'true';
    //this.applyInitialProductFilter();

    
  }

  handleRowClicked(rowData: any) {
    // console.log('Row click event', );
    this.rowClicked.emit(rowData);
    // this.utilityService.openAsset(rowData.Id);
  }

  getConditionImage(id: number) {
    return GetConditionImage(id, this.images);
  }

  openRealTimeForAsset(id: number, externalId: string) {
    if (this.websocket.getAnnunStatusValue(externalId) != null) {
      this.router.navigate(['/asset-details', { id: id, realTime: true }]);
    } else {
      this.utilityService.openAsset(id);
    }
  }


  getAnnunImage(annun_status: number) {
    if(annun_status != null){
      switch (annun_status) {
        case 0:
          return '../../../assets/icons/Check_Circle.svg';
        case 1:
          return '../../../assets/icons/Warning.svg';
        case 2:
          return '../../../assets/icons/Error.svg';
        default:
          return null;
      }

    } else {
      return '../../../assets/icons/NotAvailable.png';
    }
    
  }

  searchChange(searchText: string) {
    if (searchText.trim() !== this.dashboardTable.inputSubject.getValue() || searchText === '') {
      this.dashboardTable.inputSubject.next(searchText);
    }
  }

  applyFilters(filterEvent: any) {
    this.dashboardTable.View.Filters = {};
    //this.applyInitialProductFilter();
    this.dashboardTable.clearFilterLists();
    Object.keys(filterEvent).map((key: string) => {
      const filterKey = GetFilterConfigKey(key);
      const filterType = GetFilterConfigType(key);
      const values = filterEvent[key];
      if (values.length <= 0) return;
      // Convert from Type - selectedFilters to Table Filters
      this.dashboardTable.addToFilter(filterKey, values, key, filterType);
    });
    this.filterApplied.emit(filterEvent);
    // this.dashboardTable.SearchUpdated();
  }

  exportTable() {
    this.exportClicked.emit();
  }

  fixUrl(filename: string) {
    return encodeURIComponent(filename);
  }

  openReports(data: AssetServiceRecordDashboardView) {
    let url =
      'https://hrt-fesportal-hgamdfadh9dyb3cv.westeurope-01.azurewebsites.net/RepairReports/' +
      this.fixUrl(data.ReportLocation);
    if (data.HasReport) {
      window.open(url, '_blank')?.focus();
    }
    if (data.HasStorageReport) {
      this.api.Assets.DownloadAssetServiceRecordReport(data);
    }
    event?.stopPropagation();
  }

  showNotes(item: AssetServiceRecord, event: MouseEvent) {
    event.stopPropagation();
    // this.notesItem = item;
    // this.notesDialog = true;
  }

}
