<img
  class="type-icon"
  *ngIf="config.field == 'ProductTypeId'"
  [src]="getCustomTypeIcon(rowData.ProductTypeId)"
/>
<div
  class="annun-status cursor"
  *ngIf="config.field === 'OperatingStatus'"
  (click)="openRealTimeForAsset(rowData.Id, rowData.ConnectionId, $event)"
>
  <mat-spinner
    [diameter]="20"
    *ngIf="
      rowData.ConnectionId &&
      !getAnnunImage(getAnnunStatusValue(rowData.ConnectionId))
    "
    style="margin-left: 30px"
  ></mat-spinner>
  <img
    *ngIf="websocket.isSocketConnected && rowData.ConnectionId"
    [style.display]="rowData.ExternalId ? 'block' : 'none'"
    [src]="getAnnunImage(getAnnunStatusValue(rowData.ConnectionId))"
    class="shutdown-status-img"
  />
  <span *ngIf="rowData.ConnectionId">{{ getAnnunText(getAnnunStatusValue(rowData.ConnectionId)) }}</span>
</div>
<div
  class="motor-status cursor"
  *ngIf="config.field === 'MotorStatus'"
  (click)="openRealTimeForAsset(rowData.Id, rowData.ConnectionId, $event)"
>
  <mat-spinner
    [diameter]="20"
    *ngIf="
      rowData.ConnectionId &&
      !getMotorStatus(rowData.ConnectionId)
    "
    style="margin-left: 30px"
  ></mat-spinner>
  <img
    *ngIf="websocket.isSocketConnected"
    [style.display]="rowData.ExternalId ? 'block' : 'none'"
    [src]="getMotorStatus(rowData.ConnectionId)"
    class="shutdown-status-img"
  />
  <span>{{ motorStatusText }}</span>
</div>
<div *ngIf="config.field == 'ConditionId'" class="asset-service-condition">
  <img [src]="getConditionImage(rowData.ConditionId)" height="24" width="24" />
</div>
<div *ngIf="config.field == 'ReportLocation'" class="report-location-pdf">
  <img
    *ngIf="rowData.HasReport || rowData.HasStorageReport"
    [src]="pdfImage"
    height="24"
    width="24"
    style="cursor: pointer"
    (click)="openReports(rowData)"
  />
</div>
<div
  *ngIf="config.field == 'VisitNotes' || config.field == 'showNotes'"
  class="visit-notes"
>
  <img
    [src]="infoImage"
    height="24"
    width="24"
    style="cursor: pointer"
    (click)="showNotes(rowData, $event)"
  />
</div>
<div *ngIf="config.field == 'actions'" class="actions">
  <img
    [src]="editImage"
    height="24"
    width="24"
    style="cursor: pointer"
    (click)="edit(rowData)"
  />
  <img
    [src]="deleteImage"
    height="24"
    width="24"
    style="cursor: pointer"
    (click)="delete(rowData)"
  />
</div>
<div
  *ngIf="
    ['C1Id', 'C2Id', 'C3Id', 'C4Id', 'M1Id', 'M2Id'].includes(config.field)
  "
  class="asset-service-conf"
>
  <img
    [src]="getConditionImage(rowData[config.field])"
    height="24"
    width="24"
  />
</div>
