import { Component, NgModule, Input, Output, EventEmitter } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { MaterialModule } from 'src/app/material.module';
import { CommentView } from '../../data/data.generated';
import { ApiService } from '../../services/api.service';
import { ButtonV2Module, IconModule } from '@gea/digital-ui-lib';

@Component({
  selector: 'hrt-comment',
  templateUrl: './hrt-comment.component.html',
  styleUrls: ['./hrt-comment.component.scss'],
})
export class HrtCommentComponent {

  @Input() comments: CommentView[] = [];
  @Input() commentType: string;
  @Output() commentAdded = new EventEmitter<string>();

  public newComment: string;

  constructor(private sanitizer: DomSanitizer, private api: ApiService) {}

  ngOnInit() {
  }

  getUserInitials(username: string) {
    const firstName = username.split(' ')[0];
    const lastName = username.split(' ')[1];

    return firstName.charAt(0) + lastName.charAt(0);
  }

  sanitizeComment(comment: string) {
    if (!comment) return;
    comment = comment.replace(/\n/g, '<br>');
    return this.sanitizer.bypassSecurityTrustHtml(comment);
  }

  addComment() {
    if (this.newComment == '') return;
    this.commentAdded.emit(this.newComment);
    this.newComment = '';
  }

  formatDate(dateStr: string): string {
    if (!dateStr) return '';
    const utcDateString = dateStr.endsWith('Z') ? dateStr : dateStr + 'Z';
    const date = new Date(utcDateString);
    return date.toLocaleString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'    });
  }
}

@NgModule({
  imports: [
    RouterModule,
    MaterialModule,
    FormsModule,
    BrowserModule,
    MatTooltipModule,
    ButtonV2Module,
    IconModule,
  ],
  exports: [HrtCommentComponent],
  declarations: [HrtCommentComponent],
})
export class HrtCommentsModule {}
