import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
    selector: 'asset-notes',
    templateUrl: './asset-notes.component.html',
    styleUrls: ['./asset-notes.component.scss']
})
export class AssetNotesComponent implements OnInit {

    constructor(private api: ApiService) { }

    @Input() public assetId: number = -1;
    @Input() public notes: string = "( ... Updating ... )";

    ngOnInit(): void {
    }

    private filterDelayed: any = undefined;
    private firstUpdate: boolean = true;
    updateNotes() {
        clearTimeout(this.filterDelayed);
        this.filterDelayed = setTimeout(() => {
            if (this.firstUpdate) {
                this.firstUpdate = false;
                return;
            }
            this.api.Assets.UpdateAssetNotes(this.assetId, this.notes)
                .subscribe(x => {
                    console.log(JSON.stringify(x));
                });
        }, 2000);
    }
}
