import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Asset } from '../../data/data.generated';
import { Table, TableView } from '../../services/api/table';
import { GetCustomTypeIcon, GetCustomTypeIconWidth, GetCustomTypeText } from '../../data/product-types';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'asset-finder',
  templateUrl: './asset-finder.component.html',
  styleUrls: ['./asset-finder.component.scss']
})
export class AssetFinderComponent implements OnInit {

    constructor(private readonly api: ApiService) { }

    @Input() public Show: boolean = false;
    @Input() public AssetType: number | undefined = undefined;
    @Output() public SelectedAsset = new EventEmitter<Asset | undefined>();

    public displayedColumns: string[] = ['ReferenceName', 'Manufacturer', 'Model', 'AlternativeModel', 'SerialNumber', 'ProjectNumber'];
    public table: Table<Asset> = new Table<Asset>();
    public getCustomTypeText = GetCustomTypeText;
    public getCustomTypeIcon = GetCustomTypeIcon;
    public getCustomTypeIconWidth = GetCustomTypeIconWidth;

    ngOnInit(): void {
        this.table.View.PageSize = 10;
        this.table.GetApi = (listOptions: TableView) => this.api.Assets.SearchAssets(listOptions, this.AssetType);
        this.table.RefreshData();
    }

    selectAsset(asset: Asset | undefined) {
        this.SelectedAsset.emit(asset);
        this.Show = false;
    }

    visibleChanged(value: boolean) {
        if (!value) {
            this.SelectedAsset.emit(undefined);
            this.Show = false;
        }
    }

}
