import { Component } from '@angular/core';
import {
  GetCustomTypeIcon,
  GetCustomTypeIconWidth,
  GetCustomTypeText,
} from '../../data/product-types';
import { ColumnRendererComponent } from '@gea/digital-ui-lib';
import { WebsocketService } from '../../services/websocket.service';
import { Router } from '@angular/router';
import { GetConditionImage } from '../../data/service-conditions';
import { ImagesService } from '../../services/images.service';
import {
  AssetServiceRecord,
  AssetServiceRecordDashboardView,
} from '../../data/data.generated';
import { ApiService } from '../../services/api.service';
import { HrtTableService } from '../../services/hrt-table.service';

@Component({
  selector: 'app-table-icon',
  templateUrl: './table-icon.component.html',
  styleUrls: ['./table-icon.component.scss'],
})
export class TableIconComponent<T> implements ColumnRendererComponent<T> {
  rowData?: any;
  config?: any;

  // custom variables for functionality.
  public getCustomTypeText = GetCustomTypeText;
  public getCustomTypeIcon = GetCustomTypeIcon;
  public getCustomTypeIconWidth = GetCustomTypeIconWidth;
  public shutdownStatusMapping: any = {
    0: 'Normal',
    1: 'Warning',
    2: 'Shutdown',
  };

  public pdfImage: string = this.images.Icons.FormatPdf;
  public infoImage: string = this.images.Icons.Information;
  public editImage: string = this.images.Icons.Edit;
  public deleteImage: string = this.images.Icons.Delete;

  public notesDialog: boolean = false;
  public notesItem: AssetServiceRecord = new AssetServiceRecord();
  motorStatusText: string;

  constructor(
    private api: ApiService,
    public websocket: WebsocketService,
    private router: Router,
    private images: ImagesService,
    private hrtTable: HrtTableService
  ) {}

  ngOnInit() {}

  getAnnunStatusValue(connectionId: string) {
    if (connectionId) {
      return this.websocket.annunStatuses[connectionId]
        ? this.websocket.annunStatuses[connectionId].annun_status
        : null;
    } else {
      return null;
    }
  }

  getAnnunImage(annun_status: number) {
    if(annun_status != null){
      switch (annun_status) {
        case 0:
          return '../../../assets/icons/Check_Circle.svg';
        case 1:
          return '../../../assets/icons/Warning.svg';
        case 2:
          return '../../../assets/icons/Error.svg';
        default:
          return null;
      } 
    } else {
      return '../../../assets/icons/NotAvailable.png';
    }
    
  }

  getAnnunText(annun_status: number) {
    if(annun_status != null){
      return this.shutdownStatusMapping[annun_status];
    } else {
      return "No Data"
    }
  }

  getMotorStatus(key: string) {
    if (this.websocket.connectedAssets.includes(key)) {
      if(this.websocket.annunStatuses[key]){
        if (this.websocket.annunStatuses[key]?.motor_status) {
          this.motorStatusText = 'Running';
          return '../assets/icons/Check_Circle.svg';
        } else if (!this.websocket.annunStatuses[key]?.motor_status) {
          this.motorStatusText = 'Stopped';
          return '../assets/product-icons/Error.svg';
        }
      } else {
        this.motorStatusText = 'No Data';
        return '../assets/icons/NotAvailable.png';
      }
      
    }
    return null;
  }

  openRealTimeForAsset(id: number, connectionId: string, event: MouseEvent) {
    event.stopPropagation();
    if (this.getAnnunStatusValue(connectionId) != null) {
      this.router.navigate(['/asset-details', { id: id, realTime: true }]);
    } else {
      this.openAsset(id);
    }
  }

  openAsset(id: number) {
    this.router.navigate(['/asset-details', { id: id }]);
  }

  getConditionImage(id: number) {
    return GetConditionImage(id, this.images);
  }

  fixUrl(filename: string) {
    return encodeURIComponent(filename);
  }

  openReports(data: AssetServiceRecordDashboardView) {
    let url =
      'https://hrt-fesportal-hgamdfadh9dyb3cv.westeurope-01.azurewebsites.net/RepairReports/' +
      this.fixUrl(data.ReportLocation);
    if (data.HasReport) {
      window.open(url, '_blank')?.focus();
    }
    if (data.HasStorageReport) {
      this.api.Assets.DownloadAssetServiceRecordReport(data);
    }
    event?.stopPropagation();
  }

  showNotes(item: AssetServiceRecord, event: MouseEvent) {
    event.stopPropagation();
    this.notesItem = item;
    this.notesDialog = true;
  }

  edit(serviceRecord: AssetServiceRecord | undefined = undefined) {
    this.hrtTable.iconEvent.next({ eventKey: 'assetServiceEdit', eventVal: serviceRecord});
  }

  delete(serviceRecord: AssetServiceRecord) {
    this.hrtTable.iconEvent.next({ eventKey: 'assetServiceDelete', eventVal: serviceRecord});
  }
}
