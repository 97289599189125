<img src="../assets/icons/Back.svg" *ngIf="mode != viewMode && mode != editMode" (click)="back()" alt="Back" class="IconButtonRight">
<div *ngIf="mode == viewMode" class="ScrollableMode">
    <table mat-table [dataSource]="table.Result.Data" matSort matSortDisableClear [matSortActive]="table.View.SortColumn" (matSortChange)="table.RefreshData($event)">
        <ng-container matColumnDef="Description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
            <td mat-cell *matCellDef="let row">{{row.Description}}</td>
        </ng-container>
        <ng-container matColumnDef="Quantity">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Quantity</th>
            <td mat-cell *matCellDef="let row">{{row.Quantity}}</td>
        </ng-container>
        <ng-container matColumnDef="ListPrice">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>ListPrice</th>
            <td mat-cell *matCellDef="let row">{{row.ListPrice}}</td>
        </ng-container>
        <ng-container matColumnDef="Options">
            <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
            <td mat-cell *matCellDef="let row">
                <img src="../assets/product-icons/Edit.svg" class="IconButton" (click)="showEdit(row)" />
                <img src="../assets/product-icons/History.svg" class="IconButton" (click)="showHistory(row)" />
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>
<div *ngIf="mode == editMode" class="ScrollableMode">
    <br>
    <span class="CalculatorLabel">Description:</span> <input class="CalculatorLongTextBox" [(ngModel)]="Description"><br>
    <span class="CalculatorLabel">BOM Number:</span> <input class="CalculatorLongTextBox" [(ngModel)]="BomNumber"><button (click)="updateBomCost()">Update Cost</button><br>
    <span class="CalculatorLabel">BOM Cost:</span> <span class="Currency">$ </span><input type="number" disabled="disabled" step=".01" class="CalculatorShortTextBox" [(ngModel)]="BomCost"><br>
    <span class="CalculatorLabel">Materials Cost:</span> <span class="Currency">$ </span><input type="number" step=".01" class="CalculatorShortTextBox" [(ngModel)]="MaterialsCost"><br>
    <span class="CalculatorLabel">Freight Cost:</span> <span class="Currency">$ </span><input type="number" step=".01" class="CalculatorShortTextBox" [(ngModel)]="FreightCost"><br>
    <span class="CalculatorLabel">Miscellaneous Cost:</span> <span class="Currency">$ </span><input type="number" step=".01" class="CalculatorShortTextBox" [(ngModel)]="MiscCost"><br>
    <span class="CalculatorLabel">Warranty Cost %:</span> <input type="number" step=".01" class="CalculatorShortTextBox" [(ngModel)]="WarrantyCostPercent"> %<br>
    <span class="CalculatorLabel">Electrical Drafting Hours:</span> <input type="number" step=".01" class="CalculatorShortTextBox" [(ngModel)]="ElectricalDraftingHours"> x $ {{ElectricalDraftingRate}}<br>
    <span class="CalculatorLabel">Electrical Engineering Hours:</span> <input type="number" step=".01" class="CalculatorShortTextBox" [(ngModel)]="ElectricalEngineeringHours"> x $ {{ElectricalEngineeringRate}}<br>
    <span class="CalculatorLabel">Mechanical Drafting Hours:</span> <input type="number" step=".01" class="CalculatorShortTextBox" [(ngModel)]="MechanicalDraftingHours"> x $ {{MechanicalDraftingRate}}<br>
    <span class="CalculatorLabel">Mechanical Engineering Hours:</span> <input type="number" step=".01" class="CalculatorShortTextBox" [(ngModel)]="MechanicalEngineeringHours"> x $ {{MechanicalEngineeringRate}}<br>
    <span class="CalculatorLabel">Heat Exchanger Drafting Hours:</span> <input type="number" step=".01" class="CalculatorShortTextBox" [(ngModel)]="HeatExchangerDraftingHours"> x $ {{HeatExchangerDraftingRate}}<br>
    <span class="CalculatorLabel">Heat Exchanger Engineering Hours:</span> <input type="number" step=".01" class="CalculatorShortTextBox" [(ngModel)]="HeatExchangerEngineeringHours"> x $ {{HeatExchangerEngineeringRate}}<br>
    <span class="CalculatorLabel">Engineering Support Doc Hours:</span> <input type="number" step=".01" class="CalculatorShortTextBox" [(ngModel)]="EngineeringSupportDocumentation"> x $ {{MechanicalDraftingRate}}<br>
    <span class="CalculatorLabel">Engineering Support Dwg Hours:</span> <input type="number" step=".01" class="CalculatorShortTextBox" [(ngModel)]="EngineeringSupportDrawings"> x $ {{MechanicalDraftingRate}}<br>
    <span class="CalculatorLabel">Shop Labor Hours:</span> <input type="number" step=".01" class="CalculatorShortTextBox" [(ngModel)]="ShopLaborHours"> x $ {{ShopLaborRate}}<br>
    <span class="CalculatorLabel">Total Cost:</span> <span class="Currency">$ </span><input type="number" disabled="disabled" step=".01" class="CalculatorShortTextBox" [ngModel]="GetBasePrice().toFixed(2)"><br>
    <span class="CalculatorLabel">Cost to List Mark-up:</span> <input type="number" step=".01" class="CalculatorShortTextBox" [(ngModel)]="CostToListMarkUp"> x $ {{GetBasePrice().toFixed(2)}} = <b>$ <input class="CalculatorShortTextBox" [value]="GetListPrice().toFixed(2)" (focusout)="listPriceChanged($event)"></b><br>
    <span class="CalculatorLabel">Cost to Transfer Mark-up:</span> <input type="number" step=".01" class="CalculatorShortTextBox" [(ngModel)]="CostToTransferMarkUp"> x $ {{GetBasePrice().toFixed(2)}} = <b>$ <input class="CalculatorShortTextBox" [value]="GetTransferPrice().toFixed(2)" (focusout)="transferPriceChanged($event)"></b><br>

    <button mat-raised-button color="primary" (click)="back()" class="Button">Cancel</button>
    <button mat-raised-button color="primary" (click)="update()" class="Button">Save</button>
</div>
<div *ngIf="mode == historyMode" class="ScrollableMode">
    <table mat-table [dataSource]="historyItems" multiTemplateDataRows class="mat-elevation-z8">
        <ng-container matColumnDef="Id">
            <th mat-header-cell *matHeaderCellDef>Version #</th>
            <td mat-cell *matCellDef="let historyItem">{{historyItem.VersionNumber + (historyItem.VersionActive == true ? " (Active)" : "")}}</td>
        </ng-container>
        <ng-container matColumnDef="ModifiedDate">
            <th mat-header-cell *matHeaderCellDef>Modified Date</th>
            <td mat-cell *matCellDef="let historyItem">{{formatDate(historyItem.VersionDate)}}</td>
        </ng-container>
        <ng-container matColumnDef="UserName">
            <th mat-header-cell *matHeaderCellDef>User Name</th>
            <td mat-cell *matCellDef="let historyItem">{{historyItem.VersionUserName}}</td>
        </ng-container>
        <ng-container matColumnDef="Comments">
            <th mat-header-cell *matHeaderCellDef>Comment</th>
            <td mat-cell *matCellDef="let historyItem">{{historyItem.VersionComment}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let historyItem; columns: columnsToDisplay;" (click)="showHistoryItem(historyItem)" class="TableRowClickable">
        </tr>
    </table>
</div>
<div *ngIf="mode == historyItemMode" class="ScrollableMode">
    <hr>
    <table>
        <tr>
            <td><b>Version #:</b></td>
            <td>{{ historyPrice.VersionNumber + (historyPrice.VersionActive == true ? " (Active)" : "") }}</td>
        </tr>
        <tr>
            <td><b>Modified Date:</b></td>
            <td>{{ formatDate(historyPrice.VersionDate) }}</td>
        </tr>
        <tr>
            <td><b>User Name:</b></td>
            <td>{{ historyPrice.VersionUserName }}</td>
        </tr>
        <tr>
            <td><b>Comment:</b></td>
            <td>{{ historyPrice.VersionComment }}</td>
        </tr>
    </table>
    <hr>
    <div style="margin: 15px;">
        <span class="HistoryLabel">Description:</span> {{ historyPrice.Description }}<br>
        <span class="HistoryLabel">BOM Number:</span> {{ historyPrice.BomNumber }}<br>
        <span class="HistoryLabel">Materials Cost:</span> <span class="Currency">$ </span> {{ historyDerived.Mat ?? "0" }}<br>
        <span class="HistoryLabel">Freight Cost:</span> <span class="Currency">$ </span> {{ historyDerived.Freight ?? "0" }}<br>
        <span class="HistoryLabel">Miscellaneous Cost:</span> <span class="Currency">$ </span> {{ historyDerived.Misc ?? "0" }} <br>
        <span class="HistoryLabel">Warranty Cost %:</span> {{ historyDerived.WP }} %<br>
        <span class="HistoryLabel">Electrical Drafting Hours:</span> {{ historyDerived.ED ?? "0" }} x $ {{ElectricalDraftingRate}}<br>
        <span class="HistoryLabel">Electrical Engineering Hours:</span> {{ historyDerived.EE ?? "0" }} x $ {{ElectricalEngineeringRate}}<br>
        <span class="HistoryLabel">Mechanical Drafting Hours:</span> {{ historyDerived.MD ?? "0" }} x $ {{MechanicalDraftingRate}}<br>
        <span class="HistoryLabel">Mechanical Engineering Hours:</span> {{ historyDerived.ME ?? "0" }} x $ {{MechanicalEngineeringRate}}<br>
        <span class="HistoryLabel">Heat Exchanger Drafting Hours:</span> {{ historyDerived.HXD ?? "0" }} x $ {{HeatExchangerDraftingRate}}<br>
        <span class="HistoryLabel">Heat Exchanger Engineering Hours:</span> {{ historyDerived.HXE ?? "0" }} x $ {{HeatExchangerEngineeringRate}}<br>
        <span class="HistoryLabel">Engineering Support Doc Hours:</span> {{ historyDerived.ESDO ?? "0" }} x $ {{MechanicalDraftingRate}}<br>
        <span class="HistoryLabel">Engineering Support Dwg Hours:</span> {{ historyDerived.ESDG ?? "0" }} x $ {{MechanicalDraftingRate}}<br>
        <span class="HistoryLabel">Shop Labor Hours:</span> {{ historyDerived.Shop ?? "0" }} x $ {{ShopLaborRate}}<br>
        <span class="HistoryLabel">Cost to List Mark-up:</span> {{ historyPrice.ListPrice.toFixed(2) }} x $ {{GetBasePrice().toFixed(2)}} = <b>$ {{GetListPrice().toFixed(2)}}</b><br>
        <span class="HistoryLabel">Cost to Transfer Mark-up:</span> {{ historyPrice.TransferPrice.toFixed(2) }} x $ {{GetBasePrice().toFixed(2)}} = <b>$ {{GetTransferPrice().toFixed(2)}}</b><br>

    </div>
    <hr>
    
    <button mat-raised-button color="primary" (click)="back()" class="Button">Cancel</button>
    <button mat-raised-button color="primary" (click)="revert()" class="Button" *ngIf="historyPrice?.VersionActive == false">Change to this Version</button>
</div>