import { Component, Input, OnInit } from '@angular/core';
import { DerivedPrice } from 'src/app/products/pricing/derived-price';
import { PriceLine } from 'src/app/products/pricing/price-line';
import { ProductPrice } from 'src/app/shared/data/data.generated';
import { ApiService } from 'src/app/shared/services/api.service';
import { Table, TableView } from 'src/app/shared/services/api/table';

@Component({
    selector: 'product-pricing',
    templateUrl: './product-pricing.component.html',
    styleUrls: ['./product-pricing.component.scss']
})
export class ProductPricingComponent implements OnInit {

    @Input() public ProductId: number = -1;
    @Input() public CompanyId: number = 0;

    public mode: number = 0;

    public viewMode: number = 0;
    public editMode: number = 1;
    public historyMode: number = 2;
    public historyItemMode: number = 3;

    public displayedColumns: string[] = ['Description', 'Quantity', 'ListPrice', 'Options'];
    public table: Table<ProductPrice> = new Table<ProductPrice>();

    public historyItems: ProductPrice[] = [];
    public columnsToDisplay: string[] = ['Id', 'ModifiedDate', 'UserName', 'Comments'];
    public editPrice: ProductPrice = new ProductPrice();
    public historyPrice: ProductPrice = new ProductPrice();
    public historyDerived: any = {};
    public overrideListPrice: number | undefined = undefined;
    public overrideTransferPrice: number | undefined = undefined;

    // #region Derived Pricing (York) TODO: Move to separate control
    public ShopLaborRate: number = 99.9;
    public ElectricalDraftingRate: number = 99.9;
    public ElectricalEngineeringRate: number = 99.9;
    public MechanicalDraftingRate: number = 99.9;
    public MechanicalEngineeringRate: number = 99.9;
    public HeatExchangerDraftingRate: number = 99.9;
    public HeatExchangerEngineeringRate: number = 99.9;

    public Description: string = "";
    public BomNumber: string = "";
    public BomCost: number = 0;
    public MaterialsCost: number = 0;
    public FreightCost: number = 0;
    public MiscCost: number = 0;
    public WarrantyCostPercent: number = .999;
    public ElectricalDraftingHours: number = 0;
    public ElectricalEngineeringHours: number = 0;
    public MechanicalDraftingHours: number = 0;
    public MechanicalEngineeringHours: number = 0;
    public HeatExchangerDraftingHours: number = 0;
    public HeatExchangerEngineeringHours: number = 0;
    public EngineeringSupportDocumentation: number = 0;
    public EngineeringSupportDrawings: number = 0;
    public ShopLaborHours: number = 0;
    public CostToListMarkUp: number = 9.99;
    public CostToTransferMarkUp: number = 9.99;

    public DefaultWarrantyCostPercent: number = .999;
    public DefaultCostToListMarkUp: number = 9.99;
    public DefaultCostToTransferMarkUp: number = 9.99;

    public GetBasePriceWithoutWarranty(): number {
        let basePrice: number =
            +this.BomCost +
            +this.MaterialsCost +
            +this.FreightCost +
            +this.MiscCost + 
            (+this.ShopLaborHours * this.ShopLaborRate) +
            (+this.ElectricalDraftingHours * this.ElectricalDraftingRate) +
            (+this.MechanicalDraftingHours * this.MechanicalDraftingRate) +
            (+this.HeatExchangerDraftingHours * this.HeatExchangerDraftingRate) +
            (+this.ElectricalEngineeringHours * this.ElectricalEngineeringRate) +
            (+this.MechanicalEngineeringHours * this.MechanicalEngineeringRate) +
            (+this.HeatExchangerEngineeringHours * this.HeatExchangerEngineeringRate) +
            (+this.EngineeringSupportDocumentation * this.MechanicalDraftingRate) +
            (+this.EngineeringSupportDrawings * this.MechanicalDraftingRate);
        return basePrice;
    }

    public GetWarrantyCost(): number {
        return this.GetBasePriceWithoutWarranty() * (this.WarrantyCostPercent / 100.0);
    }

    public GetBasePrice(): number {
        return this.GetBasePriceWithoutWarranty() + this.GetWarrantyCost();
    }

    public GetListPrice(): number {
        return this.GetBasePrice() * this.CostToListMarkUp;
    }

    public GetTransferPrice(): number {
        return this.GetBasePrice() * this.CostToTransferMarkUp;
    }
    // #endregion

    constructor(private readonly api: ApiService) { }

    ngOnInit(): void {
        this.loadRates();
        this.table.GetApi = (listOptions: TableView) => this.api.Products.GetProductPrices(this.CompanyId, this.ProductId);
        this.loadPrices();
    }

    loadPrices() {
        this.mode = this.viewMode;
        this.table.RefreshData();
    }

    loadRates() {
        this.api.Products.GetHourlyRates(this.CompanyId)
            .subscribe(x => {
                this.ShopLaborRate = <number>x.find(x => x.Name == "ShopLaborRate")?.Rate ?? 99.9;
                this.ElectricalDraftingRate = <number>x.find(x => x.Name == "ElectricalDraftingRate")?.Rate ?? 99.9;
                this.ElectricalEngineeringRate = <number>x.find(x => x.Name == "ElectricalEngineeringRate")?.Rate ?? 99.9;
                this.MechanicalDraftingRate = <number>x.find(x => x.Name == "MechanicalDraftingRate")?.Rate ?? 99.9;
                this.MechanicalEngineeringRate = <number>x.find(x => x.Name == "MechanicalEngineeringRate")?.Rate ?? 99.9;
                this.HeatExchangerDraftingRate = <number>x.find(x => x.Name == "HeatExchangerDraftingRate")?.Rate ?? 99.9;
                this.HeatExchangerEngineeringRate = <number>x.find(x => x.Name == "HeatExchangerEngineeringRate")?.Rate ?? 99.9;
                this.DefaultWarrantyCostPercent = <number>x.find(x => x.Name == "WarrantyCostPercentDefault")?.Rate ?? 99.9;
                this.DefaultCostToListMarkUp = <number>x.find(x => x.Name == "OtherListMultiplierDefault")?.Rate ?? 99.9;
                this.DefaultCostToTransferMarkUp = <number>x.find(x => x.Name == "TransferMultiplierDefault")?.Rate ?? 99.9;
            });
    }

    loadDerivedData(price: ProductPrice) {
        let dervied = JSON.parse(price.DerivedPrice);
        this.Description = price.Description ?? "";
        this.BomCost = dervied.Bom ?? 0;
        this.MaterialsCost = dervied.Mat ?? 0;
        this.FreightCost = dervied.Freight ?? 0;
        this.MiscCost = dervied.Misc ?? 0;
        this.WarrantyCostPercent = dervied.WP ?? this.DefaultWarrantyCostPercent;
        this.ElectricalDraftingHours = dervied.ED ?? 0;
        this.ElectricalEngineeringHours = dervied.EE ?? 0;
        this.MechanicalDraftingHours = dervied.MD ?? 0;
        this.MechanicalEngineeringHours = dervied.ME ?? 0;
        this.HeatExchangerDraftingHours = dervied.HXD ?? 0;
        this.HeatExchangerEngineeringHours = dervied.HXE ?? 0;
        this.EngineeringSupportDocumentation = dervied.ESDO ?? 0;
        this.EngineeringSupportDrawings = dervied.ESDG ?? 0;
        this.ShopLaborHours = dervied.Shop ?? 0;
        this.CostToListMarkUp = dervied.LMU ?? this.DefaultCostToListMarkUp;
        this.CostToTransferMarkUp = dervied.TMU ?? this.DefaultCostToTransferMarkUp;
    }

    back() {
        if (this.mode == this.historyItemMode) {
            this.mode = this.historyMode;
        }
        else {
            this.mode = this.viewMode;
        }
    }

    update() {
        let comment = prompt("Enter comment for this new version:");
        if (comment) {
            var data: any = {};
            if (this.BomCost) data.Bom = this.BomCost;
            if (this.MaterialsCost) data.Mat = this.MaterialsCost;
            if (this.FreightCost) data.Freight = this.FreightCost;
            if (this.MiscCost) data.Misc = this.MiscCost;
            data.WP = this.WarrantyCostPercent;
            if (this.ElectricalDraftingHours) data.ED = this.ElectricalDraftingHours;
            if (this.ElectricalEngineeringHours) data.EE = this.ElectricalEngineeringHours;
            if (this.MechanicalDraftingHours) data.MD = this.MechanicalDraftingHours;
            if (this.MechanicalEngineeringHours) data.ME = this.MechanicalEngineeringHours;
            if (this.HeatExchangerDraftingHours) data.HXD = this.HeatExchangerDraftingHours;
            if (this.HeatExchangerEngineeringHours) data.HXE = this.HeatExchangerEngineeringHours;
            if (this.EngineeringSupportDocumentation) data.ESDO = this.EngineeringSupportDocumentation;
            if (this.EngineeringSupportDrawings) data.ESDG = this.EngineeringSupportDrawings;
            if (this.ShopLaborHours) data.Shop = this.ShopLaborHours;
            data.LMU = this.CostToListMarkUp;
            data.TMU = this.CostToTransferMarkUp;

            var properties = { ...this.editPrice };
            properties.Description = this.Description;
            properties.BomNumber = this.BomNumber;
            properties.TotalCost = this.GetBasePrice();
            properties.ListPrice = this.GetListPrice();
            properties.TransferPrice = this.GetTransferPrice();
            properties.UseHourlyRates = data.ED !== 0 || data.EE !== 0 || data.MD !== 0 || data.ME !== 0 || data.HXD !== 0 || data.HXE !== 0 || data.ESDO !== 0 || data.ESDG !== 0 || data.Ship !== 0;
            properties.Comments = comment;

            this.api.Products.UpdateProductPrice(this.editPrice.Id, data, properties)
                .subscribe(x => { 
                    this.loadPrices();
                });
        }
    }

    showHistory(price: ProductPrice) {
        this.mode = this.historyMode;
        this.api.Products.GetProductPriceHistory(this.CompanyId, this.ProductId, price.Key)
            .subscribe(x => {
                this.historyItems = x;
            });
    }

    revert() {
        this.loadDerivedData(this.historyPrice);
        this.editPrice = this.historyPrice;
        this.update();
    }

    showEdit(price: ProductPrice) {
        this.mode = this.editMode;
        this.editPrice = price;
        this.loadDerivedData(price);
    }

    updateBomCost() {
        this.api.Products.GetBomCost(this.BomNumber)
            .subscribe(x => {
                this.BomCost = x;
            });
    }

    showHistoryItem(price: ProductPrice) {
        this.api.Products.GetProductPriceHistoryItem(price.Id)
            .subscribe(x => {
                this.mode = this.historyItemMode;
                this.historyPrice = x;
                this.historyDerived = JSON.parse(x.DerivedPrice);
            });
    }

    formatDate(dateStr: string) {
        return new Date(dateStr).toLocaleDateString('en-US', { timeZone: 'UTC' });
    }

    listPriceChanged(event: FocusEvent) {
        // @ts-ignore
        let value = parseFloat(event.target.value);
        this.CostToListMarkUp = +((value / this.GetBasePrice()).toFixed(5));
    }

    transferPriceChanged(event: FocusEvent) {
        // @ts-ignore
        let value = parseFloat(event.target.value);
        this.CostToTransferMarkUp = +((value / this.GetBasePrice()).toFixed(5));
    }

}
