import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AssetItemView, Item } from 'src/app/shared/data/data.generated';
import { ApiService } from 'src/app/shared/services/api.service';
import { Table, TableResult, TableView } from 'src/app/shared/services/api/table';
import { HRTColumnDefinition } from 'src/app/shared/data/filterConfiguration';
import { tap } from 'rxjs';
import { FilterTableSettings } from '@gea/digital-ui-lib';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { environment } from 'src/environments';
import { HrtTableService } from 'src/app/shared/services/hrt-table.service';
import { UtilityService } from 'src/app/shared/services/utility.service';

@Component({
    selector: 'asset-component-list',
    templateUrl: './asset-component-list.component.html',
    styleUrls: ['./asset-component-list.component.scss']
})
export class AssetComponentListComponent implements OnInit, OnChanges {
  
    tableId: string = 'assetComponentListComponent';
    totalCount: number = 0;
    columnDefinitions: HRTColumnDefinition[];
    tableData: any[] = [];
    listOptions: TableView = new TableView();
    assetItemViewTable: Table<AssetItemView> = new Table<AssetItemView>();
    public displayedColumns: string[] = [ 'Number', 'Quantity', 'Category', 'Description'];
    public table: Table<AssetItemView> = new Table<AssetItemView>();
    public isEnvironment : string | null = environment.name;
  
    tableDataLoading: boolean = false;
    initialLoad: boolean = true;

    @Input() public assetId: number = -1;
    @Input() public showLineNumber: boolean = false;
  
    mapData: (string | number)[][] = [];
    mapDataLoading: boolean = false;
  
    constructor(
      private api: ApiService,
      private dashboardService: DashboardService,
      private hrtTableService: HrtTableService,
      private utilityService: UtilityService
    ) {}
  
    @Input() data: any;
  
    ngOnInit(): void {
      if (sessionStorage.getItem(this.tableId + '-listOptions')) {
        this.listOptions = JSON.parse(
          sessionStorage.getItem(this.tableId + '-listOptions') || ''
        );
      }

      this.columnDefinitions = [
        { displayName: 'Item', key: 'LineNumber'},
        { displayName: 'Number',key: 'Number', hasFilter: true},
        { displayName: 'Quantity', key: 'Quantity' },
        { displayName: 'Category', key: 'Category', hasFilter: true},
        { displayName: 'Description', key: 'Description'},
    ];
      this.assetItemViewTable.GetApi = (listOptions: TableView) => {
        return this.api.Assets.GetAssetItems(
            this.listOptions,
            this.assetId
        ).pipe(
          tap((assetItemViewTable: TableResult<AssetItemView>) => {
            this.tableData = assetItemViewTable.Data;
          })
        );
      };
  
      this.assetItemViewTable.Updated = () => (this.tableDataLoading = false);
  
      // For initial load, control doesn't go in tap unless subscribed.
      this.assetItemViewTable.refreshDataForTable()?.subscribe();
    }
  
    ngAfterViewInit() {
      this.initialLoad = false;
    }

    ngOnChanges(changes: SimpleChanges) {
      if (changes['assetId'].firstChange) {
          this.table.GetApi = (listOptions: TableView) => this.api.Assets.GetAssetItems(listOptions, this.assetId);
          this.table.RefreshData();
      }
      if (changes['showLineNumber']) {
        if (this.showLineNumber)
          this.displayedColumns = ['LineNumber', ...this.displayedColumns];
      }
    }
  
    handleTableSettings(tableSettings: FilterTableSettings) {
      if (this.initialLoad) return;
  
      const oldOptions = JSON.parse(JSON.stringify(this.listOptions));
  
      const tableColKeys: string[] = Object.keys(tableSettings.columns);
      let sortKey = '';
      let sortDes = 'False';
      if (tableColKeys.length > 0) {
        // sorting exists
        for (let key in tableSettings.columns) {
          let sortValue = tableSettings.columns[key].sort;
          if (sortValue === 'asc' || sortValue === 'desc') {
            sortKey = key;
            sortDes = sortValue === 'desc' ? 'True' : 'False';
            break;
          }
        }
        this.assetItemViewTable.View.SortColumn = sortKey;
        this.assetItemViewTable.View.SortDesc = sortDes;
  
        this.listOptions.SortColumn = sortKey;
        this.listOptions.SortDesc = sortDes;
        
      }
      this.assetItemViewTable.View.Page = tableSettings.page;
      this.listOptions.Page = tableSettings.page;
      this.hrtTableService.saveListOptionsToSS(this.tableId, this.listOptions);
  
      // Object comparision as tableSettings sends multiple events.
      if (this.utilityService.deepEqual(oldOptions, this.listOptions)) return;
      this.assetItemViewTable.SearchUpdated();
    }
  
    updateSearchTerm(searchText: string) {
      if (this.initialLoad) return;
      this.assetItemViewTable.View.Search = searchText;
      this.listOptions.Search = searchText;
      this.hrtTableService.saveListOptionsToSS(this.tableId, this.listOptions);
      this.assetItemViewTable.SearchUpdated();
    }
  
    rowClicked(rowData: any) {
      this.utilityService.openAsset(rowData.Id);
    }
    
    formatQuantity(qty: number) {
      switch (qty) {
        case -1: return "AR*";
        case -2: return "REF*";
        default: return qty;
      }
    }
  
    ngOnDestroy() {
      sessionStorage.setItem(
        this.tableId + '-listOptions',
        JSON.stringify(this.listOptions)
      );
    }
  }