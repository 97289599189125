import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChartOptions } from 'chart.js';
import { LineChartComponent } from 'src/app/shared/components/line-chart/line-chart.component';
import { FieldType } from 'src/app/shared/data/compressor-metadata';
import { ConversionService } from 'src/app/shared/services/conversion.service';
import { WebsocketService } from 'src/app/shared/services/websocket.service';

@Component({
  selector: 'app-chart-container',
  standalone: true,
  imports: [CommonModule, LineChartComponent],
  templateUrl: './chart-container.component.html',
  styleUrl: './chart-container.component.scss',
})
export class ChartContainerComponent {
  timePeriod = '-24h';
  deviceId: string;
  fieldName: string;
  fieldUnit: string;
  fieldType: FieldType;
  assetRef: string;
  isIPSystem: boolean = false;

  chartLiveData: any;
  chartRenderData: any;
  lineChartOptions: ChartOptions;
  // lineChartOptions: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private websocketService: WebsocketService,
    private conversionService: ConversionService
  ) {}

  ngOnInit() {
    if (this.data && this.data['deviceId']) {
      this.deviceId = this.data['deviceId'];
      this.fieldName = this.data['fieldName'];
      this.fieldUnit = this.data['fieldUnit'];
      this.fieldType = this.data['fieldType'];
      this.assetRef = this.data['assetReferenceName'];
      this.isIPSystem = this.data['isIPSystem'];

      const socketRequest = JSON.stringify({
        isChartData: true,
        field: this.data['field'],
        timePeriod: this.timePeriod,
        deviceId: this.deviceId,
      });
      this.websocketService.send(socketRequest);
    }

    this.websocketService.chartData.subscribe((chartStreamData: any) => {
      if (chartStreamData && chartStreamData[this.deviceId]) {
        this.chartLiveData = chartStreamData[this.deviceId];
        // this.chartData = chartStreamData[this.deviceId];
        this.defineChartOptions();
        const convertedTimeLabels = this.convertTimeLabels(
          this.chartLiveData.timestamps
        );
        const chartObj = {
          labels: convertedTimeLabels,
          datasets: [
            {
              label: `${this.fieldName}: ${this.deviceId}`,
              data: this.modifyChartData(this.chartLiveData.datapoints),
              fill: false,
              borderColor: '#000F41',
            },
          ],
        };
        this.chartRenderData = chartObj;
        // console.log('Chart info:', this.chartRenderData);
      }
    });
  }

  modifyChartData(chartDataPoints: number[]) {
    if (chartDataPoints && chartDataPoints.length > 0) {
      if (this.isIPSystem) {
        if (this.fieldType == FieldType.Temperature) {
          const fromUnit = 'C';
          const toUnit = 'F';
          return chartDataPoints.map((data) =>
            this.conversionService.convertTemperature(data, fromUnit, toUnit)
          );
        }
        if (this.fieldType == FieldType.Pressure) {
          const fromUnit = 'barA';
          const toUnit = 'Psia';
          return chartDataPoints.map((data) =>
            this.conversionService.convertPressure(data, fromUnit, toUnit)
          );
        }
      }
      return chartDataPoints;
    }
    return chartDataPoints;
  }

  defineChartOptions() {
    this.lineChartOptions = {
      plugins: {
        tooltip: {
          callbacks: {
            label: (context) => {
              const time = new Date(
                this.chartLiveData.timestamps[context.dataIndex]
              ).toLocaleString('en-US');
              const value = context.formattedValue;
              return `${time} \n ${this.fieldName}: ${value} ${this.fieldUnit}`;
            },
          },
        },
      },
      scales: {
        x: {
          // type: 'time',
          // time: {
          //   unit: 'hour'
          // },
          ticks: {
            maxTicksLimit: 24,
          },
        },
        y: {
          ticks: {
              // Include a dollar sign in the ticks
              callback: (value, index, ticks) => {
                  return `${value} ${this.fieldUnit}`;
              }
          }
      }
      },
    };
  }

  convertTimeLabels(timestamps: string[]) {
    let convertedTimestamps = timestamps.map((time) =>
      new Date(time).toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      })
    );
    return convertedTimestamps;
  }

  ngOnDestroy() {
    this.websocketService.chartData.next(undefined);
  }
}
