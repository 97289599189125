import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  defaultMessage = 'Fetching data...';
  isLoading: boolean = false;
  isLoaderVisible = new BehaviorSubject<boolean>(false);
  loadingMessage = new BehaviorSubject<string>('Loading Application...');

  constructor() { }

  showLoadingIcon(message?: string) {
    if (message) {
      this.loadingMessage.next(message);
    }
    this.isLoading = true;
    this.isLoaderVisible.next(true);
  }

  hideLoadingIcon() {
    this.resetLoadingMessage();
    this.isLoading = false;
    this.isLoaderVisible.next(false);
  }

  resetLoadingMessage() {
    this.loadingMessage.next(this.defaultMessage);
  }

}
