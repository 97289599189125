<div class="documents-view">
  <hrt-tab [hasTabs]="false" [title]="'Documents'"></hrt-tab>
  <table class="TableHeader">
    <tr>
      <td>
        <mat-form-field  class="TableHeaderSearch">
          <mat-label style="color: var(--geaui-vibrant-blue);">Search Documents</mat-label>
          <input type="text" matInput [(ngModel)]="table.View.Search" (ngModelChange)="searchChange($event)">
          <mat-icon style="color: var(--geaui-vibrant-blue);" matSuffix>search</mat-icon>
        </mat-form-field>
      </td>
    </tr>
  </table>
  <table mat-table [dataSource]="table.Result.Data" 
          matSort matSortDisableClear
          [matSortActive]="table.View.SortColumn" 
          (matSortChange)="table.RefreshData($event)">
    <ng-container matColumnDef="Icon">
      <th mat-header-cell *matHeaderCellDef class="TableIconColumn"></th>
      <td mat-cell *matCellDef="let row" (click)="openPopUp(row)">
        <mat-icon matSuffix>build</mat-icon>
      </td>
    </ng-container>
    <ng-container matColumnDef="Title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
      <td mat-cell *matCellDef="let row" (click)="openPopUp(row)">{{row.Title}}</td>
    </ng-container>
    <ng-container matColumnDef="Company">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Company</th>
      <td mat-cell *matCellDef="let row" (click)="openPopUp(row)">{{getCompanyName(row.CompanyId)}}</td>
    </ng-container>
    <ng-container matColumnDef="Language">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Language</th>
      <td mat-cell *matCellDef="let row" (click)="openPopUp(row)">{{row.Language}}</td>
    </ng-container>
    <ng-container matColumnDef="Type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
      <td mat-cell *matCellDef="let row" (click)="openPopUp(row)">{{row.FileType}}</td>
    </ng-container>
    <ng-container matColumnDef="Size">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Size</th>
      <td mat-cell *matCellDef="let row" (click)="openPopUp(row)">{{row.Size}} MB</td>
    </ng-container>
    <ng-container matColumnDef="Download">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
      <td  mat-cell *matCellDef="let row">
        <mat-icon style="padding: 5px; padding-top: 10px; ;height: 40px; scale: 1.3;" (click)="downloadDocument(row)">download</mat-icon> 
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        class="TableRowClickable">
    </tr>
  </table>
  <mat-paginator 
    [length]="table.Result.Count" 
    [pageSizeOptions]="table.View.PageSizeOptions" 
    [pageSize]="table.View.PageSize" 
    [pageIndex]="table.View.Page" 
    (page)="table.RefreshData($event)">
  </mat-paginator>
</div>

<progress-spinner 
    class="example-margin" 
    color="primary" 
    mode="indeterminate" 
    [value]="50" 
    [backdropEnabled]="true" 
    [positionGloballyCenter]="true" 
    [displayProgressSpinner]="downloading">
</progress-spinner>