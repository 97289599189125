import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RegionDataService {

    constructor() { }
    
    public Countries = [
        { Name: 'United States', Code2: 'US', Code3: 'USA' },
        { Name: 'Afghanistan', Code2: 'AF', Code3: 'AFG' },
        { Name: 'Albania', Code2: 'AL', Code3: 'ALB' },
        { Name: 'Algeria', Code2: 'DZ', Code3: 'DZA' },
        { Name: 'American Samoa', Code2: 'AS', Code3: 'ASM' },
        { Name: 'Andorra', Code2: 'AD', Code3: 'AND' },
        { Name: 'Angola', Code2: 'AO', Code3: 'AGO' },
        { Name: 'Anguilla', Code2: 'AI', Code3: 'AIA' },
        { Name: 'Antarctica', Code2: 'AQ', Code3: 'ATA' },
        { Name: 'Antigua and Barbuda', Code2: 'AG', Code3: 'ATG' },
        { Name: 'Argentina', Code2: 'AR', Code3: 'ARG' },
        { Name: 'Armenia', Code2: 'AM', Code3: 'ARM' },
        { Name: 'Aruba', Code2: 'AW', Code3: 'ABW' },
        { Name: 'Australia', Code2: 'AU', Code3: 'AUS' },
        { Name: 'Austria', Code2: 'AT', Code3: 'AUT' },
        { Name: 'Azerbaijan', Code2: 'AZ', Code3: 'AZE' },
        { Name: 'Bahamas (the)', Code2: 'BS', Code3: 'BHS' },
        { Name: 'Bahrain', Code2: 'BH', Code3: 'BHR' },
        { Name: 'Bangladesh', Code2: 'BD', Code3: 'BGD' },
        { Name: 'Barbados', Code2: 'BB', Code3: 'BRB' },
        { Name: 'Belarus', Code2: 'BY', Code3: 'BLR' },
        { Name: 'Belgium', Code2: 'BE', Code3: 'BEL' },
        { Name: 'Belize', Code2: 'BZ', Code3: 'BLZ' },
        { Name: 'Benin', Code2: 'BJ', Code3: 'BEN' },
        { Name: 'Bermuda', Code2: 'BM', Code3: 'BMU' },
        { Name: 'Bhutan', Code2: 'BT', Code3: 'BTN' },
        { Name: 'Bolivia (Plurinational State of)', Code2: 'BO', Code3: 'BOL' },
        { Name: 'Bonaire, Sint Eustatius and Saba', Code2: 'BQ', Code3: 'BES' },
        { Name: 'Bosnia and Herzegovina', Code2: 'BA', Code3: 'BIH' },
        { Name: 'Botswana', Code2: 'BW', Code3: 'BWA' },
        { Name: 'Bouvet Island', Code2: 'BV', Code3: 'BVT' },
        { Name: 'Brazil', Code2: 'BR', Code3: 'BRA' },
        { Name: 'British Indian Ocean Territory (the)', Code2: 'IO', Code3: 'IOT' },
        { Name: 'Brunei Darussalam', Code2: 'BN', Code3: 'BRN' },
        { Name: 'Bulgaria', Code2: 'BG', Code3: 'BGR' },
        { Name: 'Burkina Faso', Code2: 'BF', Code3: 'BFA' },
        { Name: 'Burundi', Code2: 'BI', Code3: 'BDI' },
        { Name: 'Cabo Verde', Code2: 'CV', Code3: 'CPV' },
        { Name: 'Cambodia', Code2: 'KH', Code3: 'KHM' },
        { Name: 'Cameroon', Code2: 'CM', Code3: 'CMR' },
        { Name: 'Canada', Code2: 'CA', Code3: 'CAN' },
        { Name: 'Cayman Islands (the)', Code2: 'KY', Code3: 'CYM' },
        { Name: 'Central African Republic (the)', Code2: 'CF', Code3: 'CAF' },
        { Name: 'Chad', Code2: 'TD', Code3: 'TCD' },
        { Name: 'Chile', Code2: 'CL', Code3: 'CHL' },
        { Name: 'China', Code2: 'CN', Code3: 'CHN' },
        { Name: 'Christmas Island', Code2: 'CX', Code3: 'CXR' },
        { Name: 'Cocos (Keeling) Islands (the)', Code2: 'CC', Code3: 'CCK' },
        { Name: 'Colombia', Code2: 'CO', Code3: 'COL' },
        { Name: 'Comoros (the)', Code2: 'KM', Code3: 'COM' },
        { Name: 'Congo (the Democratic Republic of the)', Code2: 'CD', Code3: 'COD' },
        { Name: 'Congo (the)', Code2: 'CG', Code3: 'COG' },
        { Name: 'Cook Islands (the)', Code2: 'CK', Code3: 'COK' },
        { Name: 'Costa Rica', Code2: 'CR', Code3: 'CRI' },
        { Name: 'Croatia', Code2: 'HR', Code3: 'HRV' },
        { Name: 'Cuba', Code2: 'CU', Code3: 'CUB' },
        { Name: 'Curaçao', Code2: 'CW', Code3: 'CUW' },
        { Name: 'Cyprus', Code2: 'CY', Code3: 'CYP' },
        { Name: 'Czechia', Code2: 'CZ', Code3: 'CZE' },
        { Name: 'Côte d\'Ivoire', Code2: 'CI', Code3: 'CIV' },
        { Name: 'Denmark', Code2: 'DK', Code3: 'DNK' },
        { Name: 'Djibouti', Code2: 'DJ', Code3: 'DJI' },
        { Name: 'Dominica', Code2: 'DM', Code3: 'DMA' },
        { Name: 'Dominican Republic (the)', Code2: 'DO', Code3: 'DOM' },
        { Name: 'Ecuador', Code2: 'EC', Code3: 'ECU' },
        { Name: 'Egypt', Code2: 'EG', Code3: 'EGY' },
        { Name: 'El Salvador', Code2: 'SV', Code3: 'SLV' },
        { Name: 'Equatorial Guinea', Code2: 'GQ', Code3: 'GNQ' },
        { Name: 'Eritrea', Code2: 'ER', Code3: 'ERI' },
        { Name: 'Estonia', Code2: 'EE', Code3: 'EST' },
        { Name: 'Eswatini', Code2: 'SZ', Code3: 'SWZ' },
        { Name: 'Ethiopia', Code2: 'ET', Code3: 'ETH' },
        { Name: 'Falkland Islands (the) [Malvinas]', Code2: 'FK', Code3: 'FLK' },
        { Name: 'Faroe Islands (the)', Code2: 'FO', Code3: 'FRO' },
        { Name: 'Fiji', Code2: 'FJ', Code3: 'FJI' },
        { Name: 'Finland', Code2: 'FI', Code3: 'FIN' },
        { Name: 'France', Code2: 'FR', Code3: 'FRA' },
        { Name: 'French Guiana', Code2: 'GF', Code3: 'GUF' },
        { Name: 'French Polynesia', Code2: 'PF', Code3: 'PYF' },
        { Name: 'French Southern Territories (the)', Code2: 'TF', Code3: 'ATF' },
        { Name: 'Gabon', Code2: 'GA', Code3: 'GAB' },
        { Name: 'Gambia (the)', Code2: 'GM', Code3: 'GMB' },
        { Name: 'Georgia', Code2: 'GE', Code3: 'GEO' },
        { Name: 'Germany', Code2: 'DE', Code3: 'DEU' },
        { Name: 'Ghana', Code2: 'GH', Code3: 'GHA' },
        { Name: 'Gibraltar', Code2: 'GI', Code3: 'GIB' },
        { Name: 'Greece', Code2: 'GR', Code3: 'GRC' },
        { Name: 'Greenland', Code2: 'GL', Code3: 'GRL' },
        { Name: 'Grenada', Code2: 'GD', Code3: 'GRD' },
        { Name: 'Guadeloupe', Code2: 'GP', Code3: 'GLP' },
        { Name: 'Guam', Code2: 'GU', Code3: 'GUM' },
        { Name: 'Guatemala', Code2: 'GT', Code3: 'GTM' },
        { Name: 'Guernsey', Code2: 'GG', Code3: 'GGY' },
        { Name: 'Guinea', Code2: 'GN', Code3: 'GIN' },
        { Name: 'Guinea-Bissau', Code2: 'GW', Code3: 'GNB' },
        { Name: 'Guyana', Code2: 'GY', Code3: 'GUY' },
        { Name: 'Haiti', Code2: 'HT', Code3: 'HTI' },
        { Name: 'Heard Island and McDonald Islands', Code2: 'HM', Code3: 'HMD' },
        { Name: 'Holy See (the)', Code2: 'VA', Code3: 'VAT' },
        { Name: 'Honduras', Code2: 'HN', Code3: 'HND' },
        { Name: 'Hong Kong', Code2: 'HK', Code3: 'HKG' },
        { Name: 'Hungary', Code2: 'HU', Code3: 'HUN' },
        { Name: 'Iceland', Code2: 'IS', Code3: 'ISL' },
        { Name: 'India', Code2: 'IN', Code3: 'IND' },
        { Name: 'Indonesia', Code2: 'ID', Code3: 'IDN' },
        { Name: 'Iran (Islamic Republic of)', Code2: 'IR', Code3: 'IRN' },
        { Name: 'Iraq', Code2: 'IQ', Code3: 'IRQ' },
        { Name: 'Ireland', Code2: 'IE', Code3: 'IRL' },
        { Name: 'Isle of Man', Code2: 'IM', Code3: 'IMN' },
        { Name: 'Israel', Code2: 'IL', Code3: 'ISR' },
        { Name: 'Italy', Code2: 'IT', Code3: 'ITA' },
        { Name: 'Jamaica', Code2: 'JM', Code3: 'JAM' },
        { Name: 'Japan', Code2: 'JP', Code3: 'JPN' },
        { Name: 'Jersey', Code2: 'JE', Code3: 'JEY' },
        { Name: 'Jordan', Code2: 'JO', Code3: 'JOR' },
        { Name: 'Kazakhstan', Code2: 'KZ', Code3: 'KAZ' },
        { Name: 'Kenya', Code2: 'KE', Code3: 'KEN' },
        { Name: 'Kiribati', Code2: 'KI', Code3: 'KIR' },
        { Name: 'Korea (the Democratic People\'s Republic of) ', Code2: 'KP', Code3: 'PRK' },
        { Name: 'Korea (the Republic of)', Code2: 'KR', Code3: 'KOR' },
        { Name: 'Kuwait', Code2: 'KW', Code3: 'KWT' },
        { Name: 'Kyrgyzstan', Code2: 'KG', Code3: 'KGZ' },
        { Name: 'Lao People\'s Democratic Republic(the)', Code2: 'LA', Code3: 'LAO' },
        { Name: 'Latvia', Code2: 'LV', Code3: 'LVA' },
        { Name: 'Lebanon', Code2: 'LB', Code3: 'LBN' },
        { Name: 'Lesotho', Code2: 'LS', Code3: 'LSO' },
        { Name: 'Liberia', Code2: 'LR', Code3: 'LBR' },
        { Name: 'Libya', Code2: 'LY', Code3: 'LBY' },
        { Name: 'Liechtenstein', Code2: 'LI', Code3: 'LIE' },
        { Name: 'Lithuania', Code2: 'LT', Code3: 'LTU' },
        { Name: 'Luxembourg', Code2: 'LU', Code3: 'LUX' },
        { Name: 'Macao', Code2: 'MO', Code3: 'MAC' },
        { Name: 'Madagascar', Code2: 'MG', Code3: 'MDG' },
        { Name: 'Malawi', Code2: 'MW', Code3: 'MWI' },
        { Name: 'Malaysia', Code2: 'MY', Code3: 'MYS' },
        { Name: 'Maldives', Code2: 'MV', Code3: 'MDV' },
        { Name: 'Mali', Code2: 'ML', Code3: 'MLI' },
        { Name: 'Malta', Code2: 'MT', Code3: 'MLT' },
        { Name: 'Marshall Islands (the)', Code2: 'MH', Code3: 'MHL' },
        { Name: 'Martinique', Code2: 'MQ', Code3: 'MTQ' },
        { Name: 'Mauritania', Code2: 'MR', Code3: 'MRT' },
        { Name: 'Mauritius', Code2: 'MU', Code3: 'MUS' },
        { Name: 'Mayotte', Code2: 'YT', Code3: 'MYT' },
        { Name: 'Mexico', Code2: 'MX', Code3: 'MEX' },
        { Name: 'Micronesia (Federated States of)', Code2: 'FM', Code3: 'FSM' },
        { Name: 'Moldova (the Republic of)', Code2: 'MD', Code3: 'MDA' },
        { Name: 'Monaco', Code2: 'MC', Code3: 'MCO' },
        { Name: 'Mongolia', Code2: 'MN', Code3: 'MNG' },
        { Name: 'Montenegro', Code2: 'ME', Code3: 'MNE' },
        { Name: 'Montserrat', Code2: 'MS', Code3: 'MSR' },
        { Name: 'Morocco', Code2: 'MA', Code3: 'MAR' },
        { Name: 'Mozambique', Code2: 'MZ', Code3: 'MOZ' },
        { Name: 'Myanmar', Code2: 'MM', Code3: 'MMR' },
        { Name: 'Namibia', Code2: 'NA', Code3: 'NAM' },
        { Name: 'Nauru', Code2: 'NR', Code3: 'NRU' },
        { Name: 'Nepal', Code2: 'NP', Code3: 'NPL' },
        { Name: 'Netherlands', Code2: 'NL', Code3: 'NLD' },
        { Name: 'New Caledonia', Code2: 'NC', Code3: 'NCL' },
        { Name: 'New Zealand', Code2: 'NZ', Code3: 'NZL' },
        { Name: 'Nicaragua', Code2: 'NI', Code3: 'NIC' },
        { Name: 'Niger (the)', Code2: 'NE', Code3: 'NER' },
        { Name: 'Nigeria', Code2: 'NG', Code3: 'NGA' },
        { Name: 'Niue', Code2: 'NU', Code3: 'NIU' },
        { Name: 'Norfolk Island', Code2: 'NF', Code3: 'NFK' },
        { Name: 'Northern Mariana Islands (the)', Code2: 'MP', Code3: 'MNP' },
        { Name: 'Norway', Code2: 'NO', Code3: 'NOR' },
        { Name: 'Oman', Code2: 'OM', Code3: 'OMN' },
        { Name: 'Pakistan', Code2: 'PK', Code3: 'PAK' },
        { Name: 'Palau', Code2: 'PW', Code3: 'PLW' },
        { Name: 'Palestine, State of', Code2: 'PS', Code3: 'PSE' },
        { Name: 'Panama', Code2: 'PA', Code3: 'PAN' },
        { Name: 'Papua New Guinea', Code2: 'PG', Code3: 'PNG' },
        { Name: 'Paraguay', Code2: 'PY', Code3: 'PRY' },
        { Name: 'Peru', Code2: 'PE', Code3: 'PER' },
        { Name: 'Philippines (the)', Code2: 'PH', Code3: 'PHL' },
        { Name: 'Pitcairn', Code2: 'PN', Code3: 'PCN' },
        { Name: 'Poland', Code2: 'PL', Code3: 'POL' },
        { Name: 'Portugal', Code2: 'PT', Code3: 'PRT' },
        { Name: 'Puerto Rico', Code2: 'PR', Code3: 'PRI' },
        { Name: 'Qatar', Code2: 'QA', Code3: 'QAT' },
        { Name: 'Republic of North Macedonia', Code2: 'MK', Code3: 'MKD' },
        { Name: 'Romania', Code2: 'RO', Code3: 'ROU' },
        { Name: 'Russian Federation (the)', Code2: 'RU', Code3: 'RUS' },
        { Name: 'Rwanda', Code2: 'RW', Code3: 'RWA' },
        { Name: 'Réunion', Code2: 'RE', Code3: 'REU' },
        { Name: 'Saint Barthélemy', Code2: 'BL', Code3: 'BLM' },
        { Name: 'Saint Helena, Ascension and Tristan da Cunha', Code2: 'SH', Code3: 'SHN' },
        { Name: 'Saint Kitts and Nevis', Code2: 'KN', Code3: 'KNA' },
        { Name: 'Saint Lucia', Code2: 'LC', Code3: 'LCA' },
        { Name: 'Saint Martin (French part)', Code2: 'MF', Code3: 'MAF' },
        { Name: 'Saint Pierre and Miquelon', Code2: 'PM', Code3: 'SPM' },
        { Name: 'Saint Vincent and the Grenadines', Code2: 'VC', Code3: 'VCT' },
        { Name: 'Samoa', Code2: 'WS', Code3: 'WSM' },
        { Name: 'San Marino', Code2: 'SM', Code3: 'SMR' },
        { Name: 'Sao Tome and Principe', Code2: 'ST', Code3: 'STP' },
        { Name: 'Saudi Arabia', Code2: 'SA', Code3: 'SAU' },
        { Name: 'Senegal', Code2: 'SN', Code3: 'SEN' },
        { Name: 'Serbia', Code2: 'RS', Code3: 'SRB' },
        { Name: 'Seychelles', Code2: 'SC', Code3: 'SYC' },
        { Name: 'Sierra Leone', Code2: 'SL', Code3: 'SLE' },
        { Name: 'Singapore', Code2: 'SG', Code3: 'SGP' },
        { Name: 'Sint Maarten (Dutch part)', Code2: 'SX', Code3: 'SXM' },
        { Name: 'Slovakia', Code2: 'SK', Code3: 'SVK' },
        { Name: 'Slovenia', Code2: 'SI', Code3: 'SVN' },
        { Name: 'Solomon Islands', Code2: 'SB', Code3: 'SLB' },
        { Name: 'Somalia', Code2: 'SO', Code3: 'SOM' },
        { Name: 'South Africa', Code2: 'ZA', Code3: 'ZAF' },
        { Name: 'South Georgia and the South Sandwich Islands', Code2: 'GS', Code3: 'SGS' },
        { Name: 'South Sudan', Code2: 'SS', Code3: 'SSD' },
        { Name: 'Spain', Code2: 'ES', Code3: 'ESP' },
        { Name: 'Sri Lanka', Code2: 'LK', Code3: 'LKA' },
        { Name: 'Sudan (the)', Code2: 'SD', Code3: 'SDN' },
        { Name: 'Suriname', Code2: 'SR', Code3: 'SUR' },
        { Name: 'Svalbard and Jan Mayen', Code2: 'SJ', Code3: 'SJM' },
        { Name: 'Sweden', Code2: 'SE', Code3: 'SWE' },
        { Name: 'Switzerland', Code2: 'CH', Code3: 'CHE' },
        { Name: 'Syrian Arab Republic', Code2: 'SY', Code3: 'SYR' },
        { Name: 'Taiwan (Province of China)', Code2: 'TW', Code3: 'TWN' },
        { Name: 'Tajikistan', Code2: 'TJ', Code3: 'TJK' },
        { Name: 'Tanzania, United Republic of', Code2: 'TZ', Code3: 'TZA' },
        { Name: 'Thailand', Code2: 'TH', Code3: 'THA' },
        { Name: 'Timor-Leste', Code2: 'TL', Code3: 'TLS' },
        { Name: 'Togo', Code2: 'TG', Code3: 'TGO' },
        { Name: 'Tokelau', Code2: 'TK', Code3: 'TKL' },
        { Name: 'Tonga', Code2: 'TO', Code3: 'TON' },
        { Name: 'Trinidad and Tobago', Code2: 'TT', Code3: 'TTO' },
        { Name: 'Tunisia', Code2: 'TN', Code3: 'TUN' },
        { Name: 'Turkey', Code2: 'TR', Code3: 'TUR' },
        { Name: 'Turkmenistan', Code2: 'TM', Code3: 'TKM' },
        { Name: 'Turks and Caicos Islands (the)', Code2: 'TC', Code3: 'TCA' },
        { Name: 'Tuvalu', Code2: 'TV', Code3: 'TUV' },
        { Name: 'Uganda', Code2: 'UG', Code3: 'UGA' },
        { Name: 'Ukraine', Code2: 'UA', Code3: 'UKR' },
        { Name: 'United Arab Emirates (the)', Code2: 'AE', Code3: 'ARE' },
        { Name: 'United Kingdom of Great Britain and Northern Ireland (the)', Code2: 'GB', Code3: 'GBR' },
        { Name: 'United States Minor Outlying Islands (the)', Code2: 'UM', Code3: 'UMI' },
        { Name: 'Uruguay', Code2: 'UY', Code3: 'URY' },
        { Name: 'Uzbekistan', Code2: 'UZ', Code3: 'UZB' },
        { Name: 'Vanuatu', Code2: 'VU', Code3: 'VUT' },
        { Name: 'Venezuela (Bolivarian Republic of)', Code2: 'VE', Code3: 'VEN' },
        { Name: 'Viet Nam', Code2: 'VN', Code3: 'VNM' },
        { Name: 'Virgin Islands (British)', Code2: 'VG', Code3: 'VGB' },
        { Name: 'Virgin Islands (U.S.)', Code2: 'VI', Code3: 'VIR' },
        { Name: 'Wallis and Futuna', Code2: 'WF', Code3: 'WLF' },
        { Name: 'Western Sahara', Code2: 'EH', Code3: 'ESH' },
        { Name: 'Yemen', Code2: 'YE', Code3: 'YEM' },
        { Name: 'Zambia', Code2: 'ZM', Code3: 'ZMB' },
        { Name: 'Zimbabwe', Code2: 'ZW', Code3: 'ZWE' },
        { Name: 'Åland Islands', Code2: 'AX', Code3: 'ALA' }
    ];

    public States = [
        {
            Name: "Alabama",
            Code: "AL"
        },
        {
            Name: "Alaska",
            Code: "AK"
        },
        {
            Name: "Arizona",
            Code: "AZ"
        },
        {
            Name: "Arkansas",
            Code: "AR"
        },
        {
            Name: "California",
            Code: "CA"
        },
        {
            Name: "Colorado",
            Code: "CO"
        },
        {
            Name: "Connecticut",
            Code: "CT"
        },
        {
            Name: "Delaware",
            Code: "DE"
        },
        {
            Name: "Florida",
            Code: "FL"
        },
        {
            Name: "Georgia",
            Code: "GA"
        },
        {
            Name: "Hawaii",
            Code: "HI"
        },
        {
            Name: "Idaho",
            Code: "ID"
        },
        {
            Name: "Illinois",
            Code: "IL"
        },
        {
            Name: "Indiana",
            Code: "IN"
        },
        {
            Name: "Iowa",
            Code: "IA"
        },
        {
            Name: "Kansas",
            Code: "KS"
        },
        {
            Name: "Kentucky",
            Code: "KY"
        },
        {
            Name: "Louisiana",
            Code: "LA"
        },
        {
            Name: "Maine",
            Code: "ME"
        },
        {
            Name: "Maryland",
            Code: "MD"
        },
        {
            Name: "Massachusetts",
            Code: "MA"
        },
        {
            Name: "Michigan",
            Code: "MI"
        },
        {
            Name: "Minnesota",
            Code: "MN"
        },
        {
            Name: "Mississippi",
            Code: "MS"
        },
        {
            Name: "Missouri",
            Code: "MO"
        },
        {
            Name: "Montana",
            Code: "MT"
        },
        {
            Name: "Nebraska",
            Code: "NE"
        },
        {
            Name: "Nevada",
            Code: "NV"
        },
        {
            Name: "New Hampshire",
            Code: "NH"
        },
        {
            Name: "New Jersey",
            Code: "NJ"
        },
        {
            Name: "New Mexico",
            Code: "NM"
        },
        {
            Name: "New York",
            Code: "NY"
        },
        {
            Name: "North Carolina",
            Code: "NC"
        },
        {
            Name: "North Dakota",
            Code: "ND"
        },
        {
            Name: "Ohio",
            Code: "OH"
        },
        {
            Name: "Oklahoma",
            Code: "OK"
        },
        {
            Name: "Oregon",
            Code: "OR"
        },
        {
            Name: "Pennsylvania",
            Code: "PA"
        },
        {
            Name: "Rhode Island",
            Code: "RI"
        },
        {
            Name: "South Carolina",
            Code: "SC"
        },
        {
            Name: "South Dakota",
            Code: "SD"
        },
        {
            Name: "Tennessee",
            Code: "TN"
        },
        {
            Name: "Texas",
            Code: "TX"
        },
        {
            Name: "Utah",
            Code: "UT"
        },
        {
            Name: "Vermont",
            Code: "VT"
        },
        {
            Name: "Virginia",
            Code: "VA"
        },
        {
            Name: "Washington",
            Code: "WA"
        },
        {
            Name: "West Virginia",
            Code: "WV"
        },
        {
            Name: "Wisconsin",
            Code: "WI"
        },
        {
            Name: "Wyoming",
            Code: "WY"
        }
    ];
}
