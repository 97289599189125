<table mat-table [dataSource]="serviceTable.Result.Data" matSort matSortDisableClear [matSortActive]="serviceTable.View.SortColumn" (matSortChange)="serviceTable.RefreshData($event)">
    <ng-container matColumnDef="Icon">
        <th mat-header-cell *matHeaderCellDef class="TableLargeIconColumn"></th>
        <td mat-cell *matCellDef="let row" (click)="openAsset(row.AssetId)">
            <img [src]="getCustomTypeIcon(row.ProductTypeId)" [style.max-height.px]="32" [style.max-width.px]="getCustomTypeIconWidth(row.ProductTypeId)" />
        </td>
    </ng-container>
    <ng-container matColumnDef="Location">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Location</th>
        <td mat-cell *matCellDef="let row" (click)="openAsset(row.AssetId)">{{row.Location}}</td>
    </ng-container>
    <ng-container matColumnDef="Manufacturer">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Manufacturer</th>
        <td mat-cell *matCellDef="let row">{{row.Manufacturer}}</td>
    </ng-container>
    <ng-container matColumnDef="Model">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Model</th>
        <td mat-cell *matCellDef="let row" (click)="openAsset(row.AssetId)">{{row.Model}}</td>
    </ng-container>
    <ng-container matColumnDef="AlternativeModel">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Alt Model</th>
        <td mat-cell *matCellDef="let row" (click)="openAsset(row.AssetId)">{{row.AlternativeModel}}</td>
    </ng-container>
    <ng-container matColumnDef="Date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
        <td mat-cell *matCellDef="let row" (click)="openAsset(row.AssetId)">{{ formatDate(row.Date) }}</td>
    </ng-container>
    <ng-container matColumnDef="Type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
        <td mat-cell *matCellDef="let row" (click)="openAsset(row.AssetId)">{{ getTypeDescription(row.TypeId) }}</td>
    </ng-container>
    <ng-container matColumnDef="Condition">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style=" text-align: center !important;">Condition</th>
        <td mat-cell *matCellDef="let row" (click)="openAsset(row.AssetId)" style=" text-align: center !important;">
            <img [src]="getConditionImage(row.ConditionId)" height="24" width="24" />
        </td>
    </ng-container>
    <ng-container matColumnDef="Report">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style=" text-align: center !important;">Report</th>
        <td mat-cell *matCellDef="let row" style=" text-align: center !important;">
          <a *ngIf="row.HasReport" [href]="'https://hrt-fesportal-hgamdfadh9dyb3cv.westeurope-01.azurewebsites.net/RepairReports/' + fixUrl(row.ReportLocation)" target="_blank">
            <img [src]="pdfImage" height="24" width="24" style="cursor: pointer;" [matTooltip]="row.ReportLocation" />
          </a>
          <img *ngIf="row.HasStorageReport" [src]="pdfImage" height="24" width="24" style="cursor: pointer;" (click)="downloadStorageReport(row)" />
        </td>
    </ng-container>
    <ng-container matColumnDef="Notes">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style=" text-align: center !important;">Notes</th>
        <td mat-cell *matCellDef="let row" style=" text-align: center !important;">
            <img [src]="infoImage" height="24" width="24" style="cursor: pointer;" (click)="showNotes(row)" />
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="serviceColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: serviceColumns;" class="TableRowClickable"></tr>
</table>
<mat-paginator [length]="serviceTable.Result.Count" [pageSizeOptions]="serviceTable.View.PageSizeOptions" [pageSize]="serviceTable.View.PageSize" [pageIndex]="serviceTable.View.Page" (page)="serviceTable.RefreshData($event)">
</mat-paginator>