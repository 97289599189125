import { Component, Input } from '@angular/core';
import { GeoChartOptions } from 'src/app/shared/components/geo-chart/geo-chart.component';
import { AssetConditionCount, AssetServiceRecordDashboardView } from 'src/app/shared/data/data.generated';
import {
  HRTColumnDefinition,
  SelectedFilters,
} from 'src/app/shared/data/filterConfiguration';
import { ApiService } from 'src/app/shared/services/api.service';
import {
  Table,
  TableView,
} from 'src/app/shared/services/api/table';
import { RegionDataService } from 'src/app/shared/services/region-data.service';
import {
  DashboardQueryType,
  DashboardService,
  DashboardServiceCondition,
} from 'src/app/shared/services/dashboard.service';
import { FilterTableSettings } from '@gea/digital-ui-lib';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { HrtTableService } from 'src/app/shared/services/hrt-table.service';

@Component({
  selector: 'app-dashboard-service',
  templateUrl: './dashboard-service.component.html',
  styleUrls: ['./dashboard-service.component.scss'],
})
export class DashboardServiceComponent {
  tableId: string = 'dashboardService';
  totalCount: number = 0;
  columnDefinitions: HRTColumnDefinition[];
  tableData: any[];
  listOptions: TableView = new TableView();
  unsortableKeys: string[] = [
    'ProductTypeId',
    'ConditionId',
    'ReportLocation',
    'VisitNotes',
  ];

  countries: any[] = [];
  presetFilter: SelectedFilters = {
    Country: [
      {
        key: 'USA',
        label: 'United States',
      },
    ],
    Condition: [
      {
        key: DashboardServiceCondition.Serious.toString(),
        label: 'Serious',
      },
    ],
  };
  defaultTableConfig: FilterTableSettings = {
    pageSize: 20,
    columns: {},
  };
  assetsType: DashboardQueryType = DashboardQueryType.ServiceData;
  selectedCountry: string = 'USA';
  selectedState: string = '';
  selectedType: DashboardQueryType = DashboardQueryType.ServiceData;
  selectedCondition: DashboardServiceCondition =
    DashboardServiceCondition.Serious;
  selectedProductType: number = -1;
  appliedType: DashboardQueryType = DashboardQueryType.ServiceData;

  showWorldMap: boolean = true;
  showStateMap: boolean = true;
  tableDataLoading: boolean = false;
  initialLoad: boolean = true;

  mapData: (string | number)[][] = [];
  mapDataLoading: boolean = false;
  worldOptions: GeoChartOptions = GeoChartOptions.WorldViewOptions;
  stateOptions: GeoChartOptions = GeoChartOptions.StateViewOptions;

  serviceTable: Table<AssetServiceRecordDashboardView> =
    new Table<AssetServiceRecordDashboardView>(this.loader);

  conditionCountsFetched = false;
  conditionObj = {
    ok: {
      text: 'Normal',
      count: 0,
    },
    warning: {
      text: 'Warning',
      count: 0,
    },
    serious: {
      text: 'Serious',
      count: 0,
    },
  };
  okPercent = 0;
  warningPercent = 0;
  seriousPercent = 0;

  public displayedColumns: string[] = [
    'Icon',
    'Location',
    'Manufacturer',
    'Model',
    'AlternativeModel',
    'ServiceDate',
    'ServiceType',
    'ConditionId',
    'ReportLocation',
    'VisitNotes',
  ];

  constructor(
    private regionData: RegionDataService,
    private api: ApiService,
    private dashboardService: DashboardService,
    private hrtTableService: HrtTableService,
    private utilityService: UtilityService,
    private loader: LoaderService
  ) {}

  @Input() data: any;

  ngOnInit(): void {
    this.applyPresetFilters();
    if (sessionStorage.getItem(this.tableId + '-listOptions')) {
      this.listOptions = JSON.parse(
        sessionStorage.getItem(this.tableId + '-listOptions') || ''
      );
    }
    this.serviceTable.GetApi = (listOptions: TableView) => {
      this.fetchDashboardConditionCounts();
      return this.api.Assets.GetAssetServiceRecordDashboardViews(
        listOptions,
        this.selectedCountry,
        this.selectedState,
        this.selectedProductType,
        this.selectedCondition
      );
    };

    this.serviceTable.Updated = () => (this.tableDataLoading = false);

    // For initial load, control doesn't go in tap unless subscribed.
    // this.serviceTable.refreshDataForTable()?.subscribe();

    this.updateMapAndTable();
    this.utilityService.setActiveTab(2, 'dashboardActiveIndex');
  }

  ngAfterViewInit() {
    this.initialLoad = false;
  }

  fetchDashboardConditionCounts() {
    this.api.Assets.GetAssetServiceConditionCount(
      this.selectedCountry
    ).subscribe((resp: AssetConditionCount) => {
      if (!resp) return;

      // console.log('Condition counts resp:', resp);
      this.conditionObj.ok.count = resp.counts.ok;
      this.conditionObj.warning.count = resp.counts.warning;
      this.conditionObj.serious.count = resp.counts.serious;

      const total = resp.counts.ok + resp.counts.warning + resp.counts.serious;
      this.fetchConditionPercentage(total);
    });
  }

  fetchConditionPercentage(total: number) {
    this.okPercent = (this.conditionObj.ok.count / total) * 100;
    this.warningPercent = (this.conditionObj.warning.count / total) * 100;
    this.seriousPercent = (this.conditionObj.serious.count / total) * 100;
  }

  applyPresetFilters() {
    if (!sessionStorage.getItem('filter-chips-' + this.tableId)) return;
    this.presetFilter = JSON.parse(
      sessionStorage.getItem('filter-chips-' + this.tableId) || ''
    );

    this.selectedCountry =
      this.presetFilter['Country'] && this.presetFilter['Country'].length > 0
        ? this.presetFilter['Country'][0].key.toString()
        : '';
    this.selectedState =
      this.presetFilter['State'] && this.presetFilter['State'].length > 0
        ? this.presetFilter['State'][0].key.toString()
        : '';
    // if null, then serious else if [] -> All else condition
    this.selectedCondition = !this.presetFilter['Condition']
      ? DashboardServiceCondition.Serious
      : this.presetFilter['Condition'] &&
        this.presetFilter['Condition'].length > 0
      ? parseInt(this.presetFilter['Condition'][0].key.toString())
      : DashboardServiceCondition.All;
  }


  handleSelectedFilters(selectedFilters: SelectedFilters) {
    // console.log('Selected filters in parent:', selectedFilters);
    if (selectedFilters) {
      if (!selectedFilters['Country']) {
        this.selectedCountry = '';
        this.selectedState = '';
      }
      if (!selectedFilters['Condition']) {
        this.selectedCondition = DashboardServiceCondition.All;
      }
      if (!selectedFilters['State']) {
        this.selectedState = '';
      }
      this.updateMapAndTable();
    }
  }

  regionSelected(region: string) {
    if (this.showWorldMap) {
      let lookup2Digit = this.regionData.Countries.find(
        (x) => x.Code2 == region
      );
      let lookupName = this.regionData.Countries.find((x) => x.Name == region);
      lookup2Digit?.Name
        ? this.updateCountryFilter(lookup2Digit.Code3, lookup2Digit.Name)
        : null;
      this.selectedCountry = lookup2Digit?.Code3 ?? lookupName?.Code3 ?? region;
      this.updateMapAndTable();
    } else if (this.showStateMap) {
      const stateName = this.regionData.States.find(
        (x) => x.Code == region
      )?.Name;
      this.selectedState = region;
      if (stateName) this.updateStateFilter(region, stateName);
      this.updateMapAndTable();
    }
  }

  updateCountryFilter(countryCode: string, countryName: string) {
    if (this.presetFilter) {
      this.presetFilter = {
        ...this.presetFilter,
        Country: [
          {
            key: countryCode,
            label: countryName,
          },
        ],
      };
    }
  }

  updateStateFilter(stateCode: string, stateName: string) {
    if (this.presetFilter) {
      this.presetFilter = {
        ...this.presetFilter,
        State: [
          {
            key: stateCode,
            label: stateName,
          },
        ],
      };
    }
  }

  updateMapAndTable() {
    //this.tableDataLoading = true;
    if (this.selectedCondition == DashboardServiceCondition.Serious) {
      this.stateOptions.colorAxis = { colors: ['lightGray', '#a61920'] };
      this.worldOptions.colorAxis = { colors: ['lightGray', '#a61920'] };
    } else if (this.selectedCondition == DashboardServiceCondition.Warning) {
      this.stateOptions.colorAxis = { colors: ['lightGray', '#EDD622'] };
      this.worldOptions.colorAxis = { colors: ['lightGray', '#EDD622'] };
    } else if (this.selectedCondition === DashboardServiceCondition.Ok) {
      this.stateOptions.colorAxis = { colors: ['lightGray', '#107c3c'] };
      this.worldOptions.colorAxis = { colors: ['lightGray', '#107c3c'] };
    } else {
      this.stateOptions.colorAxis = { colors: ['lightGray', '#0303B8'] };
      this.worldOptions.colorAxis = { colors: ['lightGray', '#0303B8'] };
    }

    if (this.selectedType !== DashboardQueryType.OperatingData) {
      this.appliedType = this.selectedType;
    }
    if (this.selectedCountry === '' && this.selectedState === '') {
      this.showWorldMap = true;
      this.showStateMap = false;
    } else if (this.selectedCountry === 'USA') {
      //this.selectedState === ""
      this.showWorldMap = false;
      this.showStateMap = true;
    } else {
      this.showWorldMap = false;
      this.showStateMap = false;
    }
    if (this.showWorldMap || this.showStateMap) {
      this.mapDataLoading = true;
      this.serviceTable.oldRequest = {};
      this.serviceTable.SearchUpdated();
      this.api.Assets.GetAssetMapData(
        this.selectedType,
        this.selectedCountry,
        this.selectedProductType,
        this.selectedCondition
      ).subscribe((x) => {
        this.mapData = this.dashboardService.normalizeCountryData(x);
        this.mapDataLoading = false;
      });
    } else {
      this.serviceTable.oldRequest = {};
      this.serviceTable.SearchUpdated();
    }
  }

  updateSearchTerm(searchText: string) {
    // console.log(`SearchText is ${searchText}`);
    if (this.initialLoad) return;

    this.serviceTable.View.Search = searchText;
    this.listOptions.Search = searchText;
    this.hrtTableService.saveListOptionsToSS(this.tableId, this.listOptions);
    this.serviceTable.SearchUpdated();
  }

  rowClicked(rowData: any) {
    // console.log('Row click event', rowData);
    this.utilityService.openAsset(rowData.AssetId);
  }

  exportAssetService() {
    const listOptions = this.listOptions;
    listOptions.Page = 0;
    listOptions.HasPaging = false;
    this.api.Assets.ExportAssetService(
      listOptions,
      this.selectedCountry,
      this.selectedState,
      this.selectedProductType,
      this.selectedCondition
    );
  }

  backToWorld() {
    this.selectedCountry = '';
    this.selectedState = '';


    this.presetFilter = {
      ...this.presetFilter,
      Country: [],
      State: [],
    };
    this.updateMapAndTable();
  }

  filterDashboard(statusText: string) {
    switch (statusText) {
      case 'Normal':
        this.selectedCondition = DashboardServiceCondition.Ok;
        this.presetFilter = {
          ...this.presetFilter,
          Condition: [
            {
              key: DashboardServiceCondition.Ok.toString(),
              label: 'Normal',
            },
          ],
        };
        break;
      case 'Warning':
        this.selectedCondition = DashboardServiceCondition.Warning;
        this.presetFilter = {
          ...this.presetFilter,
          Condition: [
            {
              key: DashboardServiceCondition.Warning.toString(),
              label: 'Warning',
            },
          ],
        };
        break;
      case 'Serious':
        this.selectedCondition = DashboardServiceCondition.Serious;
        this.presetFilter = {
          ...this.presetFilter,
          Condition: [
            {
              key: DashboardServiceCondition.Serious.toString(),
              label: 'Serious',
            },
          ],
        };
        break;
    }
    this.updateMapAndTable();
  }

  ngOnDestroy() {
    sessionStorage.setItem(
      this.tableId + '-listOptions',
      JSON.stringify(this.listOptions)
    );
  }
}
