<h2>{{ mode == "edit" ? 'Edit ' : 'Add ' }} Service Record</h2>
<table>
    <col style="width: 150px">
    <col style="width: 250px">
    <col style="width: 150px">
    <col style="width: 300px">
    <tr>
        <td>Record Type</td>
        <td>
            <mat-form-field class="DropDown">
                <mat-select [(ngModel)]="data.TypeId">
                    <mat-optgroup>
                        <mat-option *ngFor="let type of availableTypes" [value]="type.Id">{{ type.Name }}</mat-option>
                    </mat-optgroup>
                </mat-select>
            </mat-form-field>
        </td>
        <td>Condition</td>
        <td>
            <mat-form-field class="ConditionDropDown">
                <mat-select [(value)]="data.ConditionId">
                    <mat-select-trigger>
                        <img [src]="getConditionImage(data.ConditionId)"> {{ getConditionText(data.ConditionId) }}
                    </mat-select-trigger>
                    <mat-optgroup label="Conditions">
                        <mat-option *ngFor="let condition of conditions" [value]="condition.Id"> <img [src]="getConditionImage(condition.Id)"> {{ condition.Name }}</mat-option>
                    </mat-optgroup>
                </mat-select>
            </mat-form-field>
        </td>
    </tr>
    <tr>
        <td>Date</td>
        <td>
            <mat-form-field class="DateBox">
                <input matInput [matDatepicker]="picker" placeholder="Choose a date" [(ngModel)]="data.Date">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </td>
        <td *ngIf="data.TypeId === vibrationId">Vibration Points</td>
        <td *ngIf="data.TypeId === vibrationId">
            C1:
            <mat-form-field class="VibrationPointDropDown">
                <mat-select [(value)]="data.C1Id">
                    <mat-select-trigger>
                        <img [src]="getConditionImage(data.C1Id)"> {{ getConditionText(data.C1Id) }}
                    </mat-select-trigger>
                    <mat-optgroup label="Conditions">
                        <mat-option *ngFor="let condition of conditions" [value]="condition.Id"> <img [src]="getConditionImage(condition.Id)"> {{ condition.Name }}</mat-option>
                    </mat-optgroup>
                </mat-select>
            </mat-form-field>
        </td>
    </tr>
    <tr>
        <td>Service Order #</td>
        <td>
            <mat-form-field class="TextBox">
                <input matInput required [(ngModel)]="data.ServiceOrderNumber" />
            </mat-form-field>
        </td>
        <td *ngIf="data.TypeId === vibrationId"></td>
        <td *ngIf="data.TypeId === vibrationId">
            C2:
            <mat-form-field class="VibrationPointDropDown">
                <mat-select [(value)]="data.C2Id">
                    <mat-select-trigger>
                        <img [src]="getConditionImage(data.C2Id)"> {{ getConditionText(data.C2Id) }}
                    </mat-select-trigger>
                    <mat-optgroup label="Conditions">
                        <mat-option *ngFor="let condition of conditions" [value]="condition.Id"> <img [src]="getConditionImage(condition.Id)"> {{ condition.Name }}</mat-option>
                    </mat-optgroup>
                </mat-select>
            </mat-form-field>
        </td>
    </tr>
    <tr>
        <td>Site Contact</td>
        <td>
            <mat-form-field class="TextBox">
                <input matInput required [(ngModel)]="data.SiteContact" />
            </mat-form-field>
        </td>
        <td *ngIf="data.TypeId === vibrationId"></td>
        <td *ngIf="data.TypeId === vibrationId">
            C3:
            <mat-form-field class="VibrationPointDropDown">
                <mat-select [(value)]="data.C3Id">
                    <mat-select-trigger>
                        <img [src]="getConditionImage(data.C3Id)"> {{ getConditionText(data.C3Id) }}
                    </mat-select-trigger>
                    <mat-optgroup label="Conditions">
                        <mat-option *ngFor="let condition of conditions" [value]="condition.Id"> <img [src]="getConditionImage(condition.Id)"> {{ condition.Name }}</mat-option>
                    </mat-optgroup>
                </mat-select>
            </mat-form-field>
        </td>
    </tr>
    <tr>
        <td>Service Company</td>
        <td>
            <mat-form-field class="TextBox">
                <input matInput required [(ngModel)]="data.ServiceCompany" />
            </mat-form-field>
        </td>
        <td *ngIf="data.TypeId === vibrationId"></td>
        <td *ngIf="data.TypeId === vibrationId">
            C4:
            <mat-form-field class="VibrationPointDropDown">
                <mat-select [(value)]="data.C4Id">
                    <mat-select-trigger>
                        <img [src]="getConditionImage(data.C4Id)"> {{ getConditionText(data.C4Id) }}
                    </mat-select-trigger>
                    <mat-optgroup label="Conditions">
                        <mat-option *ngFor="let condition of conditions" [value]="condition.Id"> <img [src]="getConditionImage(condition.Id)"> {{ condition.Name }}</mat-option>
                    </mat-optgroup>
                </mat-select>
            </mat-form-field>
        </td>
    </tr>
    <tr>
        <td>Service Technician</td>
        <td>
            <mat-form-field class="TextBox">
                <input matInput required [(ngModel)]="data.Technician" />
            </mat-form-field>
        </td>
        <td *ngIf="data.TypeId === vibrationId"></td>
        <td *ngIf="data.TypeId === vibrationId">
            M1:
            <mat-form-field class="VibrationPointDropDown">
                <mat-select [(value)]="data.M1Id">
                    <mat-select-trigger>
                        <img [src]="getConditionImage(data.M1Id)"> {{ getConditionText(data.M1Id) }}
                    </mat-select-trigger>
                    <mat-optgroup label="Conditions">
                        <mat-option *ngFor="let condition of conditions" [value]="condition.Id"> <img [src]="getConditionImage(condition.Id)"> {{ condition.Name }}</mat-option>
                    </mat-optgroup>
                </mat-select>
            </mat-form-field>
        </td>
    </tr>
    <tr>
        <td>Project #</td>
        <td>
            <mat-form-field class="TextBox">
                <input matInput required [(ngModel)]="projectNumber" readonly/>
            </mat-form-field>
        </td>
            <td *ngIf="data.TypeId === vibrationId"></td>
            <td *ngIf="data.TypeId === vibrationId">
                M2:
                <mat-form-field class="VibrationPointDropDown">
                    <mat-select [(value)]="data.M2Id">
                        <mat-select-trigger>
                            <img [src]="getConditionImage(data.M2Id)"> {{ getConditionText(data.M2Id) }}
                        </mat-select-trigger>
                        <mat-optgroup label="Conditions">
                            <mat-option *ngFor="let condition of conditions" [value]="condition.Id"> <img [src]="getConditionImage(condition.Id)"> {{ condition.Name }}</mat-option>
                        </mat-optgroup>
                    </mat-select>
                </mat-form-field>
            </td>
    </tr>
    <tr>
        <td>Serial #</td>
        <td>
            <mat-form-field class="TextBox">
                <input matInput required [(ngModel)]="serialNumber" readonly/>
            </mat-form-field>
        </td>
        <td rowspan="2">Service Notes</td>
        <td rowspan="2">
            <mat-form-field appearance="fill" class="TextArea" >
                <textarea matInput [(ngModel)]="data.VisitNotes" style="height: 60px;">
                </textarea>
            </mat-form-field>
        </td>
    </tr>
    <tr>
        <td>Reference Name</td>
        <td>
            <mat-form-field class="TextBox">
                <input matInput required [(ngModel)]="data.ReferenceName" />
            </mat-form-field>
        </td>
    </tr>
    <tr>
        <td>
            PDF Report File
        </td>
        <td>
            <input type="file" accept="application/pdf" class="FileDownload" (change)="onFileSelected($event)">
        </td>
        <td></td>
        <td>
            <span class="ErrorText">{{ validationMessage }}</span>
        </td>
    </tr>
</table>

<button (click)="submit()" class="Button geaui-button-primary">Save</button>
<button (click)="cancel()" class="Button geaui-button-secondary">Cancel</button>
