import { Injectable } from '@angular/core';
import { DashboardService } from './dashboard.service';
import { WebsocketService } from './websocket.service';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  constructor(
    private dashboardService: DashboardService,
    private websocketService: WebsocketService
  ) {}

  filterFuncMap: any = {
    AnnunciationStatus: (field: string, statuses: string[]) =>
      this.matchAnnunStatus(field, statuses),
  };

  matchAnnunStatus(field: string, statuses: string[]): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const maxTries = 20;
      let attempts = 0;

      const checkStatus = () => {
        if (
          this.websocketService.isSocketConnected &&
          Object.keys(this.websocketService.annunStatuses).length > 0
        ) {
          resolve(this.dashboardService.getAnnunStatusValue(field));
        } else {
          if (attempts < maxTries) {
            console.log('Waiting for socket connection...');
            attempts++;
            setTimeout(checkStatus, 1000);
          } else {
            reject(
              new Error(
                'Failed to connect to WebSocket after multiple attempts.'
              )
            );
          }
        }
      };
      checkStatus();
    });
  }
}
