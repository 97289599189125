import { Component} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { HrtBookmarkComponent } from 'src/app/shared/components/hrt-bookmark/hrt-bookmark.component';
import { ApiService } from 'src/app/shared/services/api.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { AddConfigPopupComponent } from '../add-config-popup/add-config-popup.component';
import { Table, TableResult, TableView } from 'src/app/shared/services/api/table';
import { ConfigData, ConfigList } from 'src/app/shared/data/data.generated';
import { debounceTime, tap } from 'rxjs';
import { HrtDialogComponent } from '../../hrt-dialog/hrt-dialog.component';

@Component({
  selector: 'gea-config-details',
  templateUrl: './config-details.component.html',
  styleUrl: './config-details.component.scss',
})
export class ConfigDetailsComponent {

  configListId: number;
  configName: string;
  configValue: string;
  public url: any;
  public configDetails: any;
  public bookmarkName: any;
  public bookmarkType: string = 'Config';
  public isBookmark: boolean;
  public link: any;
  tableId = 'configData';
  searchPlaceholder = 'Search Config Data';
  configDataTable: Table<ConfigData> = new Table<ConfigData>(this.loader);
  // configDataTable: TableResult<ConfigData>= new TableResult<ConfigData>;
  public displayedColumns: string[] = [
    'Target',
    'Key',
    'Value',
    'Actions'
  ];

  public homepageTargets: string [] = [
    'Product Slides',
    'App info',
  ]
  listOptions: TableView = new TableView();

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private loader: LoaderService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {

    this.route.params.subscribe((p: any) => {
      this.configListId = p['id'];
      this.configName = p['configName'];
      this.configValue = p['configValue']
      this.configDataTable.GetApi = (listOptions: TableView) =>
        this.api.Config.GetConfigData(this.configListId, this.listOptions);
      this.configDataTable.inputSubject
      .pipe(debounceTime(500))
      .subscribe((value) => {
        this.configDataTable.SearchUpdated();
      });
      // this.api.Config.GetConfigData(this.id, this.listOptions).subscribe({
      //   next: (x) => {
      //     this.configDataTable = x.Data;
      //     this.loader.hideLoadingIcon();
      //   },
      //   error: (error) => {
      //     console.log(error);
      //     this.loader.hideLoadingIcon();
      //   },
      // });


      const userBookmarks = JSON.parse(
        sessionStorage.getItem('UserBookmarks') ?? ''
      );
      for (let i = 0; i < userBookmarks.length; i++) {
        if (
          userBookmarks[i].ForeignKey == this.configListId &&
          userBookmarks[i].BookmarkType == this.bookmarkType
        ) {
          this.isBookmark = !this.isBookmark;
        }
      }
    });
  }


  addtoConfigData(){
    const dialogRef = this.dialog.open(AddConfigPopupComponent, {
      data: {
        flag: 'ConfigData',
        ConfigListId: this.configListId,
        ConfigListValue: this.configValue,
      },
    });
    dialogRef.afterClosed().subscribe(newConfigData => {
      if (newConfigData) {
        // console.log('Config data received in parent:', newConfigData);
        // Add to Config Data.
        this.api.Config.AddOrEditConfigData(newConfigData).subscribe({
          next: (x) => {
            this.configDataTable.UpdateResult(x);
          },
          error: (err) => {
            console.log('Error: ' + err);
          }
        });
      }
    })
  }

  toggleBookmark(isBookmarked: boolean){
    if(!isBookmarked){
      this.url = window.location.href;
      this.bookmarkName = this.configValue ;
      const dialogRef = this.dialog.open(HrtBookmarkComponent, {
        data: { url: this.url, bookmarkName: this.bookmarkName },
      });

      dialogRef.afterClosed().subscribe((result) => {
        this.bookmarkName = result;
        if(result) {
          this.api.Bookmark.AddBookmark(this.configListId, this.bookmarkType,this.url, this.bookmarkName).subscribe({
            next: (x) => {
              this.isBookmark = !this.isBookmark;
          },
          });
          let existingBookmarks = JSON.parse(sessionStorage.getItem('UserBookmarks') || '')
          existingBookmarks.push({ForeignKey: this.configListId, BookmarkType: this.bookmarkType})
          sessionStorage.setItem('UserBookmarks',JSON.stringify(existingBookmarks))
        }
      });
    } else {
      this.api.Bookmark.DeleteBookmark(this.configListId, this.bookmarkType).subscribe({
        next: (x) => {
          this.isBookmark = !this.isBookmark;
      },
      });
      let existingBookmarks = JSON.parse(sessionStorage.getItem('UserBookmarks') || '');
      existingBookmarks = existingBookmarks.filter((bookmark: { ForeignKey: number; BookmarkType: string; }) => !(bookmark.ForeignKey === this.configListId && bookmark.BookmarkType ===this.bookmarkType))
      sessionStorage.setItem('UserBookmarks',JSON.stringify(existingBookmarks));
    }
  }


  editData(ele: any){
    const dialogRef = this.dialog.open(AddConfigPopupComponent, {
      data: {
        flag: 'ConfigData',
        row: JSON.parse(JSON.stringify(ele)),
        action: 'Edit',
        ConfigListId: this.configListId,
        ConfigListValue: this.configValue,
      },
    });
    dialogRef.afterClosed().subscribe(newConfigData => {
      if (newConfigData) {
        // console.log('Config data received in parent:', newConfigData);
        //Edit Config Data.
        this.api.Config.AddOrEditConfigData(newConfigData).subscribe({
          next: (x) => {
            this.configDataTable.UpdateResult(x);
          },
          error: (err) => {
            console.log('Error: ' + err);
          }
        });
      }
    })


  }

  deleteData(ele:any){
    const dialogRef = this.dialog.open(HrtDialogComponent, {
      data: {title: "Delete Data", msg: "Are you sure want to permanently delete this data?"}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.api.Config.DeleteConfigData(ele.Id, ele.ConfigListId).subscribe({
          next: (x) => {
            this.configDataTable.UpdateResult(x);
          },
          error: (err) => {
            console.log('Error: ' + err);
          }
        });
        let existingBookmarks = JSON.parse(sessionStorage.getItem('UserBookmarks') || '');
        existingBookmarks = existingBookmarks.filter((bookmark: { ForeignKey: number; BookmarkType: string; }) => !(bookmark.ForeignKey === ele.ForeignKey && bookmark.BookmarkType === ele.BookmarkType))
        sessionStorage.setItem('UserBookmarks',JSON.stringify(existingBookmarks));
      }
    }); 

  }

  
}
