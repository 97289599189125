export enum Application {
    RTSelect = 1,
    HRTConnect = 2,
    Other = 4,
    BluRedCare = 5
}

export const Applications = [
    { Id: Application.RTSelect, Name: "RTSelect"},
    { Id: Application.HRTConnect, Name: "HRT Connect"},
    { Id: Application.BluRedCare, Name: "Blu-Red Care"},
];

const appDictionary = Applications.reduce((acc: ApplicationDictionary, product) => {
    acc[product.Id] = product.Name;
    return acc;
}, {});

export function GetApplicationTypeText(id: number = 1) {
    let textData = appDictionary[id];
    if (textData) {
        return textData;
    }
    else {
        return "RTSelect";
    }
}

export function GetAppTextList(ids: number[]): string[] {
    let appList: string[] = [];
    if (ids.length > 0) {
        ids.map(id => {
            const name = appDictionary[id];
            if (name)
                appList.push(name);
        });
    }
    return appList;
}

export function GetAppList(idString:string) {
    const ids: number[] = idString.split(',').map(x => parseInt(x));
    return GetAppTextList(ids);
}

interface ApplicationDictionary {
    [key: number]: string
}