<!-- <div class="header">
    <span class="header-title" scope="col" >Document Info</span>
    <img src="..\assets\product-icons\Information.svg" scope="col" />
</div> -->
<hrt-tab [hasTabs]="false" [title]="'Document Info'" [bookmarkData]="isBookmark" (bookmarkToggled)="toggleBookmark($event)" [hasBookmark]="true" [hasShareButton]="true"></hrt-tab>
<div class="details-container">
    <div class="detail-row">
      <span>Title</span>
      <span colspan="4" style="font-weight:bold; padding-left: 4px; font-size: 16px; color: rgb(0, 15, 65);"> &nbsp; {{selectedDoc?.Title}}</span>
    </div> 
    <div class="detail-row">
      <span >Language</span>
      <span colspan="4">&nbsp; {{selectedDoc?.Language}}</span>
    </div>
    <div class="detail-row">
      <span >Document Id</span>
      <span colspan="4">&nbsp; {{selectedDoc?.Id}}</span>
    </div>
    <div class="detail-row">
      <span>Document Type</span>
      <span colspan="4">&nbsp; {{selectedDoc?.DocumentType}}</span>
    </div>
    <div class="detail-row">
      <span>File Type</span>
      <span colspan="4">&nbsp; {{selectedDoc?.FileType}}</span>
    </div>
    <div class="detail-row">
      <span>File Size</span>
      <span colspan="4">&nbsp; {{selectedDoc?.Size}} MB</span>
    </div>
    <div class="detail-row">
      <span>File Name</span>
      <span colspan="4">&nbsp; {{selectedDoc?.OriginalFileName}}</span>
    </div>
    <div class="detail-row">
      <span>Keywords</span>
      <span colspan="4">&nbsp; {{selectedDoc?.Keywords}}</span>
    </div>
</div>  
<button mat-raised-button class="geaui-button-primary mgn-15"
        (click)="downloadDocument(selectedDoc)">
      <div *ngIf="!downloading" class="download-btn">
          Download File
          <mat-icon matSuffix class="custom-svg">download</mat-icon>
      </div>
      <div *ngIf="downloading">
          Downloading...
          <mat-spinner diameter="20" style="display: inline-block; vertical-align: center;"></mat-spinner>
      </div>
</button>  