
<hrt-tab [hasTabs]="false" [title]="'Config Details'" [bookmarkData]="isBookmark" (bookmarkToggled)="toggleBookmark($event)" [hasBookmark]="true" [hasShareButton]="true"></hrt-tab>
<div class="details-container">
    <div class="detail-row">
      <span>Name</span>
      <span colspan="4" style="font-weight:bold; padding-left: 4px; font-size: 16px; color: rgb(0, 15, 65);">{{configName}}</span>
    </div> 
    <div class="detail-row">
      <span >Value</span>
      <span colspan="4" style="font-weight:bold; padding-left: 4px; font-size: 16px; color: rgb(0, 15, 65);">{{configValue}}</span>
    </div>
</div>  

<mat-divider class="divider"></mat-divider>

<div class="config-list-header">
  <h1 class="sitedetails">
    <geaui-icon-v2
      class="geaui-hrt-icon"
      file="16px_settings-gear"
      [color]="'black'"
      [colorProp]="'stroke'"
    ></geaui-icon-v2>
    Config List
  </h1>
  <button
    class="geaui-button-primary AddButton"
    (click)="addtoConfigData()"
    >
    Add Item
  </button>
</div>


<table
  mat-table
  [dataSource]="configDataTable.Result.Data"
>

<ng-container matColumnDef="Target">
  <th mat-header-cell *matHeaderCellDef style="width: 15%;">Target</th>
  <td mat-cell *matCellDef="let element">{{ element.Target }}
  </td>
</ng-container>

<ng-container matColumnDef="Key">
  <th mat-header-cell *matHeaderCellDef style="width: 20%;">Key</th>
  <td mat-cell *matCellDef="let element">{{ element.key }}
  </td>
</ng-container>

<ng-container matColumnDef="Value">
  <th mat-header-cell *matHeaderCellDef>Value</th>
  <td mat-cell *matCellDef="let element" class="value-cell">{{ element.value }}
  </td>
</ng-container>

<ng-container matColumnDef="Actions">
  <th mat-header-cell *matHeaderCellDef style="width: 10%;">Actions</th>
  <td mat-cell *matCellDef="let ele">
    <div class="action-buttons">
      <span (click)="editData(ele)">
        <mat-icon matSuffix class="ActionIcon" title="Edit"><geaui-icon-v2
          file="16px_edit"
          [colorProp]="'stroke'"
        ></geaui-icon-v2></mat-icon>
      </span>
      <span (click)="deleteData(ele)">
        <mat-icon matSuffix class="ActionIcon" title="Delete"><geaui-icon-v2
          file="16px_remove"
          [colorProp]="'stroke'"
        ></geaui-icon-v2></mat-icon>
      </span>
    </div>
    
  </td>
</ng-container>


<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
<tr
  mat-row
  *matRowDef="let row; columns: displayedColumns;"
  style="cursor: pointer"
></tr>
</table>
