import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GeoChartOptions } from 'src/app/shared/components/geo-chart/geo-chart.component';
import { TabData } from 'src/app/shared/components/hrt-tab/hrt-tab.component';
import {
  Asset,
  AssetMapData,
  AssetServiceRecord,
  AssetServiceRecordDashboardView,
  Site,
} from 'src/app/shared/data/data.generated';
import {
  GetCustomTypeIcon,
  GetCustomTypeIconWidth,
  GetCustomTypeText,
  ProductTypes,
} from 'src/app/shared/data/product-types';
import { ServiceTypes } from 'src/app/shared/data/service-types';
import { ApiService } from 'src/app/shared/services/api.service';
import { Table, TableView } from 'src/app/shared/services/api/table';
import { RegionDataService } from 'src/app/shared/services/region-data.service';
import { WebsocketService } from 'src/app/shared/services/websocket.service';
import { DashboardAssetComponent } from './dashboard-asset/dashboard-asset.component';
import { DashboardServiceComponent } from './dashboard-service/dashboard-service.component';
import { DashboardSitesComponent } from './dashboard-sites/dashboard-sites.component';
import { DashboardIotComponent } from './dashboard-iot/dashboard-iot.component';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { DashboardIotAdComponent } from './dashborad-iot-ad/dashboard-iot-ad.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  socketInterval: NodeJS.Timer;

  assetsType: DashboardQueryType = DashboardQueryType.Assets;
  sitesType: DashboardQueryType = DashboardQueryType.Sites;
  serviceDataType: DashboardQueryType = DashboardQueryType.ServiceData;

  notesDialog: boolean = false;
  notesItem: AssetServiceRecord = new AssetServiceRecord();

  types: any[] = [];
  conditions: any[] = [];
  countries: any[] = [];
  states: any[] = [];
  productTypes: any[] = [];

  selectedType: DashboardQueryType = DashboardQueryType.Assets;
  selectedCondition: DashboardServiceCondition = DashboardServiceCondition.All;
  selectedCountry: string = 'USA';
  selectedState: string = '';
  selectedProductType: number = -1;
  selectedOperatingStatus: string = '';
  selectedTabIndex = 0;

  filtersApplied: any[] = [];

  assetTable: Table<Asset> = new Table<Asset>();
  siteTable: Table<Site> = new Table<Site>();
  serviceTable: Table<AssetServiceRecordDashboardView> =
    new Table<AssetServiceRecordDashboardView>();

  getCustomTypeText = GetCustomTypeText;
  getCustomTypeIcon = GetCustomTypeIcon;
  getCustomTypeIconWidth = GetCustomTypeIconWidth;

  showWorldMap: boolean = true;
  showStateMap: boolean = true;
  mapData: (string | number)[][] = [];
  worldOptions: GeoChartOptions = GeoChartOptions.WorldViewOptions;
  stateOptions: GeoChartOptions = GeoChartOptions.StateViewOptions;
  appliedType: DashboardQueryType = DashboardQueryType.Assets;

  mapDataLoading: boolean = false;
  tableDataLoading: boolean = false;

  isExpanded: boolean = false;

  dashboardTabs: TabData[] = [
    {
      tabName: 'Assets',
      tabComponent: DashboardAssetComponent,
      isTabVisible : true,
    },
    {
      tabName: 'Sites',
      tabComponent: DashboardSitesComponent,
      isTabVisible : true,
    },
    {
      tabName: 'Service',
      tabComponent: DashboardServiceComponent,
      isTabVisible : true,
    },
    {
      tabName: 'IoT',
      tabComponent: DashboardIotComponent,
      isTabVisible : true,
    },
    {
      tabName: 'Admin IoT',
      tabComponent: DashboardIotAdComponent,
      isTabVisible : sessionStorage.getItem('RoleId') === "7000" || "6000" ? true : false,
    },
  ];

  constructor(
    private api: ApiService,
    private regionData: RegionDataService,
    private websocketService: WebsocketService,
    public dialog: MatDialog,
    private dashboardService: DashboardService,
    private utilityService: UtilityService
  ) {}

  ngOnInit(): void {
    this.pingWebSocket();
    this.selectedTabIndex = this.utilityService.getActiveTabIndex('dashboardActiveIndex');
  }

  pingWebSocket() {
    const bucketList: string = this.websocketService.connectedBuckets.join(',');
    const messageStr = JSON.stringify({
      deviceList: bucketList
    })
    this.socketInterval = setInterval(() => {
      if (this.websocketService.isSocketConnected) {
        this.websocketService.send(messageStr);
        // console.log('Message sent:', pkList);
      } else {
        console.log('Socket not connected yet');
      }
    }, 10000);
  }

  /**
   * Toggles the show / hide of dashboard filter sidebar.
   */
  toggleSideNav() {
    this.isExpanded = !this.isExpanded;
  }

  saveFilters() {
    localStorage.setItem('DashboardSelectedType', this.selectedType.toString());
    localStorage.setItem(
      'DashboardSelectedCondition',
      this.selectedCondition.toString()
    );
    localStorage.setItem(
      'DashboardSelectedCountry',
      this.selectedCountry.toString()
    );
    localStorage.setItem(
      'DashboardSelectedState',
      this.selectedState.toString()
    );
    localStorage.setItem(
      'DashboardSelectedProductType',
      this.selectedProductType.toString()
    );
  }

  loadFilters() {
    if (localStorage.getItem('DashboardSelectedType'))
      this.selectedType = <DashboardQueryType>(
        parseInt(localStorage.getItem('DashboardSelectedType') ?? '-1')
      );
    if (localStorage.getItem('DashboardSelectedCondition'))
      this.selectedCondition = <DashboardServiceCondition>(
        parseInt(localStorage.getItem('DashboardSelectedCondition') ?? '-1')
      );
    if (localStorage.getItem('DashboardSelectedCountry'))
      this.selectedCountry =
        localStorage.getItem('DashboardSelectedCountry') ?? '';
    if (localStorage.getItem('DashboardSelectedState'))
      this.selectedState = localStorage.getItem('DashboardSelectedState') ?? '';
    if (localStorage.getItem('DashboardSelectedProductType'))
      this.selectedProductType = parseInt(
        localStorage.getItem('DashboardSelectedProductType') ?? '-1'
      );
  }

  buildAppliedFilterList() {
    this.filtersApplied = [];
    if (this.selectedCondition !== DashboardServiceCondition.All) {
      this.filtersApplied.push({
        text: 'Condition: ' + DashboardServiceCondition[this.selectedCondition],
        action: () => {
          this.selectedCondition = DashboardServiceCondition.All;
          this.updateTableData();
        },
      });
    }
    if (this.selectedCountry !== 'USA') {
      this.filtersApplied.push({
        text:
          'Country: ' +
          this.regionData.Countries.find((x) => x.Code3 == this.selectedCountry)
            ?.Name,
        action: () => {
          this.selectedCountry = 'USA';
          this.updateTableData();
        },
      });
    }
    if (this.selectedState !== '') {
      this.filtersApplied.push({
        text:
          'State: ' +
          this.regionData.States.find((x) => x.Code == this.selectedState)
            ?.Name,
        action: () => {
          this.selectedState = '';
          this.updateTableData();
        },
      });
    }
    if (this.selectedProductType !== -1) {
      this.filtersApplied.push({
        text:
          'Product Type: ' +
          ProductTypes.find((x) => x.Id == this.selectedProductType)?.Name,
        action: () => {
          this.selectedProductType = -1;
          this.updateTableData();
        },
      });
    }
    if (this.selectedOperatingStatus !== '') {
      this.filtersApplied.push({
        text: 'Annunciation Status: ' + this.selectedOperatingStatus,
        action: () => {
          this.selectedOperatingStatus = '';
          this.updateTableData();
        },
      });
    }
  }

  resetAllFilters() {
    this.selectedCondition = DashboardServiceCondition.All;
    this.selectedCountry = 'USA';
    this.selectedState = '';
    this.selectedProductType = -1;
    this.updateTableData();
  }

  populateFilterDropdowns() {
    this.types.push({ Type: DashboardQueryType.Assets, Name: 'Assets' });
    this.types.push({ Type: DashboardQueryType.Sites, Name: 'Sites' });
    this.types.push({
      Type: DashboardQueryType.ServiceData,
      Name: 'Service Data',
    });
    this.types.push({
      Type: DashboardQueryType.OperatingData,
      Name: 'Operating Data (Real-time)',
    });

    this.conditions.push({ Type: DashboardServiceCondition.All, Name: 'All' });
    this.conditions.push({ Type: DashboardServiceCondition.Ok, Name: 'Ok' });
    this.conditions.push({
      Type: DashboardServiceCondition.Warning,
      Name: 'Warning',
    });
    this.conditions.push({
      Type: DashboardServiceCondition.Serious,
      Name: 'Serious',
    });

    this.countries.push({ Code3: '', Name: 'All' });
    this.countries = this.countries.concat(this.regionData.Countries);

    this.states.push({ Code: '', Name: 'All' });
    this.states = this.states.concat(this.regionData.States);

    this.productTypes = ProductTypes;
  }

  initializeTables() {
    this.assetTable.GetApi = (listOptions: TableView) =>
      this.api.Assets.GetAssets(
        listOptions,
        undefined,
        this.selectedCountry,
        this.selectedState,
        this.selectedProductType
      );
    this.assetTable.Updated = () => (this.tableDataLoading = false);

    this.siteTable.GetApi = (listOptions: TableView) =>
      this.api.Sites.GetSites(
        listOptions,
        this.selectedCountry,
        this.selectedState,
        this.selectedProductType
      );
    this.siteTable.Updated = () => (this.tableDataLoading = false);

    this.serviceTable.GetApi = (listOptions: TableView) =>
      this.api.Assets.GetAssetServiceRecordDashboardViews(
        listOptions,
        this.selectedCountry,
        this.selectedState,
        this.selectedProductType,
        this.selectedCondition
      );
    this.serviceTable.Updated = () => (this.tableDataLoading = false);
    this.serviceTable.View.SortColumn = 'Date';
    this.serviceTable.View.SortDesc = 'True';
  }

  updateTableData() {
    //this.tableDataLoading = true;
    if (this.selectedType === DashboardQueryType.Assets) {
      this.stateOptions.colorAxis = { colors: ['lightGray', '#0303B8'] };
      this.assetTable.RefreshData();
    } else if (this.selectedType === DashboardQueryType.Sites) {
      this.stateOptions.colorAxis = { colors: ['lightGray', '#0303B8'] };
      this.siteTable.RefreshData();
    } else if (this.selectedType === DashboardQueryType.ServiceData) {
      if (this.selectedCondition == DashboardServiceCondition.Serious)
        this.stateOptions.colorAxis = { colors: ['lightGray', 'red'] };
      else if (this.selectedCondition == DashboardServiceCondition.Warning)
        this.stateOptions.colorAxis = { colors: ['lightGray', '#EDD622'] };
      else this.stateOptions.colorAxis = { colors: ['lightGray', '#0303B8'] };
      this.serviceTable.RefreshData();
    }

    if (this.selectedType !== DashboardQueryType.OperatingData) {
      this.appliedType = this.selectedType;
    }
    if (this.selectedCountry === '' && this.selectedState === '') {
      this.showWorldMap = true;
      this.showStateMap = false;
    } else if (this.selectedCountry === 'USA') {
      //this.selectedState === ""
      this.showWorldMap = false;
      this.showStateMap = true;
    } else {
      this.showWorldMap = false;
      this.showStateMap = false;
    }
    if (this.showWorldMap || this.showStateMap) {
      //this.mapDataLoading = true;
      this.api.Assets.GetAssetMapData(
        this.selectedType,
        this.selectedCountry,
        this.selectedProductType,
        this.selectedCondition
      ).subscribe((x) => {
        this.mapData = this.normalizeCountryData(x);
        this.mapDataLoading = false;
      });
    }
    this.saveFilters();
    this.buildAppliedFilterList();
  }

  filterDashboard(statusText: string) {
    // console.log('Svg clicked with status:', statusText);
    this.selectedOperatingStatus = statusText;
    this.buildAppliedFilterList();
  }

  backToWorld() {
    this.selectedCountry = '';
    this.selectedState = '';
    this.updateTableData();
  }

  regionSelected(region: string) {
    if (this.showWorldMap) {
      let lookup2Digit = this.regionData.Countries.find(
        (x) => x.Code2 == region
      );
      let lookupName = this.regionData.Countries.find((x) => x.Name == region);
      this.selectedCountry = lookup2Digit?.Code3 ?? lookupName?.Code3 ?? region;
      this.updateTableData();
    } else if (this.showStateMap) {
      this.selectedState = region;
      this.updateTableData();
    }
  }

  normalizeCountryData(rawCountryData: AssetMapData[]): (string | number)[][] {
    let countries: (string | number)[][] = [];
    for (const country of rawCountryData) {
      let lookup3Digit = this.regionData.Countries.find(
        (x) => x.Code3 == country.Area
      );
      let lookupName = this.regionData.Countries.find(
        (x) => x.Name == country.Area
      );
      let finalCode = lookup3Digit?.Code2 ?? lookupName?.Code2 ?? country.Area;

      let countryOutput = countries.find((x) => x[0] == finalCode);
      if (countryOutput === undefined) {
        countryOutput = [finalCode, 0];
        countries.push(countryOutput);
      }
      countryOutput[1] = <number>countryOutput[1] + country.Count;
    }
    return countries;
  }

  getTypeDescription(id: number) {
    return ServiceTypes.find((x) => x.Id == id)?.Name ?? '';
  }

  formatDate(dateStr: string) {
    return new Date(dateStr).toLocaleDateString('en-US', { timeZone: 'UTC' });
  }

  ngOnDestroy() {
    clearInterval(this.socketInterval);
  }
}

enum DashboardQueryType {
  Sites = 1,
  Assets = 2,
  ServiceData = 3,
  OperatingData = 4,
}

enum DashboardServiceCondition {
  All = -1,
  Ok = 1,
  Warning = 2,
  Serious = 3,
}
