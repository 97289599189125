import { Component, inject } from '@angular/core';
import { Table, TableResult, TableView } from '../../services/api/table';
import { LoaderService } from '../../services/loader.service';
import { ConfigList } from '../../data/data.generated';
import { ApiService } from '../../services/api.service';
import {
  GetFilterConfigKey,
  GetFilterConfigType,
} from '../../data/filterConfiguration';
import { debounceTime } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AddConfigPopupComponent } from './add-config-popup/add-config-popup.component';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HrtDialogComponent } from '../hrt-dialog/hrt-dialog.component';

@Component({
  selector: 'app-config-list',
  templateUrl: './config-list.component.html',
  styleUrl: './config-list.component.scss',
})
export class ConfigListComponent {

  tableId = 'configList';
  searchPlaceholder = 'Search Config';
  configTable: Table<ConfigList> = new Table<ConfigList>(this.loader);
  public displayedColumns: string[] = [
    'ConfigName',
    'ConfigValue',
    'IsUIConfig',
    'Actions',
  ];
  private snackbar = inject(MatSnackBar);

  constructor(
    private api: ApiService,
    private loader: LoaderService,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit() {
    this.configTable.GetApi = (listOptions: TableView) =>
      this.api.Config.GetConfigList(listOptions);
    this.configTable.inputSubject.pipe(debounceTime(500)).subscribe((value) => {
      this.configTable.SearchUpdated();
    });
  }

  searchChange(searchText: string) {
    if (
      searchText.trim() !== this.configTable.inputSubject.getValue() ||
      searchText === ''
    ) {
      this.configTable.inputSubject.next(searchText);
    }
  }

  applyFilters(filterEvent: any) {
    this.configTable.View.Filters = {};
    Object.keys(filterEvent).map((key: string) => {
      const filterKey = GetFilterConfigKey(key);
      const filterType = GetFilterConfigType(key);
      const values = filterEvent[key];
      if (values.length <= 0) return;
      // Convert from Type - selectedFilters to Table Filters
      this.configTable.addToFilter(filterKey, values, filterType);
    });
    this.configTable.SearchUpdated();
  }

  openConfigDetails(ele: ConfigList) {
    this.router.navigate([
      'config-details',
      { id: ele.Id, configName: ele.ConfigName, configValue: ele.ConfigValue },
    ]);
  }

  addtoConfigList() {
    const dialogRef = this.dialog.open(AddConfigPopupComponent, {
      data: {
        flag: 'ConfigList',
      },
    });
    dialogRef.afterClosed().subscribe((newConfigList) => {
      if (newConfigList) {
        // console.log('Config data received in parent:', newConfigList);
        // Add to Config List.
        this.loader.showLoadingIcon();
        this.api.Config.AddOrEditConfigList(newConfigList).subscribe({
          next: (res: TableResult<ConfigList>) => {
            this.configTable.UpdateResult(res);
          },
          error: (err) => {
            console.log('Error: ' + err);
            this.snackbar.open(
              'Failed to fetch data. Error: ' + err.messageStr,
              'X',
              {
                duration: 3000,
                panelClass: ['notify-error'],
                verticalPosition: 'top',
              }
            );
          },
          complete: () => {
            this.loader.hideLoadingIcon();
          },
        });
      }
    });
  }

  editConfigListItem(ele: ConfigList) {
    const dialogRef = this.dialog.open(AddConfigPopupComponent, {
      data: {
        flag: 'ConfigList',
        row: ele,
        action: 'Edit'
      },
    });
    dialogRef.afterClosed().subscribe((newConfigList) => {
      if (newConfigList) {
        // console.log('Config data received in parent:', newConfigList);
        // Add to Config List.
        this.loader.showLoadingIcon();
        this.api.Config.AddOrEditConfigList(newConfigList).subscribe({
          next: (res: TableResult<ConfigList>) => {
            this.configTable.UpdateResult(res);
          },
          error: (err) => {
            console.log('Error: ' + err);
            this.snackbar.open(
              'Failed to fetch data. Error: ' + err.messageStr,
              'X',
              {
                duration: 3000,
                panelClass: ['notify-error'],
                verticalPosition: 'top',
              }
            );
          },
          complete: () => {
            this.loader.hideLoadingIcon();
          },
        });
      }
    });
  }

  deleteFromConfigList(ele: ConfigList) {
    const dialogRef = this.dialog.open(HrtDialogComponent, {
      data: {
        title: 'Delete Config',
        msg:
          'Are you sure want to permanently delete the config ' +
          ele.ConfigName +
          ':' +
          ele.ConfigValue +
          '?',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loader.showLoadingIcon();
        this.api.Config.RemoveFromConfigList(ele.Id).subscribe({
          next: (res: TableResult<ConfigList>) => {
            this.configTable.UpdateResult(res);
          },
          error: (err) => {
            this.snackbar.open(
              'Failed to fetch data. Error: ' + err.messageStr,
              'X',
              {
                duration: 3000,
                panelClass: ['notify-error'],
                verticalPosition: 'top',
              }
            );
          },
          complete: () => {
            this.loader.hideLoadingIcon();
          },
        });
      }
    });
  }
}
