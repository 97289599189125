<div class="background" *ngFor="let group of groups">
    <p class="header">{{group.name}}</p>
    <div>
        <div class="tile" *ngFor="let product of group.products" (click)="addProduct(product)">
            <table>
                <tr>
                    <td>
                        <img class="icon" src="{{ product.imageSrc }}">
                    </td>
                    <td>
                        <!-- BETA dot in future -->
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="titleBlock">
                            <p class="title">{{ product.title }}</p>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>
