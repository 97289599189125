import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { Site } from '../../data/data.generated';
import { TableResult, TableView } from './table';

export class Sites {
  constructor(private _api: ApiService) {}

  public GetSites(
    listOptions: TableView,
    country?: string,
    state?: string,
    productTypeId?: number
  ): Observable<TableResult<Site>> {
    var params: any = {
      ...listOptions,
    };
    if (country) params.Country = country;
    if (state) params.State = state;
    if (productTypeId) params.ProductTypeId = productTypeId;
    return this._api.Get<TableResult<Site>>('GetSites', params);
  }

  public GetSiteDetails(id: number): Observable<Site> {
    return this._api.Get<Site>('GetSiteDetails', { Id: id });
  }

  public GetOrderSiteInformation(projectId: string): Observable<Site> {
    return this._api.Get<Site>('GetOrderSiteInformation', {
      ProjectId: projectId,
    });
  }

  public CreateSite(site: Site): Observable<string> {
    return this._api.Get<string>('CreateSite', {
      Object: site,
    });
  }

  public UpdateSite(site: Site): Observable<string> {
    return this._api.Get<string>('UpdateSite', {
      Object: site,
    });
  }

  public DeleteSite(id: number): Observable<string> {
    return this._api.Get<string>('DeleteSite', {
      Id: id,
    });
  }

  public ExportSites(
    listOptions: TableView,
    country?: string,
    state?: string,
    productTypeId?: number
  ) {
    var params: any = {
      ...listOptions,
    };
    if (country) params.Country = country;
    if (state) params.State = state;
    if (productTypeId) params.ProductTypeId = productTypeId;

    let date = new Date().toLocaleString('en-US');

    this._api.Download(
      'ExportSites',
      params,
      `Sites Export ${date}`,
      'text/csv',
      (err: string) => {}
    );
  }
}
