<table class="TableHeader">
    <tr>
        <div class="headline">
            <span class="mat-headline" style="font-size: 26px; font-weight: bold;">Master Schedule Report</span>
        </div>
        <td>
            <div class="TableHeaderSearch">
                <mat-form-field  class="TableHeaderSearchBox">
                    <mat-label>Search Master Schedule Report</mat-label>
                    <input type="text" matInput [(ngModel)]="filter" (ngModelChange)="searchUpdated()">
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
            </div>
            <dx-load-indicator *ngIf="!items" class="content-block" height="60" width="60">
            </dx-load-indicator>
        </td>
    </tr>
</table>
<div class="content-block font-italic" *ngIf="noResults">No Results Found</div>
<div>
    <div *ngFor="let item of items" style="margin-bottom: 20px;">
        <div>
            <div *ngFor="let project of item.Projects" class="mat-elevation-z2" style="padding: 10px; padding-bottom: 10px;">
                <div class="mat-headline" style="font-weight: bold;">
                    {{project.ProjectName}}
                </div>
                <table>

                    <tr>
                        <td cols="5" [colSpan]="3"><span style="font-weight: bold;">End User:</span> {{project.FirstItem.EndUser}}</td>
                        <td cols="4" [colSpan]="3"><span style="font-weight: bold;">Address:</span> {{project.FirstItem.City}}, {{project.FirstItem.State}}, {{project.FirstItem.Country}}</td>
                        <td cols="3" [colSpan]="2"><span style="font-weight: bold;">Contract Value:</span> {{formatCurrency(project.FirstItem.ContractValue)}}</td>
                    </tr>
                    <tr>
                        <td cols="5" [colSpan]="3"><span style="font-weight: bold;">Customer:</span> {{project.FirstItem.Customer}}</td>
                        <td cols="4" [colSpan]="3"><span style="font-weight: bold;">PO Number:</span> {{project.FirstItem.CustomerPo}}</td>
                        <td cols="3" [colSpan]="2"><span style="font-weight: bold;">Payment Terms:</span> {{project.FirstItem.PaymentTermsID}}</td>
                    </tr>

                    <tr>
                        <td cols="2" style="font-weight: bold;">Item Description</td>
                        <td style="font-weight: bold;">Turn-In Date</td>
                        <td style="font-weight: bold;">Req Ship Date</td>
                        <td style="font-weight: bold;">Curr Ship Date</td>
                        <td style="font-weight: bold;">App Dwg Date</td>
                        <td style="font-weight: bold;">Sales Mgr</td>
                        <td style="font-weight: bold;">Project Mgr</td>
                        <td style="font-weight: bold;">Tracking Info</td>
                    </tr>
                    <ng-container *ngFor="let lineItem of project.LineItems">
                        <tr>
                            <td class="caption" cols="2">{{lineItem.ItemDescription}}</td>
                            <td class="caption">{{lineItem.TurnInDate}}</td>
                            <td class="caption">{{lineItem.RequestedShipDate}}</td>
                            <td class="caption">{{lineItem.CurrentShipDate}}</td>
                            <td class="caption">{{lineItem.ApprovalDrawingDate}}</td>
                            <td class="caption">{{lineItem.SalesManager}}</td>
                            <td class="caption">{{lineItem.ProjectManager}}</td>
                            <td><span [innerHTML]="getShipmentLink(lineItem.ShipmentInformation)"></span></td>
                        </tr>
                        <tr style="font-size: 10px;">
                            <td class="caption" cols="2"> </td>
                            <td class="caption">
                                <mat-form-field appearance="fill">
                                    <mat-label>Prelim Dwg</mat-label>
                                    <input matInput [matDatepicker]="shortPicker">
                                    <mat-datepicker-toggle matSuffix [for]="shortPicker"></mat-datepicker-toggle>
                                    <mat-datepicker #shortPicker></mat-datepicker>
                                </mat-form-field>
                            </td>
                            <td class="caption">
                                <mat-form-field appearance="fill">
                                    <mat-label>Mech Release</mat-label>
                                    <input matInput [matDatepicker]="shortPicker">
                                    <mat-datepicker-toggle matSuffix [for]="shortPicker"></mat-datepicker-toggle>
                                    <mat-datepicker #shortPicker></mat-datepicker>
                                </mat-form-field>
                            </td>
                            <td class="caption">
                                <mat-form-field appearance="fill">
                                    <mat-label>Vessel Prod</mat-label>
                                    <input matInput [matDatepicker]="shortPicker">
                                    <mat-datepicker-toggle matSuffix [for]="shortPicker"></mat-datepicker-toggle>
                                    <mat-datepicker #shortPicker></mat-datepicker>
                                </mat-form-field>
                            </td>
                            <td class="caption">
                                <mat-form-field appearance="fill">
                                    <mat-label>Mech Prod</mat-label>
                                    <input matInput [matDatepicker]="shortPicker">
                                    <mat-datepicker-toggle matSuffix [for]="shortPicker"></mat-datepicker-toggle>
                                    <mat-datepicker #shortPicker></mat-datepicker>
                                </mat-form-field>
                            </td>
                            <td class="caption">
                                <mat-form-field appearance="fill">
                                    <mat-label>Elec Release</mat-label>
                                    <input matInput [matDatepicker]="shortPicker">
                                    <mat-datepicker-toggle matSuffix [for]="shortPicker"></mat-datepicker-toggle>
                                    <mat-datepicker #shortPicker></mat-datepicker>
                                </mat-form-field>
                            </td>
                            <td class="caption">
                                <mat-form-field appearance="fill">
                                    <mat-label>Program</mat-label>
                                    <input matInput [matDatepicker]="shortPicker">
                                    <mat-datepicker-toggle matSuffix [for]="shortPicker"></mat-datepicker-toggle>
                                    <mat-datepicker #shortPicker></mat-datepicker>
                                </mat-form-field>
                            </td>
                            <td class="caption">
                                <mat-form-field appearance="fill">
                                    <mat-label>Panel Upload</mat-label>
                                    <input matInput [matDatepicker]="shortPicker">
                                    <mat-datepicker-toggle matSuffix [for]="shortPicker"></mat-datepicker-toggle>
                                    <mat-datepicker #shortPicker></mat-datepicker>
                                </mat-form-field>
                            </td>
                        </tr>
                    </ng-container>
                    
                </table>
            </div>
        </div>
    </div>
</div>