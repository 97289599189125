import { Observable } from 'rxjs';
import { ApiService } from "../api.service";
import { Company } from '../../data/data.generated';
import { TableResult, TableView } from './table';

export class Companies {
    constructor(private _api: ApiService) {
    }

    public GetCompanies(listOptions: TableView): Observable<TableResult<Company>> {
        return this._api.Get<TableResult<Company>>("GetCompanies",
            {
                ...listOptions
            });
    }

    public CreateCompany(company: Company): Observable<string> {
        return this._api.Get<string>("CreateCompany",
            {
                Object: company
            });
    }

    public UpdateCompany(company: Company): Observable<string> {
        return this._api.Get<string>("UpdateCompany",
            {
                Object: company
            });
    }

    public DeleteCompany(id: number): Observable<string> {
        return this._api.Get<string>("DeleteCompany",
            {
                Id: id
            });
    }
}
