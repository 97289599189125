export enum FieldType {
  Temperature = 1,
  Pressure = 2,
  Energy = 3,
  Misc = 4,
}

export const SIUnits = {
  temperature: '°C',
  pressure: 'barA',
};

export const USUnits = {
  temperature: '°F',
  pressure: 'psia',
};

export const CompressorMetadata = {
  operating_status_properties: [
    {
      key: 'capacity',
      displayName: 'Capacity',
      unit: '%',
      infoText:
        'Current compressor capacity (the reading of the primary slide position or swept volume)',
      showChart: true,
    },
    {
      key: 'slide_Position',
      displayName: 'Slide Valve Position',
      unit: '%',
      infoText: 'Current reading of the capacity slide position sensor',
      showChart: true,
    },
    { key: 'motor_Running', displayName: 'Motor Status' },
    {
      key: 'motor_Speed',
      displayName: 'Motor Speed',
      unit: 'RPM',
      displayConditions: [{ key: 'motor_Speed', op: '>', value: '0' }],
      showChart: true,
    },
    // {
    //   key: 'number_Of_Starts',
    //   displayName: 'Number of Starts (24 hrs)',
    //   infoText: 'Total number of times that the compressor has started',
    //   unit: '',
    // },
    {
      key: 'number_Of_Starts_24h',
      displayName: 'Number of Starts (24 hrs)',
      infoText:
        'Total number of times that the compressor has started in the last 24 Hours',
    },
    {
      key: 'number_Of_Starts_7d',
      displayName: 'Number of Starts (7 days)',
      infoText:
        'Total number of times that the compressor has started in the last 7 days',
    },
    {
      key: 'compressor_RunTime_Hours',
      displayName: 'Runtime Hours',
      infoText: 'Total amount of time the compressor has run',
    },
  ],
  energy_properties: [
    {
      key: 'kWh_Demand',
      displayName: 'Power',
      infoText: 'Current estimated kWh demand of the compressor',
      unit: 'kW',
      showChart: true,
    },
    {
      key: 'kWH_Total_Usage_Hours',
      displayName: 'kWH (Total Hours)',
      infoText: 'Total kilowatt usage of the panel',
    },
    {
      key: 'motor_Current',
      displayName: 'Motor Current',
      unit: 'A',
      infoText: `Motor current as read through the motor starter communication. It will not be visible if Motor Starter communication has not been enabled in the configuration.
    starter communication. It will not be visible if Motor Starter communication has not been enabled in the configuration`,
      showChart: true,
    },
  ],
  temperature_properties: [
    {
      key: 'saturated_Suction_Temperature',
      displayName: 'Saturated Suction Temperature',
      unit: '°C',
      fieldType: FieldType.Temperature,
      showChart: true,
    },
    {
      key: 'saturated_Discharge_Temperature',
      displayName: 'Saturated Discharge Temperature',
      unit: '°C',
      fieldType: FieldType.Temperature,
      showChart: true,
    },
    {
      key: 'discharge_Temperature',
      displayName: 'Discharge Temperature',
      unit: '°C',
      fieldType: FieldType.Temperature,
      showChart: true,
    },
    {
      key: 'oil_Temperature',
      displayName: 'Oil Temperature',
      unit: '°C',
      fieldType: FieldType.Temperature,
      showChart: true,
    },
    {
      key: 'oil_Separator_Temperature',
      displayName: 'Oil Separator Temperature',
      unit: '°C',
      fieldType: FieldType.Temperature,
      showChart: true,
    },
    {
      key: 'suction_Superheat',
      displayName: 'Suction Superheat',
      unit: '°C',
      fieldType: FieldType.Temperature,
      showChart: true
    },
    {
      key: 'ambient_Air_Temperature_1',
      displayName: 'Ambient Air Temperature',
      unit: '°C',
      fieldType: FieldType.Temperature,
    },
  ],
  pressureProperties: [
    {
      key: 'suction_Pressure',
      displayName: 'Suction Pressure',
      unit: 'barA',
      fieldType: FieldType.Pressure,
      showChart: true
    },
    {
      key: 'discharge_Pressure',
      displayName: 'Discharge Pressure',
      unit: 'barA',
      fieldType: FieldType.Pressure,
      showChart: true,
    },
    {
      key: 'oil_Pressure',
      displayName: 'Oil Pressure',
      unit: 'barA',
      fieldType: FieldType.Pressure,
      showChart: true
    },
    {
      key: 'oil_Differential',
      displayName: 'Oil Differential Pressure',
      unit: 'barA',
      fieldType: FieldType.Pressure,
      showChart: true,
    },
    {
      key: 'oil_Filter_Differential',
      displayName: 'Oil Filter Differential Pressure',
      unit: 'barA',
      fieldType: FieldType.Pressure,
      showChart: true,
    },
  ],
  miscProperties: [
    { key: 'pk', displayName: 'Panel ID' },
    {
      key: 'cPU_Temperature',
      displayName: 'CPU Temperature',
      unit: '°C',
      fieldType: FieldType.Temperature,
    },
  ],
};

export interface Metadata {
  key: string;
  displayName: string;
  displayConditions?: {
    key: string;
    op: string;
    value: string | boolean | number;
  }[];
  unit?: string;
  infoText?: string;
  fieldType?: FieldType;
  showChart?: boolean;
}
