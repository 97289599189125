import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FilterTableSettings } from '@gea/digital-ui-lib';
import { tap } from 'rxjs';
import { Site } from 'src/app/shared/data/data.generated';
import { HRTColumnDefinition } from 'src/app/shared/data/filterConfiguration';
import { GetCustomTypeText } from 'src/app/shared/data/product-types';
import { tableDefinitions } from 'src/app/shared/data/table-definitions';
import { ApiService } from 'src/app/shared/services/api.service';
import {
  Table,
  TableResult,
  TableView,
} from 'src/app/shared/services/api/table';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { HrtTableService } from 'src/app/shared/services/hrt-table.service';
import { UtilityService } from 'src/app/shared/services/utility.service';

@Component({
  selector: 'app-site-iot-connected',
  templateUrl: './site-iot-connected.component.html',
  styleUrls: ['./site-iot-connected.component.scss'],
})
export class SiteIotConnectedComeponent {
  tableId: string = 'siteIotConnectedComponent';
  totalCount: number = 0;
  columnDefinitions: HRTColumnDefinition[];
  tableData: any;
  listOptions: TableView = new TableView();
  siteTable: Table<Site> = new Table<Site>();
  selectedProduct: number = 106;

  tableDataLoading: boolean = false;
  initialLoad: boolean = true;

  mapData: (string | number)[][] = [];
  mapDataLoading: boolean = false;

  getCustomTypeText = GetCustomTypeText;

  constructor(
    private api: ApiService,
    private dashboardService: DashboardService,
    private hrtTableService: HrtTableService,
    private utilityService: UtilityService
  ) {}

  @Input() data: any;

  ngOnInit(): void {
    if (sessionStorage.getItem(this.tableId + '-listOptions')) {
      this.listOptions = JSON.parse(
        sessionStorage.getItem(this.tableId + '-listOptions') || ''
      );
    }

    this.siteTable.GetApi = (listOptions: TableView) => {
      return this.api.Sites.GetSites(
        listOptions,
        undefined,
        undefined,
        this.selectedProduct
      ).pipe(
        tap((siteData: TableResult<Site>) => {
          this.tableData = siteData.Data;
        })
      );
    };

    this.siteTable.Updated = () => (this.tableDataLoading = false);

    // For initial load, control doesn't go in tap unless subscribed.
    this.siteTable.refreshDataForTable()?.subscribe();

    // this.siteTable.Updated = () => (this.tableDataLoading = false);
    this.assignMockColDefinitions();
  }

  ngAfterViewInit() {
    this.initialLoad = false;
  }

  assignMockColDefinitions() {
    this.columnDefinitions = tableDefinitions.siteTableDef;
  }

  handleTableSettings(tableSettings: FilterTableSettings) {
    if (this.initialLoad) return;

    const oldOptions = JSON.parse(JSON.stringify(this.listOptions));

    const tableColKeys: string[] = Object.keys(tableSettings.columns);
    let sortKey = '';
    let sortDes = 'False';
    if (tableColKeys.length > 0) {
      // sorting exists
      for (let key in tableSettings.columns) {
        let sortValue = tableSettings.columns[key].sort;
        if (sortValue === 'asc' || sortValue === 'desc') {
          sortKey = key;
          sortDes = sortValue === 'desc' ? 'True' : 'False';
          break;
        }
      }
      this.siteTable.View.SortColumn = sortKey;
      this.siteTable.View.SortDesc = sortDes;

      this.listOptions.SortColumn = sortKey;
      this.listOptions.SortDesc = sortDes;
    }
    this.siteTable.View.Page = tableSettings.page;
    this.listOptions.Page = tableSettings.page;
    this.hrtTableService.saveListOptionsToSS(this.tableId, this.listOptions);

    // Object comparision as tableSettings sends multiple events.
    if (this.utilityService.deepEqual(oldOptions, this.listOptions)) return;
    this.siteTable.SearchUpdated();
  }

  updateSearchTerm(searchText: string) {
    if (this.initialLoad) return;
    this.siteTable.View.Search = searchText;
    this.listOptions.Search = searchText;
    this.hrtTableService.saveListOptionsToSS(this.tableId, this.listOptions);
    this.siteTable.SearchUpdated();
  }

  rowClicked(rowData: any) {
    this.utilityService.openSite(rowData.Id);
  }

  ngOnDestroy() {
    sessionStorage.setItem(
      this.tableId + '-listOptions',
      JSON.stringify(this.listOptions)
    );
  }
}
