import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { InputV2Component, InputV2Module } from '@gea/digital-ui-lib';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-oil-separator-calc',
  templateUrl: './oil-separator-calc.component.html',
    styleUrls: ['./oil-separator-calc.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OilSeparatorCalcComponent {

    constructor(private api : ApiService) {
        
    }

    public manufacturer: number = 0;
    public calculationMode: number = 0;
    public vesselDesign: number = 0;
    public application: number = 0;
    public refrigerant: number = 0;
    public oilType: number = 0;
    
    public designCondensingTemp: number = 1;
    public designDischargeTemp: number = 2;
    public designDischargePressure: number = 3;
    public designDischargeMassFlow: number = 4;

    public minCondensingTemp: number = 5;
    public minDischargeTemp: number = 6;
    public minDischargePressure: number = 7;
    public minDischargeMassFlow: number = 8;

    test(event: any) {
        alert(event);
    }
}
