import { Component, Input, OnInit } from '@angular/core';
import { Site } from 'src/app/shared/data/data.generated';
import { Table } from 'src/app/shared/services/api/table';
import { Router } from '@angular/router';
import {
  GetCustomTypeIcon,
  GetCustomTypeIconWidth,
  GetCustomTypeText,
} from 'src/app/shared/data/product-types';
import { ImagesService } from 'src/app/shared/services/images.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'gea-site-table',
  templateUrl: './site-table.component.html',
  styleUrls: ['./site-table.component.scss'],
})
export class SiteTableComponent implements OnInit {
    @Input() siteTable: Table<Site> = new Table<Site>();

  public getCustomTypeText = GetCustomTypeText;
  public getCustomTypeIcon = GetCustomTypeIcon;
  public getCustomTypeIconWidth = GetCustomTypeIconWidth;
  public pdfImage: string = this.images.Icons.FormatPdf;
  annunStatuses: any = {};

  public siteColumns: string[] = [
    'Name',
    'Address',
    'City',
    'State',
    'Zip',
    'AxAccountNumbers',
    'ProjectNumbers',
  ];
  public infoImage: string = this.images.Icons.Information;

  constructor(private router: Router, private images: ImagesService, private api: ApiService) {}

  ngOnInit() {
    this.siteTable.inputSubject.pipe(debounceTime(500)).subscribe((value) => {
      this.siteTable.SearchUpdated()
    });
  }

  openSite(id: number) {
    this.router.navigate(['/site-details', { id: id }]);
  }

  searchChange(searchText: string) {
    if (searchText.trim() !== this.siteTable.inputSubject.getValue()) {
      this.siteTable.inputSubject.next(searchText);
    }
  }

  exportTable() {
    console.log(this.siteTable.View);
    const listOptions = this.siteTable.View;
    listOptions.Page = 0;
    listOptions.HasPaging = false;
    this.api.Sites.ExportSites(listOptions);
  }
}
